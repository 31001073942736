@import '~bootstrap-icons/font/bootstrap-icons.css';

@import '~slick-carousel/slick/slick.css';

@import '~slick-carousel/slick/slick-theme.css';

.rfont--h1 {
  font-size: 46px;
  line-height: 56px;
}

@media (max-width: 992px) {
  .rfont--h1 {
    font-size: 28px;
    line-height: 36px;
  }
}

.rfont--h2 {
  font-size: 30px;
  line-height: 36px;
}

@media (max-width: 992px) {
  .rfont--h2 {
    font-size: 24px;
    line-height: 32px;
  }
}

.rfont--h3 {
  font-size: 24px;
  line-height: 32px;
}

@media (max-width: 992px) {
  .rfont--h3 {
    font-size: 18px;
    line-height: 24px;
  }
}

.rfont--h4 {
  font-size: 20px;
  line-height: 28px;
}

@media (max-width: 992px) {
  .rfont--h4 {
    font-size: 16px;
    line-height: 24px;
  }
}

.rfont--body1,
.notif-settings__check .form-check-label {
  font-size: 18px;
  line-height: 28px;
}

@media (max-width: 992px) {
  .rfont--body1,
  .notif-settings__check .form-check-label {
    font-size: 16px;
    line-height: 24px;
  }
}

.rfont--body2 {
  font-size: 16px;
  line-height: 24px;
}

@media (max-width: 992px) {
  .rfont--body2 {
    font-size: 14px;
  }
}

.rfont--body3 {
  font-size: 14px;
  line-height: 20px;
}

@media (max-width: 992px) {
  .rfont--body3 {
    font-size: 12px;
    line-height: 16px;
  }
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Helvetica Neue",Arial,sans-serif;
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/helvetica-neue_medium.ttf");
}

@font-face {
  font-family: "Helvetica Neue",Arial,sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/helvetica-neue_bold.ttf");
}

.hov-color--primary:hover {
  color: #2178c4;
}

.hov-color--secondary:hover {
  color: #676879;
}

.hov-color--success:hover {
  color: #1ea382;
}

.hov-color--info:hover {
  color: #2178c4;
}

.hov-color--warning:hover {
  color: #d8761c;
}

.hov-color--danger:hover {
  color: #b7343f;
}

.hov-color--light:hover {
  color: #fff;
}

.hov-color--dark:hover {
  color: #22263f;
}

html {
  height: 100%;
}

body {
  height: 100%;
  font-family: "Helvetica Neue",Arial,sans-serif;
  font-weight: 400;
}

h1,
.h1 {
  color: #fff;
  font-size: 28px;
  padding: 20px 0;
  font-weight: 300;
}

h2,
.h2 {
  font-weight: 500;
}

h3,
.h3 {
  font-weight: 500;
}

h4,
.h4 {
  font-weight: 300;
  font-size: 16px;
}

h5,
.h5 {
  font-weight: 500;
  font-size: 14px;
}

h6,
.h6 {
  font-weight: 700;
  font-size: 14px;
}

p {
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  margin: 15px 0;
}

a {
  outline: none;
  text-decoration: none;
}

a:hover,
a:active {
  outline: 0;
  text-decoration: none;
}

label.above {
  display: block;
  margin-bottom: 5px;
}

[tabindex="-1"]:focus {
  outline: none !important;
}

.content-wrapper {
  max-width: 1440px;
  margin: 0 auto;
}

@media only screen and (max-width: 1800px) {
  .content-wrapper {
    max-width: 960px;
    margin: 0 auto;
  }
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters>.col,
.no-gutters>[class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.gradient-mask {
  mask-image: linear-gradient(0deg, #22263f, transparent);
}

.gtext-ellipsis,
.form-dropdown__dropdown-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

:root,
[data-bs-theme=light] {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #ffffff;
  --bs-gray: #676879;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f2f5f9;
  --bs-gray-200: #e6ecf3;
  --bs-gray-300: #aeb6be;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #676879;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #22263f;
  --bs-primary: #2178c4;
  --bs-secondary: #676879;
  --bs-success: #1ea382;
  --bs-info: #2178c4;
  --bs-warning: #d8761c;
  --bs-danger: #b7343f;
  --bs-light: #ffffff;
  --bs-dark: #22263f;
  --bs-primary-rgb: 33, 120, 196;
  --bs-secondary-rgb: 103, 104, 121;
  --bs-success-rgb: 30, 163, 130;
  --bs-info-rgb: 33, 120, 196;
  --bs-warning-rgb: 216, 118, 28;
  --bs-danger-rgb: 183, 52, 63;
  --bs-light-rgb: 255, 255, 255;
  --bs-dark-rgb: 34, 38, 63;
  --bs-primary-text-emphasis: #0d304e;
  --bs-secondary-text-emphasis: #292a30;
  --bs-success-text-emphasis: #0c4134;
  --bs-info-text-emphasis: #0d304e;
  --bs-warning-text-emphasis: #562f0b;
  --bs-danger-text-emphasis: #491519;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #d3e4f3;
  --bs-secondary-bg-subtle: #e1e1e4;
  --bs-success-bg-subtle: #d2ede6;
  --bs-info-bg-subtle: #d3e4f3;
  --bs-warning-bg-subtle: #f7e4d2;
  --bs-danger-bg-subtle: #f1d6d9;
  --bs-light-bg-subtle: #f9fafc;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #a6c9e7;
  --bs-secondary-border-subtle: #c2c3c9;
  --bs-success-border-subtle: #a5dacd;
  --bs-info-border-subtle: #a6c9e7;
  --bs-warning-border-subtle: #efc8a4;
  --bs-danger-border-subtle: #e2aeb2;
  --bs-light-border-subtle: #e7edf3;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: "Helvetica Neue", Arial, sans-serif;
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: rgba(0, 0, 0, 0.65);
  --bs-body-color-rgb: 0, 0, 0;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(0, 0, 0, 0.75);
  --bs-secondary-color-rgb: 0, 0, 0;
  --bs-secondary-bg: #e6ecf3;
  --bs-secondary-bg-rgb: 230, 236, 243;
  --bs-tertiary-color: rgba(0, 0, 0, 0.5);
  --bs-tertiary-color-rgb: 0, 0, 0;
  --bs-tertiary-bg: #f2f5f9;
  --bs-tertiary-bg-rgb: 242, 245, 249;
  --bs-heading-color: inherit;
  --bs-link-color: #1890ff;
  --bs-link-color-rgb: 24, 144, 255;
  --bs-link-decoration: none;
  --bs-link-hover-color: #40a9ff;
  --bs-link-hover-color-rgb: 64, 169, 255;
  --bs-link-hover-decoration: none;
  --bs-code-color: #d63384;
  --bs-highlight-color: rgba(0, 0, 0, 0.65);
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #b9bbbc;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(33, 120, 196, 0.25);
  --bs-form-valid-color: #1ea382;
  --bs-form-valid-border-color: #1ea382;
  --bs-form-invalid-color: #d9771c;
  --bs-form-invalid-border-color: #d9771c;
}

[data-bs-theme=dark] {
  color-scheme: dark;
  --bs-body-color: #aeb6be;
  --bs-body-color-rgb: 174, 182, 190;
  --bs-body-bg: #22263f;
  --bs-body-bg-rgb: 34, 38, 63;
  --bs-emphasis-color: #ffffff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(174, 182, 190, 0.75);
  --bs-secondary-color-rgb: 174, 182, 190;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(174, 182, 190, 0.5);
  --bs-tertiary-color-rgb: 174, 182, 190;
  --bs-tertiary-bg: #2b3040;
  --bs-tertiary-bg-rgb: 43, 48, 64;
  --bs-primary-text-emphasis: #7aaedc;
  --bs-secondary-text-emphasis: #a4a4af;
  --bs-success-text-emphasis: #78c8b4;
  --bs-info-text-emphasis: #7aaedc;
  --bs-warning-text-emphasis: #e8ad77;
  --bs-danger-text-emphasis: #d4858c;
  --bs-light-text-emphasis: #f2f5f9;
  --bs-dark-text-emphasis: #aeb6be;
  --bs-primary-bg-subtle: #071827;
  --bs-secondary-bg-subtle: #151518;
  --bs-success-bg-subtle: #06211a;
  --bs-info-bg-subtle: #071827;
  --bs-warning-bg-subtle: #2b1806;
  --bs-danger-bg-subtle: #250a0d;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #144876;
  --bs-secondary-border-subtle: #3e3e49;
  --bs-success-border-subtle: #12624e;
  --bs-info-border-subtle: #144876;
  --bs-warning-border-subtle: #824711;
  --bs-danger-border-subtle: #6e1f26;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: #7aaedc;
  --bs-link-hover-color: #95bee3;
  --bs-link-color-rgb: 122, 174, 220;
  --bs-link-hover-color-rgb: 149, 190, 227;
  --bs-code-color: #e685b5;
  --bs-highlight-color: #aeb6be;
  --bs-highlight-bg: #664d03;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: .25;
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color);
}

h1,
.h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (min-width: 1200px) {
  h1,
  .h1 {
    font-size: 2.5rem;
  }
}

h2,
.h2 {
  font-size: calc(1.325rem + 0.9vw);
}

@media (min-width: 1200px) {
  h2,
  .h2 {
    font-size: 2rem;
  }
}

h3,
.h3 {
  font-size: calc(1.3rem + 0.6vw);
}

@media (min-width: 1200px) {
  h3,
  .h3 {
    font-size: 1.75rem;
  }
}

h4,
.h4 {
  font-size: calc(1.275rem + 0.3vw);
}

@media (min-width: 1200px) {
  h4,
  .h4 {
    font-size: 1.5rem;
  }
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small,
.small {
  font-size: 0.875em;
}

mark,
.mark {
  padding: .1875em;
  color: var(--bs-highlight-color);
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: none;
}

a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
  text-decoration: none;
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}

a>code {
  color: inherit;
}

kbd {
  padding: .1875rem .375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: .25rem;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: .5rem;
  padding-bottom: .5rem;
  color: var(--bs-secondary-color);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend+* {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: .5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote>:last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #676879;
}

.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: .25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: .5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x)*.5);
  padding-left: calc(var(--bs-gutter-x)*.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm,
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container-md,
  .container-sm,
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1320px;
  }
}

:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1*var(--bs-gutter-y));
  margin-right: calc(-0.5*var(--bs-gutter-x));
  margin-left: calc(-0.5*var(--bs-gutter-x));
}

.row>* {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x)*.5);
  padding-left: calc(var(--bs-gutter-x)*.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto>* {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1>* {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2>* {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3>* {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.row-cols-4>* {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5>* {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6>* {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3>* {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .row-cols-sm-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6>* {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }

  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3>* {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .row-cols-md-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6>* {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }

  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3>* {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .row-cols-lg-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6>* {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }

  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3>* {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .row-cols-xl-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6>* {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }

  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3>* {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .row-cols-xxl-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6>* {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }

  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}

.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: var(--bs-emphasis-color);
  --bs-table-bg: var(--bs-body-bg);
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-emphasis-color);
  --bs-table-striped-bg: rgba(var(--bs-emphasis-color-rgb), 0.05);
  --bs-table-active-color: var(--bs-emphasis-color);
  --bs-table-active-bg: rgba(var(--bs-emphasis-color-rgb), 0.1);
  --bs-table-hover-color: var(--bs-emphasis-color);
  --bs-table-hover-bg: rgba(var(--bs-emphasis-color-rgb), 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}

.table>:not(caption)>*>* {
  padding: .5rem .5rem;
  color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}

.table>tbody {
  vertical-align: inherit;
}

.table>thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--bs-border-width)*2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm>:not(caption)>*>* {
  padding: .25rem .25rem;
}

.table-bordered>:not(caption)>* {
  border-width: var(--bs-border-width) 0;
}

.table-bordered>:not(caption)>*>* {
  border-width: 0 var(--bs-border-width);
}

.table-borderless>:not(caption)>*>* {
  border-bottom-width: 0;
}

.table-borderless>:not(:first-child) {
  border-top-width: 0;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-striped-columns>:not(caption)>tr>:nth-child(even) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg);
}

.table-hover>tbody>tr:hover>* {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #d3e4f3;
  --bs-table-border-color: #a9b6c2;
  --bs-table-striped-bg: #c8d9e7;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #becddb;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c3d3e1;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e1e1e4;
  --bs-table-border-color: #b4b4b6;
  --bs-table-striped-bg: #d6d6d9;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbcbcd;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d0d0d3;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d2ede6;
  --bs-table-border-color: #a8beb8;
  --bs-table-striped-bg: #c8e1db;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bdd5cf;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c2dbd5;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #d3e4f3;
  --bs-table-border-color: #a9b6c2;
  --bs-table-striped-bg: #c8d9e7;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #becddb;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c3d3e1;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #f7e4d2;
  --bs-table-border-color: #c6b6a8;
  --bs-table-striped-bg: #ebd9c8;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #decdbd;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e4d3c2;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f1d6d9;
  --bs-table-border-color: #c1abae;
  --bs-table-striped-bg: #e5cbce;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #d9c1c3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #dfc6c9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #ffffff;
  --bs-table-border-color: #cccccc;
  --bs-table-striped-bg: #f2f2f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6e6e6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ececec;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #ffffff;
  --bs-table-bg: #22263f;
  --bs-table-border-color: #4e5165;
  --bs-table-striped-bg: #2d3149;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #383c52;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #33364d;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.form-label {
  margin-bottom: .5rem;
  font-size: 0.875rem;
  font-weight: bold;
  color: #22263f;
}

.col-form-label {
  padding-top: calc(0.375rem + var(--bs-border-width));
  padding-bottom: calc(0.375rem + var(--bs-border-width));
  margin-bottom: 0;
  font-size: inherit;
  font-weight: bold;
  line-height: 1.5;
  color: #22263f;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + var(--bs-border-width));
  padding-bottom: calc(0.5rem + var(--bs-border-width));
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + var(--bs-border-width));
  padding-bottom: calc(0.25rem + var(--bs-border-width));
  font-size: 0.875rem;
}

.form-text {
  margin-top: .25rem;
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.form-control {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #22263f;
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: 4px;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control[type=file] {
  overflow: hidden;
}

.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: #22263f;
  background-color: var(--bs-body-bg);
  border-color: #2178c4;
  outline: 0;
  box-shadow: none;
}

.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.5em;
  margin: 0;
}

.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}

.form-control::placeholder {
  color: #676879;
  opacity: 1;
}

.form-control:disabled {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}

.form-control::file-selector-button {
  padding: .375rem .75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: .75rem;
  color: #22263f;
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--bs-border-width);
  border-radius: 0;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: .375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: rgba(0,0,0,0);
  border: solid rgba(0,0,0,0);
  border-width: var(--bs-border-width) 0;
}

.form-control-plaintext:focus {
  outline: 0;
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
  padding: .25rem .5rem;
  font-size: 0.875rem;
  border-radius: 4px;
}

.form-control-sm::file-selector-button {
  padding: .25rem .5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: .5rem;
}

.form-control-lg {
  min-height: 50px;
  padding: .5rem 1rem;
  font-size: 1.25rem;
  border-radius: 4px;
}

.form-control-lg::file-selector-button {
  padding: .5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
}

textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
}

textarea.form-control-lg {
  min-height: 50px;
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
  padding: .375rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: 4px;
}

.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: 4px;
}

.form-control-color.form-control-sm {
  height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
}

.form-control-color.form-control-lg {
  height: 50px;
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: .375rem 2.25rem .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #22263f;
  appearance: none;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img),var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right .75rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: 4px;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}

.form-select:focus {
  border-color: #2178c4;
  outline: 0;
  box-shadow: 0 0 0 .25rem rgba(33,120,196,.25);
}

.form-select[multiple],
.form-select[size]:not([size="1"]) {
  padding-right: .75rem;
  background-image: none;
}

.form-select:disabled {
  background-color: var(--bs-secondary-bg);
}

.form-select:-moz-focusring {
  color: rgba(0,0,0,0);
  text-shadow: 0 0 0 #22263f;
}

.form-select-sm {
  padding-top: .25rem;
  padding-bottom: .25rem;
  padding-left: .5rem;
  font-size: 0.875rem;
  border-radius: 4px;
}

.form-select-lg {
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: 4px;
}

[data-bs-theme=dark] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23aeb6be' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: .125rem;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}

.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  --bs-form-check-bg: var(--bs-body-bg);
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  margin-top: .25em;
  vertical-align: top;
  appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  print-color-adjust: exact;
}

.form-check-input[type=checkbox] {
  border-radius: .25em;
}

.form-check-input[type=radio] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: #2178c4;
  outline: 0;
  box-shadow: 0 0 0 .25rem rgba(33,120,196,.25);
}

.form-check-input:checked {
  background-color: #22263f;
  border-color: #22263f;
}

.form-check-input:checked[type=checkbox] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type=radio] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23ffffff'/%3e%3c/svg%3e");
}

.form-check-input[type=checkbox]:indeterminate {
  background-color: #2178c4;
  border-color: #2178c4;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: .5;
}

.form-check-input[disabled]~.form-check-label,
.form-check-input:disabled~.form-check-label {
  cursor: default;
  opacity: .5;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  width: 2em;
  margin-left: -2.5em;
  background-image: var(--bs-form-switch-bg);
  background-position: left center;
  border-radius: 2em;
  transition: background-position .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}

.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%232178c4'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  background-position: right center;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}

.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}

.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.btn-check[disabled]+.btn,
.btn-check:disabled+.btn {
  pointer-events: none;
  filter: none;
  opacity: .65;
}

[data-bs-theme=dark] .form-switch .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  width: 100%;
  height: calc(24px + 0.5rem);
  padding: 0;
  appearance: none;
  background-color: rgba(0,0,0,0);
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff,none;
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff,none;
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  width: 24px;
  height: 24px;
  margin-top: -11px;
  appearance: none;
  background-color: #f2f5f9;
  border: 9px solid #2178c4;
  border-radius: 50%;
  transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #f2f5f9;
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 2px;
  color: rgba(0,0,0,0);
  cursor: pointer;
  background-color: #676879;
  border-color: rgba(0,0,0,0);
  border-radius: 3px;
}

.form-range::-moz-range-thumb {
  width: 24px;
  height: 24px;
  appearance: none;
  background-color: #f2f5f9;
  border: 9px solid #2178c4;
  border-radius: 50%;
  transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #f2f5f9;
}

.form-range::-moz-range-track {
  width: 100%;
  height: 2px;
  color: rgba(0,0,0,0);
  cursor: pointer;
  background-color: #676879;
  border-color: rgba(0,0,0,0);
  border-radius: 3px;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}

.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}

.form-floating {
  position: relative;
}

.form-floating>.form-control,
.form-floating>.form-control-plaintext,
.form-floating>.form-select {
  height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
}

.form-floating>label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem .75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--bs-border-width) solid rgba(0,0,0,0);
  transform-origin: 0 0;
  transition: opacity .1s ease-in-out,transform .1s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating>label {
    transition: none;
  }
}

.form-floating>.form-control,
.form-floating>.form-control-plaintext {
  padding: 1rem .75rem;
}

.form-floating>.form-control::placeholder,
.form-floating>.form-control-plaintext::placeholder {
  color: rgba(0,0,0,0);
}

.form-floating>.form-control:focus,
.form-floating>.form-control:not(:placeholder-shown),
.form-floating>.form-control-plaintext:focus,
.form-floating>.form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating>.form-control:-webkit-autofill,
.form-floating>.form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating>.form-select {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-control-plaintext~label,
.form-floating>.form-select~label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating>.form-control:focus~label::after,
.form-floating>.form-control:not(:placeholder-shown)~label::after,
.form-floating>.form-control-plaintext~label::after,
.form-floating>.form-select~label::after {
  position: absolute;
  inset: 1rem .375rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: var(--bs-body-bg);
  border-radius: 4px;
}

.form-floating>.form-control:-webkit-autofill~label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating>.form-control-plaintext~label {
  border-width: var(--bs-border-width) 0;
}

.form-floating>:disabled~label,
.form-floating>.form-control:disabled~label {
  color: #676879;
}

.form-floating>:disabled~label::after,
.form-floating>.form-control:disabled~label::after {
  background-color: var(--bs-secondary-bg);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group>.form-control,
.input-group>.form-select,
.input-group>.form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.input-group>.form-control:focus,
.input-group>.form-select:focus,
.input-group>.form-floating:focus-within {
  z-index: 5;
}

.input-group .btn {
  position: relative;
  z-index: 2;
}

.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #22263f;
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: 4px;
}

.input-group-lg>.form-control,
.input-group-lg>.form-select,
.input-group-lg>.input-group-text,
.input-group-lg>.btn {
  padding: .5rem 1rem;
  font-size: 1.25rem;
  border-radius: 4px;
}

.input-group-sm>.form-control,
.input-group-sm>.form-select,
.input-group-sm>.input-group-text,
.input-group-sm>.btn {
  padding: .25rem .5rem;
  font-size: 0.875rem;
  border-radius: 4px;
}

.input-group-lg>.form-select,
.input-group-sm>.form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-control,
.input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.has-validation>:nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation>.dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation>.form-floating:nth-last-child(n+3)>.form-control,
.input-group.has-validation>.form-floating:nth-last-child(n+3)>.form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--bs-border-width)*-1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group>.form-floating:not(:first-child)>.form-control,
.input-group>.form-floating:not(:first-child)>.form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: .25rem;
  font-size: 0.875em;
  color: var(--bs-form-valid-color);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .25rem .5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: 4px;
}

.was-validated :valid~.valid-feedback,
.was-validated :valid~.valid-tooltip,
.is-valid~.valid-feedback,
.is-valid~.valid-tooltip {
  display: block;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: var(--bs-form-valid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: none;
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 .25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid,
.form-select.is-valid {
  border-color: var(--bs-form-valid-border-color);
}

.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"],
.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: none;
  padding-right: 4.125rem;
  background-position: right .75rem center,center right 2.25rem;
  background-size: 16px 12px,calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-select:valid:focus,
.form-select.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 .25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-control-color:valid,
.form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:valid,
.form-check-input.is-valid {
  border-color: var(--bs-form-valid-border-color);
}

.was-validated .form-check-input:valid:checked,
.form-check-input.is-valid:checked {
  background-color: var(--bs-form-valid-color);
}

.was-validated .form-check-input:valid:focus,
.form-check-input.is-valid:focus {
  box-shadow: 0 0 0 .25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-check-input:valid~.form-check-label,
.form-check-input.is-valid~.form-check-label {
  color: var(--bs-form-valid-color);
}

.form-check-inline .form-check-input~.valid-feedback {
  margin-left: .5em;
}

.was-validated .input-group>.form-control:not(:focus):valid,
.input-group>.form-control:not(:focus).is-valid,
.was-validated .input-group>.form-select:not(:focus):valid,
.input-group>.form-select:not(:focus).is-valid,
.was-validated .input-group>.form-floating:not(:focus-within):valid,
.input-group>.form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: .25rem;
  font-size: 0.875em;
  color: var(--bs-form-invalid-color);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .25rem .5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: 4px;
}

.was-validated :invalid~.invalid-feedback,
.was-validated :invalid~.invalid-tooltip,
.is-invalid~.invalid-feedback,
.is-invalid~.invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: none;
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 .25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid,
.form-select.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}

.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"],
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: none;
  padding-right: 4.125rem;
  background-position: right .75rem center,center right 2.25rem;
  background-size: 16px 12px,calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-select:invalid:focus,
.form-select.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 .25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-control-color:invalid,
.form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:invalid,
.form-check-input.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}

.was-validated .form-check-input:invalid:checked,
.form-check-input.is-invalid:checked {
  background-color: var(--bs-form-invalid-color);
}

.was-validated .form-check-input:invalid:focus,
.form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 .25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-check-input:invalid~.form-check-label,
.form-check-input.is-invalid~.form-check-label {
  color: var(--bs-form-invalid-color);
}

.form-check-inline .form-check-input~.invalid-feedback {
  margin-left: .5em;
}

.was-validated .input-group>.form-control:not(:focus):invalid,
.input-group>.form-control:not(:focus).is-invalid,
.was-validated .input-group>.form-select:not(:focus):invalid,
.input-group>.form-select:not(:focus).is-invalid,
.was-validated .input-group>.form-floating:not(:focus-within):invalid,
.input-group>.form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: 20px;
  --bs-btn-padding-y: 10px;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 4px;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}

.btn-check+.btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}

.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn-check:focus-visible+.btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn-check:checked+.btn,
:not(.btn-check)+.btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}

.btn-check:checked+.btn:focus-visible,
:not(.btn-check)+.btn:active:focus-visible,
.btn:first-child:active:focus-visible,
.btn.active:focus-visible,
.btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn-check:checked:focus-visible+.btn {
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #2178c4;
  --bs-btn-border-color: #2178c4;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #1c66a7;
  --bs-btn-hover-border-color: #1a609d;
  --bs-btn-focus-shadow-rgb: 66, 140, 205;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #1a609d;
  --bs-btn-active-border-color: #195a93;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #2178c4;
  --bs-btn-disabled-border-color: #2178c4;
}

.btn-secondary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #676879;
  --bs-btn-border-color: #676879;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #585867;
  --bs-btn-hover-border-color: #525361;
  --bs-btn-focus-shadow-rgb: 126, 127, 141;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #525361;
  --bs-btn-active-border-color: #4d4e5b;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #676879;
  --bs-btn-disabled-border-color: #676879;
}

.btn-success {
  --bs-btn-color: #000;
  --bs-btn-bg: #1ea382;
  --bs-btn-border-color: #1ea382;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #40b195;
  --bs-btn-hover-border-color: #35ac8f;
  --bs-btn-focus-shadow-rgb: 26, 139, 111;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #4bb59b;
  --bs-btn-active-border-color: #35ac8f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #1ea382;
  --bs-btn-disabled-border-color: #1ea382;
}

.btn-info {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #2178c4;
  --bs-btn-border-color: #2178c4;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #1c66a7;
  --bs-btn-hover-border-color: #1a609d;
  --bs-btn-focus-shadow-rgb: 66, 140, 205;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #1a609d;
  --bs-btn-active-border-color: #195a93;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #2178c4;
  --bs-btn-disabled-border-color: #2178c4;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #d8761c;
  --bs-btn-border-color: #d8761c;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #de8b3e;
  --bs-btn-hover-border-color: #dc8433;
  --bs-btn-focus-shadow-rgb: 184, 100, 24;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #e09149;
  --bs-btn-active-border-color: #dc8433;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #d8761c;
  --bs-btn-disabled-border-color: #d8761c;
}

.btn-danger {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #b7343f;
  --bs-btn-border-color: #b7343f;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #9c2c36;
  --bs-btn-hover-border-color: #922a32;
  --bs-btn-focus-shadow-rgb: 194, 82, 92;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #922a32;
  --bs-btn-active-border-color: #89272f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #b7343f;
  --bs-btn-disabled-border-color: #b7343f;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffffff;
  --bs-btn-border-color: #ffffff;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d9d9d9;
  --bs-btn-hover-border-color: #cccccc;
  --bs-btn-focus-shadow-rgb: 217, 217, 217;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #cccccc;
  --bs-btn-active-border-color: #bfbfbf;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffffff;
  --bs-btn-disabled-border-color: #ffffff;
}

.btn-dark {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #22263f;
  --bs-btn-border-color: #22263f;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #43475c;
  --bs-btn-hover-border-color: #383c52;
  --bs-btn-focus-shadow-rgb: 67, 71, 92;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #4e5165;
  --bs-btn-active-border-color: #383c52;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #22263f;
  --bs-btn-disabled-border-color: #22263f;
}

.btn-outline-primary {
  --bs-btn-color: #2178c4;
  --bs-btn-border-color: #2178c4;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #2178c4;
  --bs-btn-hover-border-color: #2178c4;
  --bs-btn-focus-shadow-rgb: 33, 120, 196;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #2178c4;
  --bs-btn-active-border-color: #2178c4;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #2178c4;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #2178c4;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #676879;
  --bs-btn-border-color: #676879;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #676879;
  --bs-btn-hover-border-color: #676879;
  --bs-btn-focus-shadow-rgb: 103, 104, 121;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #676879;
  --bs-btn-active-border-color: #676879;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #676879;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #676879;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #1ea382;
  --bs-btn-border-color: #1ea382;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #1ea382;
  --bs-btn-hover-border-color: #1ea382;
  --bs-btn-focus-shadow-rgb: 30, 163, 130;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #1ea382;
  --bs-btn-active-border-color: #1ea382;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #1ea382;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #1ea382;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #2178c4;
  --bs-btn-border-color: #2178c4;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #2178c4;
  --bs-btn-hover-border-color: #2178c4;
  --bs-btn-focus-shadow-rgb: 33, 120, 196;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #2178c4;
  --bs-btn-active-border-color: #2178c4;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #2178c4;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #2178c4;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #d8761c;
  --bs-btn-border-color: #d8761c;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d8761c;
  --bs-btn-hover-border-color: #d8761c;
  --bs-btn-focus-shadow-rgb: 216, 118, 28;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #d8761c;
  --bs-btn-active-border-color: #d8761c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #d8761c;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #d8761c;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #b7343f;
  --bs-btn-border-color: #b7343f;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #b7343f;
  --bs-btn-hover-border-color: #b7343f;
  --bs-btn-focus-shadow-rgb: 183, 52, 63;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #b7343f;
  --bs-btn-active-border-color: #b7343f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #b7343f;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #b7343f;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #ffffff;
  --bs-btn-border-color: #ffffff;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffffff;
  --bs-btn-hover-border-color: #ffffff;
  --bs-btn-focus-shadow-rgb: 255, 255, 255;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffffff;
  --bs-btn-active-border-color: #ffffff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffffff;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #22263f;
  --bs-btn-border-color: #22263f;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #22263f;
  --bs-btn-hover-border-color: #22263f;
  --bs-btn-focus-shadow-rgb: 34, 38, 63;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #22263f;
  --bs-btn-active-border-color: #22263f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #22263f;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #22263f;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #676879;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 20, 122, 217;
  text-decoration: none;
}

.btn-link:hover,
.btn-link:focus-visible {
  text-decoration: none;
}

.btn-link:focus-visible {
  color: var(--bs-btn-color);
}

.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg,
.btn-group-lg>.btn {
  --bs-btn-padding-y: 14px;
  --bs-btn-padding-x: 32px;
  --bs-btn-font-size: 1rem;
  --bs-btn-border-radius: 4px;
}

.btn-sm,
.btn-group-sm>.btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: 4px;
}

.fade {
  transition: opacity .15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height .35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width .35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 5rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: #ffffff;
  --bs-dropdown-border-color: #aeb6be;
  --bs-dropdown-border-radius: 4px;
  --bs-dropdown-border-width: 0px;
  --bs-dropdown-inner-border-radius: calc(4px - 0px);
  --bs-dropdown-divider-bg: #aeb6be;
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: var(--bs-box-shadow);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
  --bs-dropdown-link-active-color: #ffffff;
  --bs-dropdown-link-active-bg: #2178c4;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #676879;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}

.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}

.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}

.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }

  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }

  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }

  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }

  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }

  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }

  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }

  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }

  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }

  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }

  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}

.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}

.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: rgba(0,0,0,0);
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}

.dropdown-item.active,
.dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: rgba(0,0,0,0);
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #aeb6be;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: #aeb6be;
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #aeb6be;
  --bs-dropdown-link-hover-color: #ffffff;
  --bs-dropdown-divider-bg: #aeb6be;
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #ffffff;
  --bs-dropdown-link-active-bg: #2178c4;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group>.btn,
.btn-group-vertical>.btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group>.btn-check:checked+.btn,
.btn-group>.btn-check:focus+.btn,
.btn-group>.btn:hover,
.btn-group>.btn:focus,
.btn-group>.btn:active,
.btn-group>.btn.active,
.btn-group-vertical>.btn-check:checked+.btn,
.btn-group-vertical>.btn-check:focus+.btn,
.btn-group-vertical>.btn:hover,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: 4px;
}

.btn-group>:not(.btn-check:first-child)+.btn,
.btn-group>.btn-group:not(:first-child) {
  margin-left: calc(var(--bs-border-width)*-1);
}

.btn-group>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group>.btn.dropdown-toggle-split:first-child,
.btn-group>.btn-group:not(:last-child)>.btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group>.btn:nth-child(n+3),
.btn-group>:not(.btn-check)+.btn,
.btn-group>.btn-group:not(:first-child)>.btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 15px;
  padding-left: 15px;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm+.dropdown-toggle-split,
.btn-group-sm>.btn+.dropdown-toggle-split {
  padding-right: .375rem;
  padding-left: .375rem;
}

.btn-lg+.dropdown-toggle-split,
.btn-group-lg>.btn+.dropdown-toggle-split {
  padding-right: 24px;
  padding-left: 24px;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical>.btn,
.btn-group-vertical>.btn-group {
  width: 100%;
}

.btn-group-vertical>.btn:not(:first-child),
.btn-group-vertical>.btn-group:not(:first-child) {
  margin-top: calc(var(--bs-border-width)*-1);
}

.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical>.btn-group:not(:last-child)>.btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical>.btn~.btn,
.btn-group-vertical>.btn-group:not(:first-child)>.btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  background: none;
  border: 0;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link:hover,
.nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}

.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 .25rem rgba(33,120,196,.25);
}

.nav-link.disabled,
.nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
  --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
  --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}

.nav-tabs .nav-link {
  margin-bottom: calc(-1*var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid rgba(0,0,0,0);
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}

.nav-tabs .dropdown-menu {
  margin-top: calc(-1*var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #ffffff;
  --bs-nav-pills-link-active-bg: #2178c4;
}

.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-underline {
  --bs-nav-underline-gap: 32px;
  --bs-nav-underline-border-width: 0.125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap);
}

.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--bs-nav-underline-border-width) solid rgba(0,0,0,0);
}

.nav-underline .nav-link:hover,
.nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}

.nav-underline .nav-link.active,
.nav-underline .show>.nav-link {
  font-weight: 700;
  color: var(--bs-nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}

.nav-fill>.nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified>.nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content>.tab-pane {
  display: none;
}

.tab-content>.active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 0.65);
  --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), 0.8);
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
  --bs-navbar-active-color: #676879;
  --bs-navbar-brand-padding-y: 0.3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: #676879;
  --bs-navbar-brand-hover-color: #676879;
  --bs-navbar-nav-link-padding-x: 0px;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), 0.15);
  --bs-navbar-toggler-border-radius: 4px;
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}

.navbar>.container,
.navbar>.container-fluid,
.navbar>.container-sm,
.navbar>.container-md,
.navbar>.container-lg,
.navbar>.container-xl,
.navbar>.container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  white-space: nowrap;
}

.navbar-brand:hover,
.navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  color: var(--bs-navbar-active-color);
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: .5rem;
  padding-bottom: .5rem;
  color: var(--bs-navbar-color);
}

.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: rgba(0,0,0,0);
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }

  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: rgba(0,0,0,0) !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }

  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }

  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: rgba(0,0,0,0) !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }

  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }

  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: rgba(0,0,0,0) !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }

  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: rgba(0,0,0,0) !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }

  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: rgba(0,0,0,0) !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }

  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: rgba(0,0,0,0) !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}

.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}

.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark,
.navbar[data-bs-theme=dark] {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #ffffff;
  --bs-navbar-brand-color: #ffffff;
  --bs-navbar-brand-hover-color: #ffffff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme=dark] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}

.card>hr {
  margin-right: 0;
  margin-left: 0;
}

.card>.list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card>.list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card>.list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card>.card-header+.list-group,
.card>.list-group+.card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
}

.card-subtitle {
  margin-top: calc(-0.5*var(--bs-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--bs-card-subtitle-color);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link+.card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}

.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}

.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5*var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1*var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5*var(--bs-card-cap-padding-x));
  border-bottom: 0;
}

.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5*var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5*var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group>.card {
  margin-bottom: var(--bs-card-group-margin);
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }

  .card-group>.card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }

  .card-group>.card+.card {
    margin-left: 0;
    border-left: 0;
  }

  .card-group>.card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group>.card:not(:last-child) .card-img-top,
  .card-group>.card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  .card-group>.card:not(:last-child) .card-img-bottom,
  .card-group>.card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group>.card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group>.card:not(:first-child) .card-img-top,
  .card-group>.card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  .card-group>.card:not(:first-child) .card-img-bottom,
  .card-group>.card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: 0;
  --bs-accordion-inner-border-radius: 0;
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='rgba%280, 0, 0, 0.65%29' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='%230d304e' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-box-shadow: none;
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text-emphasis);
  --bs-accordion-active-bg: none;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}

.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1*var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}

.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}

.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}

.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}

.accordion-item:first-of-type>.accordion-header .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}

.accordion-item:not(:first-of-type) {
  border-top: 0;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-item:last-of-type>.accordion-header .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}

.accordion-item:last-of-type>.accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush>.accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.accordion-flush>.accordion-item:first-child {
  border-top: 0;
}

.accordion-flush>.accordion-item:last-child {
  border-bottom: 0;
}

.accordion-flush>.accordion-item>.accordion-header .accordion-button,
.accordion-flush>.accordion-item>.accordion-header .accordion-button.collapsed {
  border-radius: 0;
}

.accordion-flush>.accordion-item>.accordion-collapse {
  border-radius: 0;
}

[data-bs-theme=dark] .accordion-button::after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%237aaedc'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%237aaedc'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: var(--bs-secondary-color);
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item+.breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}

.breadcrumb-item+.breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/") ;
}

.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: var(--bs-body-bg);
  --bs-pagination-border-width: var(--bs-border-width);
  --bs-pagination-border-color: var(--bs-border-color);
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: var(--bs-tertiary-bg);
  --bs-pagination-hover-border-color: var(--bs-border-color);
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: var(--bs-secondary-bg);
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(33, 120, 196, 0.25);
  --bs-pagination-active-color: #ffffff;
  --bs-pagination-active-bg: #2178c4;
  --bs-pagination-active-border-color: #2178c4;
  --bs-pagination-disabled-color: var(--bs-secondary-color);
  --bs-pagination-disabled-bg: var(--bs-secondary-bg);
  --bs-pagination-disabled-border-color: var(--bs-border-color);
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}

.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}

.page-link.active,
.active>.page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}

.page-link.disabled,
.disabled>.page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--bs-border-width)*-1);
}

.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}

.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: var(--bs-border-radius-lg);
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-border-radius: var(--bs-border-radius-sm);
}

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #ffffff;
  --bs-badge-border-radius: var(--bs-border-radius);
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: var(--bs-border-radius);
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
  color: var(--bs-alert-link-color);
}

.alert-dismissible {
  padding-right: 3rem;
}

.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis);
}

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis);
}

.alert-success {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis);
}

.alert-info {
  --bs-alert-color: var(--bs-info-text-emphasis);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text-emphasis);
}

.alert-warning {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text-emphasis);
}

.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis);
}

.alert-light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis);
}

.alert-dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text-emphasis);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.progress,
.progress-stacked {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: var(--bs-secondary-bg);
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #ffffff;
  --bs-progress-bar-bg: #2178c4;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-stacked>.progress {
  overflow: visible;
}

.progress-stacked>.progress>.progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-bg: var(--bs-body-bg);
  --bs-list-group-border-color: #e7edf3;
  --bs-list-group-border-width: var(--bs-border-width);
  --bs-list-group-border-radius: var(--bs-border-radius);
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: var(--bs-secondary-color);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
  --bs-list-group-action-active-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-bg);
  --bs-list-group-disabled-color: var(--bs-secondary-color);
  --bs-list-group-disabled-bg: var(--bs-body-bg);
  --bs-list-group-active-color: #ffffff;
  --bs-list-group-active-bg: #2178c4;
  --bs-list-group-active-border-color: #2178c4;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}

.list-group-numbered>.list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}

.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}

.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}

.list-group-item+.list-group-item {
  border-top-width: 0;
}

.list-group-item+.list-group-item.active {
  margin-top: calc(-1*var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal>.list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}

.list-group-horizontal>.list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}

.list-group-horizontal>.list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal>.list-group-item+.list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}

.list-group-horizontal>.list-group-item+.list-group-item.active {
  margin-left: calc(-1*var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }

  .list-group-horizontal-sm>.list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-sm>.list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm>.list-group-item+.list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-sm>.list-group-item+.list-group-item.active {
    margin-left: calc(-1*var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }

  .list-group-horizontal-md>.list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-md>.list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md>.list-group-item+.list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-md>.list-group-item+.list-group-item.active {
    margin-left: calc(-1*var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }

  .list-group-horizontal-lg>.list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-lg>.list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg>.list-group-item+.list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-lg>.list-group-item+.list-group-item.active {
    margin-left: calc(-1*var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }

  .list-group-horizontal-xl>.list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xl>.list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl>.list-group-item+.list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-xl>.list-group-item+.list-group-item.active {
    margin-left: calc(-1*var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }

  .list-group-horizontal-xxl>.list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xxl>.list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xxl>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xxl>.list-group-item+.list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-xxl>.list-group-item+.list-group-item.active {
    margin-left: calc(-1*var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush>.list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}

.list-group-flush>.list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-text-emphasis);
}

.list-group-item-secondary {
  --bs-list-group-color: var(--bs-secondary-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-text-emphasis);
}

.list-group-item-success {
  --bs-list-group-color: var(--bs-success-text-emphasis);
  --bs-list-group-bg: var(--bs-success-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-border-subtle);
  --bs-list-group-active-color: var(--bs-success-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-text-emphasis);
}

.list-group-item-info {
  --bs-list-group-color: var(--bs-info-text-emphasis);
  --bs-list-group-bg: var(--bs-info-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-border-subtle);
  --bs-list-group-active-color: var(--bs-info-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-text-emphasis);
}

.list-group-item-warning {
  --bs-list-group-color: var(--bs-warning-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-text-emphasis);
}

.list-group-item-danger {
  --bs-list-group-color: var(--bs-danger-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-text-emphasis);
}

.list-group-item-light {
  --bs-list-group-color: var(--bs-light-text-emphasis);
  --bs-list-group-bg: var(--bs-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-light-border-subtle);
  --bs-list-group-active-color: var(--bs-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-light-text-emphasis);
}

.list-group-item-dark {
  --bs-list-group-color: var(--bs-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dark-text-emphasis);
}

.btn-close {
  --bs-btn-close-color: #000;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(33, 120, 196, 0.25);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: .25em .25em;
  color: var(--bs-btn-close-color);
  background: rgba(0,0,0,0) var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: .375rem;
  opacity: var(--bs-btn-close-opacity);
}

.btn-close:hover {
  color: var(--bs-btn-close-color);
  text-decoration: none;
  opacity: var(--bs-btn-close-hover-opacity);
}

.btn-close:focus {
  outline: 0;
  box-shadow: var(--bs-btn-close-focus-shadow);
  opacity: var(--bs-btn-close-focus-opacity);
}

.btn-close:disabled,
.btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: var(--bs-btn-close-disabled-opacity);
}

.btn-close-white {
  filter: var(--bs-btn-close-white-filter);
}

[data-bs-theme=dark] .btn-close {
  filter: var(--bs-btn-close-white-filter);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: #ffffff;
  --bs-toast-border-width: var(--bs-border-width);
  --bs-toast-border-color: #ffffff;
  --bs-toast-border-radius: none;
  --bs-toast-box-shadow: none;
  --bs-toast-header-color: var(--bs-secondary-color);
  --bs-toast-header-bg: #ffffff;
  --bs-toast-header-border-color: #ffffff;
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}

.toast.showing {
  opacity: 0;
}

.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}

.toast-container>:not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}

.toast-header .btn-close {
  margin-right: calc(-0.5*var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 600px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #ffffff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 0px;
  --bs-modal-border-radius: 4px;
  --bs-modal-box-shadow: var(--bs-box-shadow-sm);
  --bs-modal-inner-border-radius: 4px;
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 0px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 0px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin)*2);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin)*2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #e7edf3;
  --bs-backdrop-opacity: 0.7;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}

.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y)*.5) calc(var(--bs-modal-header-padding-x)*.5);
  margin: calc(-0.5*var(--bs-modal-header-padding-y)) calc(-0.5*var(--bs-modal-header-padding-x)) calc(-0.5*var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap)*.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}

.modal-footer>* {
  margin: calc(var(--bs-modal-footer-gap)*.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 6.25rem;
    --bs-modal-box-shadow: var(--bs-box-shadow);
  }

  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }

  .modal-sm {
    --bs-modal-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}

.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: var(--bs-body-bg);
  --bs-tooltip-bg: #ffffff;
  --bs-tooltip-border-radius: 4px;
  --bs-tooltip-opacity: 1;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  margin: var(--bs-tooltip-margin);
  font-family: "Helvetica Neue",Arial,sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}

.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}

.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: rgba(0,0,0,0);
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: calc(-1*var(--bs-tooltip-arrow-height));
}

.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width)*.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

.bs-tooltip-end .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: calc(-1*var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}

.bs-tooltip-end .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width)*.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width)*.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

.bs-tooltip-bottom .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: calc(-1*var(--bs-tooltip-arrow-height));
}

.bs-tooltip-bottom .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width)*.5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

.bs-tooltip-start .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: calc(-1*var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}

.bs-tooltip-start .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width)*.5) 0 calc(var(--bs-tooltip-arrow-width)*.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.875rem;
  --bs-popover-bg: var(--bs-body-bg);
  --bs-popover-border-width: var(--bs-border-width);
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: var(--bs-border-radius-lg);
  --bs-popover-inner-border-radius: calc(var(--bs-border-radius-lg) - var(--bs-border-width));
  --bs-popover-box-shadow: var(--bs-box-shadow);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: inherit;
  --bs-popover-header-bg: var(--bs-secondary-bg);
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: var(--bs-body-color);
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: "Helvetica Neue",Arial,sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
}

.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}

.popover .popover-arrow::before,
.popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: rgba(0,0,0,0);
  border-style: solid;
  border-width: 0;
}

.bs-popover-top>.popover-arrow,
.bs-popover-auto[data-popper-placement^=top]>.popover-arrow {
  bottom: calc(-1*(var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}

.bs-popover-top>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.bs-popover-top>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width)*.5) 0;
}

.bs-popover-top>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}

.bs-popover-top>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

.bs-popover-end>.popover-arrow,
.bs-popover-auto[data-popper-placement^=right]>.popover-arrow {
  left: calc(-1*(var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.bs-popover-end>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.bs-popover-end>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width)*.5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width)*.5) 0;
}

.bs-popover-end>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}

.bs-popover-end>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

.bs-popover-bottom>.popover-arrow,
.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow {
  top: calc(-1*(var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}

.bs-popover-bottom>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.bs-popover-bottom>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width)*.5) var(--bs-popover-arrow-height);
}

.bs-popover-bottom>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}

.bs-popover-bottom>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-0.5*var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

.bs-popover-start>.popover-arrow,
.bs-popover-auto[data-popper-placement^=left]>.popover-arrow {
  right: calc(-1*(var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.bs-popover-start>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.bs-popover-start>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width)*.5) 0 calc(var(--bs-popover-arrow-width)*.5) var(--bs-popover-arrow-height);
}

.bs-popover-start>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}

.bs-popover-start>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) ;
  }
}

.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: rgba(0,0,0,0);
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}

.offcanvas,
.offcanvas-xxl,
.offcanvas-xl,
.offcanvas-lg,
.offcanvas-md,
.offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 375px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: 0px;
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: var(--bs-box-shadow-sm);
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.5;
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}

@media (max-width: 575.98px)and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}

@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }

  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }

  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }

  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }

  .offcanvas-sm.showing,
  .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-sm.showing,
  .offcanvas-sm.hiding,
  .offcanvas-sm.show {
    visibility: visible;
  }
}

@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: rgba(0,0,0,0) !important;
  }

  .offcanvas-sm .offcanvas-header {
    display: none;
  }

  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: rgba(0,0,0,0) !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}

@media (max-width: 767.98px)and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }

  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }

  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }

  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }

  .offcanvas-md.showing,
  .offcanvas-md.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-md.showing,
  .offcanvas-md.hiding,
  .offcanvas-md.show {
    visibility: visible;
  }
}

@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: rgba(0,0,0,0) !important;
  }

  .offcanvas-md .offcanvas-header {
    display: none;
  }

  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: rgba(0,0,0,0) !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}

@media (max-width: 991.98px)and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }

  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }

  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }

  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }

  .offcanvas-lg.showing,
  .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-lg.showing,
  .offcanvas-lg.hiding,
  .offcanvas-lg.show {
    visibility: visible;
  }
}

@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: rgba(0,0,0,0) !important;
  }

  .offcanvas-lg .offcanvas-header {
    display: none;
  }

  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: rgba(0,0,0,0) !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}

@media (max-width: 1199.98px)and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }

  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }

  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }

  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }

  .offcanvas-xl.showing,
  .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-xl.showing,
  .offcanvas-xl.hiding,
  .offcanvas-xl.show {
    visibility: visible;
  }
}

@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: rgba(0,0,0,0) !important;
  }

  .offcanvas-xl .offcanvas-header {
    display: none;
  }

  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: rgba(0,0,0,0) !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}

@media (max-width: 1399.98px)and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }

  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }

  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }

  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }

  .offcanvas-xxl.showing,
  .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-xxl.showing,
  .offcanvas-xxl.hiding,
  .offcanvas-xxl.show {
    visibility: visible;
  }
}

@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: rgba(0,0,0,0) !important;
  }

  .offcanvas-xxl .offcanvas-header {
    display: none;
  }

  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: rgba(0,0,0,0) !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: var(--bs-offcanvas-transition);
}

@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}

.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}

.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}

.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}

.offcanvas.showing,
.offcanvas.show:not(.hiding) {
  transform: none;
}

.offcanvas.showing,
.offcanvas.hiding,
.offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #e7edf3;
}

.offcanvas-backdrop.fade {
  opacity: 0;
}

.offcanvas-backdrop.show {
  opacity: .7;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}

.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y)*.5) calc(var(--bs-offcanvas-padding-x)*.5);
  margin: calc(-0.5*var(--bs-offcanvas-padding-y)) calc(-0.5*var(--bs-offcanvas-padding-x)) calc(-0.5*var(--bs-offcanvas-padding-y)) auto;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: .5;
}

.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: .6em;
}

.placeholder-sm {
  min-height: .8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: .2;
  }
}

.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(var(--bs-secondary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #000 !important;
  background-color: RGBA(var(--bs-success-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #fff !important;
  background-color: RGBA(var(--bs-info-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(var(--bs-warning-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(var(--bs-danger-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(var(--bs-light-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(var(--bs-dark-rgb), var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-primary:hover,
.link-primary:focus {
  color: RGBA(26, 96, 157, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(26, 96, 157, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary:hover,
.link-secondary:focus {
  color: RGBA(82, 83, 97, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(82, 83, 97, var(--bs-link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-success:hover,
.link-success:focus {
  color: RGBA(75, 181, 155, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(75, 181, 155, var(--bs-link-underline-opacity, 1)) !important;
}

.link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-info:hover,
.link-info:focus {
  color: RGBA(26, 96, 157, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(26, 96, 157, var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning {
  color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning:hover,
.link-warning:focus {
  color: RGBA(224, 145, 73, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(224, 145, 73, var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger:hover,
.link-danger:focus {
  color: RGBA(146, 42, 50, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(146, 42, 50, var(--bs-link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-light:hover,
.link-light:focus {
  color: RGBA(255, 255, 255, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 255, 255, var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark:hover,
.link-dark:focus {
  color: RGBA(27, 30, 50, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(27, 30, 50, var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis:hover,
.link-body-emphasis:focus {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 0.75)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75)) !important;
}

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color);
}

.icon-link {
  display: inline-flex;
  gap: .375rem;
  align-items: center;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
  text-underline-offset: .25em;
  backface-visibility: hidden;
}

.icon-link>.bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: .2s ease-in-out transform;
}

@media (prefers-reduced-motion: reduce) {
  .icon-link>.bi {
    transition: none;
  }
}

.icon-link-hover:hover>.bi,
.icon-link-hover:focus-visible>.bi {
  transform: var(--bs-icon-link-transform, translate3d(0.25em, 0, 0));
}

.ratio {
  position: relative;
  width: 100%;
}

.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}

.ratio>* {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: var(--bs-border-width);
  min-height: 1em;
  background-color: currentcolor;
  opacity: .25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-scale {
  object-fit: scale-down !important;
}

.object-fit-none {
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: .25 !important;
}

.opacity-50 {
  opacity: .5 !important;
}

.opacity-75 {
  opacity: .75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: var(--bs-box-shadow) !important;
}

.shadow-sm {
  box-shadow: var(--bs-box-shadow-sm) !important;
}

.shadow-lg {
  box-shadow: var(--bs-box-shadow-lg) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: .25rem !important;
}

.m-2 {
  margin: .5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-6 {
  margin: 3.5rem !important;
}

.m-7 {
  margin: 4rem !important;
}

.m-8 {
  margin: 4.5rem !important;
}

.m-9 {
  margin: 5rem !important;
}

.m-10 {
  margin: 5.5rem !important;
}

.m-11 {
  margin: 6rem !important;
}

.m-12 {
  margin: 6.5rem !important;
}

.m-13 {
  margin: 7rem !important;
}

.m-14 {
  margin: 7.5rem !important;
}

.m-15 {
  margin: 8rem !important;
}

.m-16 {
  margin: 8.5rem !important;
}

.m-17 {
  margin: 9rem !important;
}

.m-18 {
  margin: 9.5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: .25rem !important;
  margin-left: .25rem !important;
}

.mx-2 {
  margin-right: .5rem !important;
  margin-left: .5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-6 {
  margin-right: 3.5rem !important;
  margin-left: 3.5rem !important;
}

.mx-7 {
  margin-right: 4rem !important;
  margin-left: 4rem !important;
}

.mx-8 {
  margin-right: 4.5rem !important;
  margin-left: 4.5rem !important;
}

.mx-9 {
  margin-right: 5rem !important;
  margin-left: 5rem !important;
}

.mx-10 {
  margin-right: 5.5rem !important;
  margin-left: 5.5rem !important;
}

.mx-11 {
  margin-right: 6rem !important;
  margin-left: 6rem !important;
}

.mx-12 {
  margin-right: 6.5rem !important;
  margin-left: 6.5rem !important;
}

.mx-13 {
  margin-right: 7rem !important;
  margin-left: 7rem !important;
}

.mx-14 {
  margin-right: 7.5rem !important;
  margin-left: 7.5rem !important;
}

.mx-15 {
  margin-right: 8rem !important;
  margin-left: 8rem !important;
}

.mx-16 {
  margin-right: 8.5rem !important;
  margin-left: 8.5rem !important;
}

.mx-17 {
  margin-right: 9rem !important;
  margin-left: 9rem !important;
}

.mx-18 {
  margin-right: 9.5rem !important;
  margin-left: 9.5rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: .25rem !important;
  margin-bottom: .25rem !important;
}

.my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-6 {
  margin-top: 3.5rem !important;
  margin-bottom: 3.5rem !important;
}

.my-7 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-8 {
  margin-top: 4.5rem !important;
  margin-bottom: 4.5rem !important;
}

.my-9 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.my-10 {
  margin-top: 5.5rem !important;
  margin-bottom: 5.5rem !important;
}

.my-11 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}

.my-12 {
  margin-top: 6.5rem !important;
  margin-bottom: 6.5rem !important;
}

.my-13 {
  margin-top: 7rem !important;
  margin-bottom: 7rem !important;
}

.my-14 {
  margin-top: 7.5rem !important;
  margin-bottom: 7.5rem !important;
}

.my-15 {
  margin-top: 8rem !important;
  margin-bottom: 8rem !important;
}

.my-16 {
  margin-top: 8.5rem !important;
  margin-bottom: 8.5rem !important;
}

.my-17 {
  margin-top: 9rem !important;
  margin-bottom: 9rem !important;
}

.my-18 {
  margin-top: 9.5rem !important;
  margin-bottom: 9.5rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: .25rem !important;
}

.mt-2 {
  margin-top: .5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-6 {
  margin-top: 3.5rem !important;
}

.mt-7 {
  margin-top: 4rem !important;
}

.mt-8 {
  margin-top: 4.5rem !important;
}

.mt-9 {
  margin-top: 5rem !important;
}

.mt-10 {
  margin-top: 5.5rem !important;
}

.mt-11 {
  margin-top: 6rem !important;
}

.mt-12 {
  margin-top: 6.5rem !important;
}

.mt-13 {
  margin-top: 7rem !important;
}

.mt-14 {
  margin-top: 7.5rem !important;
}

.mt-15 {
  margin-top: 8rem !important;
}

.mt-16 {
  margin-top: 8.5rem !important;
}

.mt-17 {
  margin-top: 9rem !important;
}

.mt-18 {
  margin-top: 9.5rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: .25rem !important;
}

.me-2 {
  margin-right: .5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-6 {
  margin-right: 3.5rem !important;
}

.me-7 {
  margin-right: 4rem !important;
}

.me-8 {
  margin-right: 4.5rem !important;
}

.me-9 {
  margin-right: 5rem !important;
}

.me-10 {
  margin-right: 5.5rem !important;
}

.me-11 {
  margin-right: 6rem !important;
}

.me-12 {
  margin-right: 6.5rem !important;
}

.me-13 {
  margin-right: 7rem !important;
}

.me-14 {
  margin-right: 7.5rem !important;
}

.me-15 {
  margin-right: 8rem !important;
}

.me-16 {
  margin-right: 8.5rem !important;
}

.me-17 {
  margin-right: 9rem !important;
}

.me-18 {
  margin-right: 9.5rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: .25rem !important;
}

.mb-2 {
  margin-bottom: .5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-6 {
  margin-bottom: 3.5rem !important;
}

.mb-7 {
  margin-bottom: 4rem !important;
}

.mb-8 {
  margin-bottom: 4.5rem !important;
}

.mb-9 {
  margin-bottom: 5rem !important;
}

.mb-10 {
  margin-bottom: 5.5rem !important;
}

.mb-11 {
  margin-bottom: 6rem !important;
}

.mb-12 {
  margin-bottom: 6.5rem !important;
}

.mb-13 {
  margin-bottom: 7rem !important;
}

.mb-14 {
  margin-bottom: 7.5rem !important;
}

.mb-15 {
  margin-bottom: 8rem !important;
}

.mb-16 {
  margin-bottom: 8.5rem !important;
}

.mb-17 {
  margin-bottom: 9rem !important;
}

.mb-18 {
  margin-bottom: 9.5rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: .25rem !important;
}

.ms-2 {
  margin-left: .5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-6 {
  margin-left: 3.5rem !important;
}

.ms-7 {
  margin-left: 4rem !important;
}

.ms-8 {
  margin-left: 4.5rem !important;
}

.ms-9 {
  margin-left: 5rem !important;
}

.ms-10 {
  margin-left: 5.5rem !important;
}

.ms-11 {
  margin-left: 6rem !important;
}

.ms-12 {
  margin-left: 6.5rem !important;
}

.ms-13 {
  margin-left: 7rem !important;
}

.ms-14 {
  margin-left: 7.5rem !important;
}

.ms-15 {
  margin-left: 8rem !important;
}

.ms-16 {
  margin-left: 8.5rem !important;
}

.ms-17 {
  margin-left: 9rem !important;
}

.ms-18 {
  margin-left: 9.5rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

.mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n5 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n5 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -1rem !important;
}

.mt-n4 {
  margin-top: -1.5rem !important;
}

.mt-n5 {
  margin-top: -3rem !important;
}

.me-n1 {
  margin-right: -0.25rem !important;
}

.me-n2 {
  margin-right: -0.5rem !important;
}

.me-n3 {
  margin-right: -1rem !important;
}

.me-n4 {
  margin-right: -1.5rem !important;
}

.me-n5 {
  margin-right: -3rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -1rem !important;
}

.mb-n4 {
  margin-bottom: -1.5rem !important;
}

.mb-n5 {
  margin-bottom: -3rem !important;
}

.ms-n1 {
  margin-left: -0.25rem !important;
}

.ms-n2 {
  margin-left: -0.5rem !important;
}

.ms-n3 {
  margin-left: -1rem !important;
}

.ms-n4 {
  margin-left: -1.5rem !important;
}

.ms-n5 {
  margin-left: -3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: .25rem !important;
}

.p-2 {
  padding: .5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.p-6 {
  padding: 3.5rem !important;
}

.p-7 {
  padding: 4rem !important;
}

.p-8 {
  padding: 4.5rem !important;
}

.p-9 {
  padding: 5rem !important;
}

.p-10 {
  padding: 5.5rem !important;
}

.p-11 {
  padding: 6rem !important;
}

.p-12 {
  padding: 6.5rem !important;
}

.p-13 {
  padding: 7rem !important;
}

.p-14 {
  padding: 7.5rem !important;
}

.p-15 {
  padding: 8rem !important;
}

.p-16 {
  padding: 8.5rem !important;
}

.p-17 {
  padding: 9rem !important;
}

.p-18 {
  padding: 9.5rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: .25rem !important;
  padding-left: .25rem !important;
}

.px-2 {
  padding-right: .5rem !important;
  padding-left: .5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-6 {
  padding-right: 3.5rem !important;
  padding-left: 3.5rem !important;
}

.px-7 {
  padding-right: 4rem !important;
  padding-left: 4rem !important;
}

.px-8 {
  padding-right: 4.5rem !important;
  padding-left: 4.5rem !important;
}

.px-9 {
  padding-right: 5rem !important;
  padding-left: 5rem !important;
}

.px-10 {
  padding-right: 5.5rem !important;
  padding-left: 5.5rem !important;
}

.px-11 {
  padding-right: 6rem !important;
  padding-left: 6rem !important;
}

.px-12 {
  padding-right: 6.5rem !important;
  padding-left: 6.5rem !important;
}

.px-13 {
  padding-right: 7rem !important;
  padding-left: 7rem !important;
}

.px-14 {
  padding-right: 7.5rem !important;
  padding-left: 7.5rem !important;
}

.px-15 {
  padding-right: 8rem !important;
  padding-left: 8rem !important;
}

.px-16 {
  padding-right: 8.5rem !important;
  padding-left: 8.5rem !important;
}

.px-17 {
  padding-right: 9rem !important;
  padding-left: 9rem !important;
}

.px-18 {
  padding-right: 9.5rem !important;
  padding-left: 9.5rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

.py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-6 {
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important;
}

.py-7 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-8 {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important;
}

.py-9 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.py-10 {
  padding-top: 5.5rem !important;
  padding-bottom: 5.5rem !important;
}

.py-11 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}

.py-12 {
  padding-top: 6.5rem !important;
  padding-bottom: 6.5rem !important;
}

.py-13 {
  padding-top: 7rem !important;
  padding-bottom: 7rem !important;
}

.py-14 {
  padding-top: 7.5rem !important;
  padding-bottom: 7.5rem !important;
}

.py-15 {
  padding-top: 8rem !important;
  padding-bottom: 8rem !important;
}

.py-16 {
  padding-top: 8.5rem !important;
  padding-bottom: 8.5rem !important;
}

.py-17 {
  padding-top: 9rem !important;
  padding-bottom: 9rem !important;
}

.py-18 {
  padding-top: 9.5rem !important;
  padding-bottom: 9.5rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: .25rem !important;
}

.pt-2 {
  padding-top: .5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pt-6 {
  padding-top: 3.5rem !important;
}

.pt-7 {
  padding-top: 4rem !important;
}

.pt-8 {
  padding-top: 4.5rem !important;
}

.pt-9 {
  padding-top: 5rem !important;
}

.pt-10 {
  padding-top: 5.5rem !important;
}

.pt-11 {
  padding-top: 6rem !important;
}

.pt-12 {
  padding-top: 6.5rem !important;
}

.pt-13 {
  padding-top: 7rem !important;
}

.pt-14 {
  padding-top: 7.5rem !important;
}

.pt-15 {
  padding-top: 8rem !important;
}

.pt-16 {
  padding-top: 8.5rem !important;
}

.pt-17 {
  padding-top: 9rem !important;
}

.pt-18 {
  padding-top: 9.5rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: .25rem !important;
}

.pe-2 {
  padding-right: .5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pe-6 {
  padding-right: 3.5rem !important;
}

.pe-7 {
  padding-right: 4rem !important;
}

.pe-8 {
  padding-right: 4.5rem !important;
}

.pe-9 {
  padding-right: 5rem !important;
}

.pe-10 {
  padding-right: 5.5rem !important;
}

.pe-11 {
  padding-right: 6rem !important;
}

.pe-12 {
  padding-right: 6.5rem !important;
}

.pe-13 {
  padding-right: 7rem !important;
}

.pe-14 {
  padding-right: 7.5rem !important;
}

.pe-15 {
  padding-right: 8rem !important;
}

.pe-16 {
  padding-right: 8.5rem !important;
}

.pe-17 {
  padding-right: 9rem !important;
}

.pe-18 {
  padding-right: 9.5rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: .25rem !important;
}

.pb-2 {
  padding-bottom: .5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.pb-6 {
  padding-bottom: 3.5rem !important;
}

.pb-7 {
  padding-bottom: 4rem !important;
}

.pb-8 {
  padding-bottom: 4.5rem !important;
}

.pb-9 {
  padding-bottom: 5rem !important;
}

.pb-10 {
  padding-bottom: 5.5rem !important;
}

.pb-11 {
  padding-bottom: 6rem !important;
}

.pb-12 {
  padding-bottom: 6.5rem !important;
}

.pb-13 {
  padding-bottom: 7rem !important;
}

.pb-14 {
  padding-bottom: 7.5rem !important;
}

.pb-15 {
  padding-bottom: 8rem !important;
}

.pb-16 {
  padding-bottom: 8.5rem !important;
}

.pb-17 {
  padding-bottom: 9rem !important;
}

.pb-18 {
  padding-bottom: 9.5rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: .25rem !important;
}

.ps-2 {
  padding-left: .5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.ps-6 {
  padding-left: 3.5rem !important;
}

.ps-7 {
  padding-left: 4rem !important;
}

.ps-8 {
  padding-left: 4.5rem !important;
}

.ps-9 {
  padding-left: 5rem !important;
}

.ps-10 {
  padding-left: 5.5rem !important;
}

.ps-11 {
  padding-left: 6rem !important;
}

.ps-12 {
  padding-left: 6.5rem !important;
}

.ps-13 {
  padding-left: 7rem !important;
}

.ps-14 {
  padding-left: 7.5rem !important;
}

.ps-15 {
  padding-left: 8rem !important;
}

.ps-16 {
  padding-left: 8.5rem !important;
}

.ps-17 {
  padding-left: 9rem !important;
}

.ps-18 {
  padding-left: 9.5rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: .25rem !important;
}

.gap-2 {
  gap: .5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.gap-6 {
  gap: 3.5rem !important;
}

.gap-7 {
  gap: 4rem !important;
}

.gap-8 {
  gap: 4.5rem !important;
}

.gap-9 {
  gap: 5rem !important;
}

.gap-10 {
  gap: 5.5rem !important;
}

.gap-11 {
  gap: 6rem !important;
}

.gap-12 {
  gap: 6.5rem !important;
}

.gap-13 {
  gap: 7rem !important;
}

.gap-14 {
  gap: 7.5rem !important;
}

.gap-15 {
  gap: 8rem !important;
}

.gap-16 {
  gap: 8.5rem !important;
}

.gap-17 {
  gap: 9rem !important;
}

.gap-18 {
  gap: 9.5rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: .25rem !important;
}

.row-gap-2 {
  row-gap: .5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 3rem !important;
}

.row-gap-6 {
  row-gap: 3.5rem !important;
}

.row-gap-7 {
  row-gap: 4rem !important;
}

.row-gap-8 {
  row-gap: 4.5rem !important;
}

.row-gap-9 {
  row-gap: 5rem !important;
}

.row-gap-10 {
  row-gap: 5.5rem !important;
}

.row-gap-11 {
  row-gap: 6rem !important;
}

.row-gap-12 {
  row-gap: 6.5rem !important;
}

.row-gap-13 {
  row-gap: 7rem !important;
}

.row-gap-14 {
  row-gap: 7.5rem !important;
}

.row-gap-15 {
  row-gap: 8rem !important;
}

.row-gap-16 {
  row-gap: 8.5rem !important;
}

.row-gap-17 {
  row-gap: 9rem !important;
}

.row-gap-18 {
  row-gap: 9.5rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: .25rem !important;
}

.column-gap-2 {
  column-gap: .5rem !important;
}

.column-gap-3 {
  column-gap: 1rem !important;
}

.column-gap-4 {
  column-gap: 1.5rem !important;
}

.column-gap-5 {
  column-gap: 3rem !important;
}

.column-gap-6 {
  column-gap: 3.5rem !important;
}

.column-gap-7 {
  column-gap: 4rem !important;
}

.column-gap-8 {
  column-gap: 4.5rem !important;
}

.column-gap-9 {
  column-gap: 5rem !important;
}

.column-gap-10 {
  column-gap: 5.5rem !important;
}

.column-gap-11 {
  column-gap: 6rem !important;
}

.column-gap-12 {
  column-gap: 6.5rem !important;
}

.column-gap-13 {
  column-gap: 7rem !important;
}

.column-gap-14 {
  column-gap: 7.5rem !important;
}

.column-gap-15 {
  column-gap: 8rem !important;
}

.column-gap-16 {
  column-gap: 8.5rem !important;
}

.column-gap-17 {
  column-gap: 9rem !important;
}

.column-gap-18 {
  column-gap: 9.5rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fs-7 {
  font-size: 0.875rem !important;
}

.fs-8 {
  font-size: 0.75rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0,0,0,.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255,255,255,.5) !important;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}

.link-opacity-10 {
  --bs-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1;
}

.link-opacity-25 {
  --bs-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25;
}

.link-opacity-50 {
  --bs-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5;
}

.link-opacity-75 {
  --bs-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75;
}

.link-opacity-100 {
  --bs-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1 {
  text-underline-offset: .125em !important;
}

.link-offset-1-hover:hover {
  text-underline-offset: .125em !important;
}

.link-offset-2 {
  text-underline-offset: .25em !important;
}

.link-offset-2-hover:hover {
  text-underline-offset: .25em !important;
}

.link-offset-3 {
  text-underline-offset: .375em !important;
}

.link-offset-3-hover:hover {
  text-underline-offset: .375em !important;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1;
}

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: rgba(0,0,0,0) !important;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .object-fit-sm-contain {
    object-fit: contain !important;
  }

  .object-fit-sm-cover {
    object-fit: cover !important;
  }

  .object-fit-sm-fill {
    object-fit: fill !important;
  }

  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }

  .object-fit-sm-none {
    object-fit: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-inline-grid {
    display: inline-grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: .25rem !important;
  }

  .m-sm-2 {
    margin: .5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-6 {
    margin: 3.5rem !important;
  }

  .m-sm-7 {
    margin: 4rem !important;
  }

  .m-sm-8 {
    margin: 4.5rem !important;
  }

  .m-sm-9 {
    margin: 5rem !important;
  }

  .m-sm-10 {
    margin: 5.5rem !important;
  }

  .m-sm-11 {
    margin: 6rem !important;
  }

  .m-sm-12 {
    margin: 6.5rem !important;
  }

  .m-sm-13 {
    margin: 7rem !important;
  }

  .m-sm-14 {
    margin: 7.5rem !important;
  }

  .m-sm-15 {
    margin: 8rem !important;
  }

  .m-sm-16 {
    margin: 8.5rem !important;
  }

  .m-sm-17 {
    margin: 9rem !important;
  }

  .m-sm-18 {
    margin: 9.5rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: .25rem !important;
    margin-left: .25rem !important;
  }

  .mx-sm-2 {
    margin-right: .5rem !important;
    margin-left: .5rem !important;
  }

  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-6 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-sm-7 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-sm-8 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-sm-9 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-sm-10 {
    margin-right: 5.5rem !important;
    margin-left: 5.5rem !important;
  }

  .mx-sm-11 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }

  .mx-sm-12 {
    margin-right: 6.5rem !important;
    margin-left: 6.5rem !important;
  }

  .mx-sm-13 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }

  .mx-sm-14 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }

  .mx-sm-15 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }

  .mx-sm-16 {
    margin-right: 8.5rem !important;
    margin-left: 8.5rem !important;
  }

  .mx-sm-17 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }

  .mx-sm-18 {
    margin-right: 9.5rem !important;
    margin-left: 9.5rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-sm-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-6 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-sm-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-sm-8 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-sm-9 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-sm-10 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important;
  }

  .my-sm-11 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-sm-12 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important;
  }

  .my-sm-13 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }

  .my-sm-14 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }

  .my-sm-15 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .my-sm-16 {
    margin-top: 8.5rem !important;
    margin-bottom: 8.5rem !important;
  }

  .my-sm-17 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .my-sm-18 {
    margin-top: 9.5rem !important;
    margin-bottom: 9.5rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: .25rem !important;
  }

  .mt-sm-2 {
    margin-top: .5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-6 {
    margin-top: 3.5rem !important;
  }

  .mt-sm-7 {
    margin-top: 4rem !important;
  }

  .mt-sm-8 {
    margin-top: 4.5rem !important;
  }

  .mt-sm-9 {
    margin-top: 5rem !important;
  }

  .mt-sm-10 {
    margin-top: 5.5rem !important;
  }

  .mt-sm-11 {
    margin-top: 6rem !important;
  }

  .mt-sm-12 {
    margin-top: 6.5rem !important;
  }

  .mt-sm-13 {
    margin-top: 7rem !important;
  }

  .mt-sm-14 {
    margin-top: 7.5rem !important;
  }

  .mt-sm-15 {
    margin-top: 8rem !important;
  }

  .mt-sm-16 {
    margin-top: 8.5rem !important;
  }

  .mt-sm-17 {
    margin-top: 9rem !important;
  }

  .mt-sm-18 {
    margin-top: 9.5rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: .25rem !important;
  }

  .me-sm-2 {
    margin-right: .5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-6 {
    margin-right: 3.5rem !important;
  }

  .me-sm-7 {
    margin-right: 4rem !important;
  }

  .me-sm-8 {
    margin-right: 4.5rem !important;
  }

  .me-sm-9 {
    margin-right: 5rem !important;
  }

  .me-sm-10 {
    margin-right: 5.5rem !important;
  }

  .me-sm-11 {
    margin-right: 6rem !important;
  }

  .me-sm-12 {
    margin-right: 6.5rem !important;
  }

  .me-sm-13 {
    margin-right: 7rem !important;
  }

  .me-sm-14 {
    margin-right: 7.5rem !important;
  }

  .me-sm-15 {
    margin-right: 8rem !important;
  }

  .me-sm-16 {
    margin-right: 8.5rem !important;
  }

  .me-sm-17 {
    margin-right: 9rem !important;
  }

  .me-sm-18 {
    margin-right: 9.5rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: .25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: .5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-6 {
    margin-bottom: 3.5rem !important;
  }

  .mb-sm-7 {
    margin-bottom: 4rem !important;
  }

  .mb-sm-8 {
    margin-bottom: 4.5rem !important;
  }

  .mb-sm-9 {
    margin-bottom: 5rem !important;
  }

  .mb-sm-10 {
    margin-bottom: 5.5rem !important;
  }

  .mb-sm-11 {
    margin-bottom: 6rem !important;
  }

  .mb-sm-12 {
    margin-bottom: 6.5rem !important;
  }

  .mb-sm-13 {
    margin-bottom: 7rem !important;
  }

  .mb-sm-14 {
    margin-bottom: 7.5rem !important;
  }

  .mb-sm-15 {
    margin-bottom: 8rem !important;
  }

  .mb-sm-16 {
    margin-bottom: 8.5rem !important;
  }

  .mb-sm-17 {
    margin-bottom: 9rem !important;
  }

  .mb-sm-18 {
    margin-bottom: 9.5rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: .25rem !important;
  }

  .ms-sm-2 {
    margin-left: .5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-6 {
    margin-left: 3.5rem !important;
  }

  .ms-sm-7 {
    margin-left: 4rem !important;
  }

  .ms-sm-8 {
    margin-left: 4.5rem !important;
  }

  .ms-sm-9 {
    margin-left: 5rem !important;
  }

  .ms-sm-10 {
    margin-left: 5.5rem !important;
  }

  .ms-sm-11 {
    margin-left: 6rem !important;
  }

  .ms-sm-12 {
    margin-left: 6.5rem !important;
  }

  .ms-sm-13 {
    margin-left: 7rem !important;
  }

  .ms-sm-14 {
    margin-left: 7.5rem !important;
  }

  .ms-sm-15 {
    margin-left: 8rem !important;
  }

  .ms-sm-16 {
    margin-left: 8.5rem !important;
  }

  .ms-sm-17 {
    margin-left: 9rem !important;
  }

  .ms-sm-18 {
    margin-left: 9.5rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-sm-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-sm-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-sm-n3 {
    margin-top: -1rem !important;
  }

  .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-sm-n5 {
    margin-top: -3rem !important;
  }

  .me-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .me-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .me-sm-n3 {
    margin-right: -1rem !important;
  }

  .me-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .me-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-sm-n3 {
    margin-left: -1rem !important;
  }

  .ms-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-sm-n5 {
    margin-left: -3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: .25rem !important;
  }

  .p-sm-2 {
    padding: .5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .p-sm-6 {
    padding: 3.5rem !important;
  }

  .p-sm-7 {
    padding: 4rem !important;
  }

  .p-sm-8 {
    padding: 4.5rem !important;
  }

  .p-sm-9 {
    padding: 5rem !important;
  }

  .p-sm-10 {
    padding: 5.5rem !important;
  }

  .p-sm-11 {
    padding: 6rem !important;
  }

  .p-sm-12 {
    padding: 6.5rem !important;
  }

  .p-sm-13 {
    padding: 7rem !important;
  }

  .p-sm-14 {
    padding: 7.5rem !important;
  }

  .p-sm-15 {
    padding: 8rem !important;
  }

  .p-sm-16 {
    padding: 8.5rem !important;
  }

  .p-sm-17 {
    padding: 9rem !important;
  }

  .p-sm-18 {
    padding: 9.5rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: .25rem !important;
    padding-left: .25rem !important;
  }

  .px-sm-2 {
    padding-right: .5rem !important;
    padding-left: .5rem !important;
  }

  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-sm-6 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-sm-7 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-sm-8 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-sm-9 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .px-sm-10 {
    padding-right: 5.5rem !important;
    padding-left: 5.5rem !important;
  }

  .px-sm-11 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }

  .px-sm-12 {
    padding-right: 6.5rem !important;
    padding-left: 6.5rem !important;
  }

  .px-sm-13 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }

  .px-sm-14 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }

  .px-sm-15 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }

  .px-sm-16 {
    padding-right: 8.5rem !important;
    padding-left: 8.5rem !important;
  }

  .px-sm-17 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }

  .px-sm-18 {
    padding-right: 9.5rem !important;
    padding-left: 9.5rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-sm-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-sm-6 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-sm-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-sm-8 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-sm-9 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-sm-10 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important;
  }

  .py-sm-11 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-sm-12 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important;
  }

  .py-sm-13 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }

  .py-sm-14 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }

  .py-sm-15 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .py-sm-16 {
    padding-top: 8.5rem !important;
    padding-bottom: 8.5rem !important;
  }

  .py-sm-17 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .py-sm-18 {
    padding-top: 9.5rem !important;
    padding-bottom: 9.5rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: .25rem !important;
  }

  .pt-sm-2 {
    padding-top: .5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pt-sm-6 {
    padding-top: 3.5rem !important;
  }

  .pt-sm-7 {
    padding-top: 4rem !important;
  }

  .pt-sm-8 {
    padding-top: 4.5rem !important;
  }

  .pt-sm-9 {
    padding-top: 5rem !important;
  }

  .pt-sm-10 {
    padding-top: 5.5rem !important;
  }

  .pt-sm-11 {
    padding-top: 6rem !important;
  }

  .pt-sm-12 {
    padding-top: 6.5rem !important;
  }

  .pt-sm-13 {
    padding-top: 7rem !important;
  }

  .pt-sm-14 {
    padding-top: 7.5rem !important;
  }

  .pt-sm-15 {
    padding-top: 8rem !important;
  }

  .pt-sm-16 {
    padding-top: 8.5rem !important;
  }

  .pt-sm-17 {
    padding-top: 9rem !important;
  }

  .pt-sm-18 {
    padding-top: 9.5rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: .25rem !important;
  }

  .pe-sm-2 {
    padding-right: .5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pe-sm-6 {
    padding-right: 3.5rem !important;
  }

  .pe-sm-7 {
    padding-right: 4rem !important;
  }

  .pe-sm-8 {
    padding-right: 4.5rem !important;
  }

  .pe-sm-9 {
    padding-right: 5rem !important;
  }

  .pe-sm-10 {
    padding-right: 5.5rem !important;
  }

  .pe-sm-11 {
    padding-right: 6rem !important;
  }

  .pe-sm-12 {
    padding-right: 6.5rem !important;
  }

  .pe-sm-13 {
    padding-right: 7rem !important;
  }

  .pe-sm-14 {
    padding-right: 7.5rem !important;
  }

  .pe-sm-15 {
    padding-right: 8rem !important;
  }

  .pe-sm-16 {
    padding-right: 8.5rem !important;
  }

  .pe-sm-17 {
    padding-right: 9rem !important;
  }

  .pe-sm-18 {
    padding-right: 9.5rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: .25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: .5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pb-sm-6 {
    padding-bottom: 3.5rem !important;
  }

  .pb-sm-7 {
    padding-bottom: 4rem !important;
  }

  .pb-sm-8 {
    padding-bottom: 4.5rem !important;
  }

  .pb-sm-9 {
    padding-bottom: 5rem !important;
  }

  .pb-sm-10 {
    padding-bottom: 5.5rem !important;
  }

  .pb-sm-11 {
    padding-bottom: 6rem !important;
  }

  .pb-sm-12 {
    padding-bottom: 6.5rem !important;
  }

  .pb-sm-13 {
    padding-bottom: 7rem !important;
  }

  .pb-sm-14 {
    padding-bottom: 7.5rem !important;
  }

  .pb-sm-15 {
    padding-bottom: 8rem !important;
  }

  .pb-sm-16 {
    padding-bottom: 8.5rem !important;
  }

  .pb-sm-17 {
    padding-bottom: 9rem !important;
  }

  .pb-sm-18 {
    padding-bottom: 9.5rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: .25rem !important;
  }

  .ps-sm-2 {
    padding-left: .5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }

  .ps-sm-6 {
    padding-left: 3.5rem !important;
  }

  .ps-sm-7 {
    padding-left: 4rem !important;
  }

  .ps-sm-8 {
    padding-left: 4.5rem !important;
  }

  .ps-sm-9 {
    padding-left: 5rem !important;
  }

  .ps-sm-10 {
    padding-left: 5.5rem !important;
  }

  .ps-sm-11 {
    padding-left: 6rem !important;
  }

  .ps-sm-12 {
    padding-left: 6.5rem !important;
  }

  .ps-sm-13 {
    padding-left: 7rem !important;
  }

  .ps-sm-14 {
    padding-left: 7.5rem !important;
  }

  .ps-sm-15 {
    padding-left: 8rem !important;
  }

  .ps-sm-16 {
    padding-left: 8.5rem !important;
  }

  .ps-sm-17 {
    padding-left: 9rem !important;
  }

  .ps-sm-18 {
    padding-left: 9.5rem !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: .25rem !important;
  }

  .gap-sm-2 {
    gap: .5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 3rem !important;
  }

  .gap-sm-6 {
    gap: 3.5rem !important;
  }

  .gap-sm-7 {
    gap: 4rem !important;
  }

  .gap-sm-8 {
    gap: 4.5rem !important;
  }

  .gap-sm-9 {
    gap: 5rem !important;
  }

  .gap-sm-10 {
    gap: 5.5rem !important;
  }

  .gap-sm-11 {
    gap: 6rem !important;
  }

  .gap-sm-12 {
    gap: 6.5rem !important;
  }

  .gap-sm-13 {
    gap: 7rem !important;
  }

  .gap-sm-14 {
    gap: 7.5rem !important;
  }

  .gap-sm-15 {
    gap: 8rem !important;
  }

  .gap-sm-16 {
    gap: 8.5rem !important;
  }

  .gap-sm-17 {
    gap: 9rem !important;
  }

  .gap-sm-18 {
    gap: 9.5rem !important;
  }

  .row-gap-sm-0 {
    row-gap: 0 !important;
  }

  .row-gap-sm-1 {
    row-gap: .25rem !important;
  }

  .row-gap-sm-2 {
    row-gap: .5rem !important;
  }

  .row-gap-sm-3 {
    row-gap: 1rem !important;
  }

  .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-sm-5 {
    row-gap: 3rem !important;
  }

  .row-gap-sm-6 {
    row-gap: 3.5rem !important;
  }

  .row-gap-sm-7 {
    row-gap: 4rem !important;
  }

  .row-gap-sm-8 {
    row-gap: 4.5rem !important;
  }

  .row-gap-sm-9 {
    row-gap: 5rem !important;
  }

  .row-gap-sm-10 {
    row-gap: 5.5rem !important;
  }

  .row-gap-sm-11 {
    row-gap: 6rem !important;
  }

  .row-gap-sm-12 {
    row-gap: 6.5rem !important;
  }

  .row-gap-sm-13 {
    row-gap: 7rem !important;
  }

  .row-gap-sm-14 {
    row-gap: 7.5rem !important;
  }

  .row-gap-sm-15 {
    row-gap: 8rem !important;
  }

  .row-gap-sm-16 {
    row-gap: 8.5rem !important;
  }

  .row-gap-sm-17 {
    row-gap: 9rem !important;
  }

  .row-gap-sm-18 {
    row-gap: 9.5rem !important;
  }

  .column-gap-sm-0 {
    column-gap: 0 !important;
  }

  .column-gap-sm-1 {
    column-gap: .25rem !important;
  }

  .column-gap-sm-2 {
    column-gap: .5rem !important;
  }

  .column-gap-sm-3 {
    column-gap: 1rem !important;
  }

  .column-gap-sm-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-sm-5 {
    column-gap: 3rem !important;
  }

  .column-gap-sm-6 {
    column-gap: 3.5rem !important;
  }

  .column-gap-sm-7 {
    column-gap: 4rem !important;
  }

  .column-gap-sm-8 {
    column-gap: 4.5rem !important;
  }

  .column-gap-sm-9 {
    column-gap: 5rem !important;
  }

  .column-gap-sm-10 {
    column-gap: 5.5rem !important;
  }

  .column-gap-sm-11 {
    column-gap: 6rem !important;
  }

  .column-gap-sm-12 {
    column-gap: 6.5rem !important;
  }

  .column-gap-sm-13 {
    column-gap: 7rem !important;
  }

  .column-gap-sm-14 {
    column-gap: 7.5rem !important;
  }

  .column-gap-sm-15 {
    column-gap: 8rem !important;
  }

  .column-gap-sm-16 {
    column-gap: 8.5rem !important;
  }

  .column-gap-sm-17 {
    column-gap: 9rem !important;
  }

  .column-gap-sm-18 {
    column-gap: 9.5rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .object-fit-md-contain {
    object-fit: contain !important;
  }

  .object-fit-md-cover {
    object-fit: cover !important;
  }

  .object-fit-md-fill {
    object-fit: fill !important;
  }

  .object-fit-md-scale {
    object-fit: scale-down !important;
  }

  .object-fit-md-none {
    object-fit: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-inline-grid {
    display: inline-grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: .25rem !important;
  }

  .m-md-2 {
    margin: .5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-6 {
    margin: 3.5rem !important;
  }

  .m-md-7 {
    margin: 4rem !important;
  }

  .m-md-8 {
    margin: 4.5rem !important;
  }

  .m-md-9 {
    margin: 5rem !important;
  }

  .m-md-10 {
    margin: 5.5rem !important;
  }

  .m-md-11 {
    margin: 6rem !important;
  }

  .m-md-12 {
    margin: 6.5rem !important;
  }

  .m-md-13 {
    margin: 7rem !important;
  }

  .m-md-14 {
    margin: 7.5rem !important;
  }

  .m-md-15 {
    margin: 8rem !important;
  }

  .m-md-16 {
    margin: 8.5rem !important;
  }

  .m-md-17 {
    margin: 9rem !important;
  }

  .m-md-18 {
    margin: 9.5rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: .25rem !important;
    margin-left: .25rem !important;
  }

  .mx-md-2 {
    margin-right: .5rem !important;
    margin-left: .5rem !important;
  }

  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-6 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-md-7 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-md-8 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-md-9 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-md-10 {
    margin-right: 5.5rem !important;
    margin-left: 5.5rem !important;
  }

  .mx-md-11 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }

  .mx-md-12 {
    margin-right: 6.5rem !important;
    margin-left: 6.5rem !important;
  }

  .mx-md-13 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }

  .mx-md-14 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }

  .mx-md-15 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }

  .mx-md-16 {
    margin-right: 8.5rem !important;
    margin-left: 8.5rem !important;
  }

  .mx-md-17 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }

  .mx-md-18 {
    margin-right: 9.5rem !important;
    margin-left: 9.5rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-md-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-6 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-md-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-md-8 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-md-9 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-md-10 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important;
  }

  .my-md-11 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-md-12 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important;
  }

  .my-md-13 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }

  .my-md-14 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }

  .my-md-15 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .my-md-16 {
    margin-top: 8.5rem !important;
    margin-bottom: 8.5rem !important;
  }

  .my-md-17 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .my-md-18 {
    margin-top: 9.5rem !important;
    margin-bottom: 9.5rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: .25rem !important;
  }

  .mt-md-2 {
    margin-top: .5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-6 {
    margin-top: 3.5rem !important;
  }

  .mt-md-7 {
    margin-top: 4rem !important;
  }

  .mt-md-8 {
    margin-top: 4.5rem !important;
  }

  .mt-md-9 {
    margin-top: 5rem !important;
  }

  .mt-md-10 {
    margin-top: 5.5rem !important;
  }

  .mt-md-11 {
    margin-top: 6rem !important;
  }

  .mt-md-12 {
    margin-top: 6.5rem !important;
  }

  .mt-md-13 {
    margin-top: 7rem !important;
  }

  .mt-md-14 {
    margin-top: 7.5rem !important;
  }

  .mt-md-15 {
    margin-top: 8rem !important;
  }

  .mt-md-16 {
    margin-top: 8.5rem !important;
  }

  .mt-md-17 {
    margin-top: 9rem !important;
  }

  .mt-md-18 {
    margin-top: 9.5rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: .25rem !important;
  }

  .me-md-2 {
    margin-right: .5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-6 {
    margin-right: 3.5rem !important;
  }

  .me-md-7 {
    margin-right: 4rem !important;
  }

  .me-md-8 {
    margin-right: 4.5rem !important;
  }

  .me-md-9 {
    margin-right: 5rem !important;
  }

  .me-md-10 {
    margin-right: 5.5rem !important;
  }

  .me-md-11 {
    margin-right: 6rem !important;
  }

  .me-md-12 {
    margin-right: 6.5rem !important;
  }

  .me-md-13 {
    margin-right: 7rem !important;
  }

  .me-md-14 {
    margin-right: 7.5rem !important;
  }

  .me-md-15 {
    margin-right: 8rem !important;
  }

  .me-md-16 {
    margin-right: 8.5rem !important;
  }

  .me-md-17 {
    margin-right: 9rem !important;
  }

  .me-md-18 {
    margin-right: 9.5rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: .25rem !important;
  }

  .mb-md-2 {
    margin-bottom: .5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-6 {
    margin-bottom: 3.5rem !important;
  }

  .mb-md-7 {
    margin-bottom: 4rem !important;
  }

  .mb-md-8 {
    margin-bottom: 4.5rem !important;
  }

  .mb-md-9 {
    margin-bottom: 5rem !important;
  }

  .mb-md-10 {
    margin-bottom: 5.5rem !important;
  }

  .mb-md-11 {
    margin-bottom: 6rem !important;
  }

  .mb-md-12 {
    margin-bottom: 6.5rem !important;
  }

  .mb-md-13 {
    margin-bottom: 7rem !important;
  }

  .mb-md-14 {
    margin-bottom: 7.5rem !important;
  }

  .mb-md-15 {
    margin-bottom: 8rem !important;
  }

  .mb-md-16 {
    margin-bottom: 8.5rem !important;
  }

  .mb-md-17 {
    margin-bottom: 9rem !important;
  }

  .mb-md-18 {
    margin-bottom: 9.5rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: .25rem !important;
  }

  .ms-md-2 {
    margin-left: .5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-6 {
    margin-left: 3.5rem !important;
  }

  .ms-md-7 {
    margin-left: 4rem !important;
  }

  .ms-md-8 {
    margin-left: 4.5rem !important;
  }

  .ms-md-9 {
    margin-left: 5rem !important;
  }

  .ms-md-10 {
    margin-left: 5.5rem !important;
  }

  .ms-md-11 {
    margin-left: 6rem !important;
  }

  .ms-md-12 {
    margin-left: 6.5rem !important;
  }

  .ms-md-13 {
    margin-left: 7rem !important;
  }

  .ms-md-14 {
    margin-left: 7.5rem !important;
  }

  .ms-md-15 {
    margin-left: 8rem !important;
  }

  .ms-md-16 {
    margin-left: 8.5rem !important;
  }

  .ms-md-17 {
    margin-left: 9rem !important;
  }

  .ms-md-18 {
    margin-left: 9.5rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-md-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-md-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-md-n3 {
    margin-top: -1rem !important;
  }

  .mt-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-md-n5 {
    margin-top: -3rem !important;
  }

  .me-md-n1 {
    margin-right: -0.25rem !important;
  }

  .me-md-n2 {
    margin-right: -0.5rem !important;
  }

  .me-md-n3 {
    margin-right: -1rem !important;
  }

  .me-md-n4 {
    margin-right: -1.5rem !important;
  }

  .me-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-md-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-md-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-md-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-md-n3 {
    margin-left: -1rem !important;
  }

  .ms-md-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-md-n5 {
    margin-left: -3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: .25rem !important;
  }

  .p-md-2 {
    padding: .5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .p-md-6 {
    padding: 3.5rem !important;
  }

  .p-md-7 {
    padding: 4rem !important;
  }

  .p-md-8 {
    padding: 4.5rem !important;
  }

  .p-md-9 {
    padding: 5rem !important;
  }

  .p-md-10 {
    padding: 5.5rem !important;
  }

  .p-md-11 {
    padding: 6rem !important;
  }

  .p-md-12 {
    padding: 6.5rem !important;
  }

  .p-md-13 {
    padding: 7rem !important;
  }

  .p-md-14 {
    padding: 7.5rem !important;
  }

  .p-md-15 {
    padding: 8rem !important;
  }

  .p-md-16 {
    padding: 8.5rem !important;
  }

  .p-md-17 {
    padding: 9rem !important;
  }

  .p-md-18 {
    padding: 9.5rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: .25rem !important;
    padding-left: .25rem !important;
  }

  .px-md-2 {
    padding-right: .5rem !important;
    padding-left: .5rem !important;
  }

  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-md-6 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-md-7 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-md-8 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-md-9 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .px-md-10 {
    padding-right: 5.5rem !important;
    padding-left: 5.5rem !important;
  }

  .px-md-11 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }

  .px-md-12 {
    padding-right: 6.5rem !important;
    padding-left: 6.5rem !important;
  }

  .px-md-13 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }

  .px-md-14 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }

  .px-md-15 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }

  .px-md-16 {
    padding-right: 8.5rem !important;
    padding-left: 8.5rem !important;
  }

  .px-md-17 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }

  .px-md-18 {
    padding-right: 9.5rem !important;
    padding-left: 9.5rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-md-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-md-6 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-md-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-md-8 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-md-9 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-md-10 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important;
  }

  .py-md-11 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-md-12 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important;
  }

  .py-md-13 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }

  .py-md-14 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }

  .py-md-15 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .py-md-16 {
    padding-top: 8.5rem !important;
    padding-bottom: 8.5rem !important;
  }

  .py-md-17 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .py-md-18 {
    padding-top: 9.5rem !important;
    padding-bottom: 9.5rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: .25rem !important;
  }

  .pt-md-2 {
    padding-top: .5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pt-md-6 {
    padding-top: 3.5rem !important;
  }

  .pt-md-7 {
    padding-top: 4rem !important;
  }

  .pt-md-8 {
    padding-top: 4.5rem !important;
  }

  .pt-md-9 {
    padding-top: 5rem !important;
  }

  .pt-md-10 {
    padding-top: 5.5rem !important;
  }

  .pt-md-11 {
    padding-top: 6rem !important;
  }

  .pt-md-12 {
    padding-top: 6.5rem !important;
  }

  .pt-md-13 {
    padding-top: 7rem !important;
  }

  .pt-md-14 {
    padding-top: 7.5rem !important;
  }

  .pt-md-15 {
    padding-top: 8rem !important;
  }

  .pt-md-16 {
    padding-top: 8.5rem !important;
  }

  .pt-md-17 {
    padding-top: 9rem !important;
  }

  .pt-md-18 {
    padding-top: 9.5rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: .25rem !important;
  }

  .pe-md-2 {
    padding-right: .5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pe-md-6 {
    padding-right: 3.5rem !important;
  }

  .pe-md-7 {
    padding-right: 4rem !important;
  }

  .pe-md-8 {
    padding-right: 4.5rem !important;
  }

  .pe-md-9 {
    padding-right: 5rem !important;
  }

  .pe-md-10 {
    padding-right: 5.5rem !important;
  }

  .pe-md-11 {
    padding-right: 6rem !important;
  }

  .pe-md-12 {
    padding-right: 6.5rem !important;
  }

  .pe-md-13 {
    padding-right: 7rem !important;
  }

  .pe-md-14 {
    padding-right: 7.5rem !important;
  }

  .pe-md-15 {
    padding-right: 8rem !important;
  }

  .pe-md-16 {
    padding-right: 8.5rem !important;
  }

  .pe-md-17 {
    padding-right: 9rem !important;
  }

  .pe-md-18 {
    padding-right: 9.5rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: .25rem !important;
  }

  .pb-md-2 {
    padding-bottom: .5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .pb-md-6 {
    padding-bottom: 3.5rem !important;
  }

  .pb-md-7 {
    padding-bottom: 4rem !important;
  }

  .pb-md-8 {
    padding-bottom: 4.5rem !important;
  }

  .pb-md-9 {
    padding-bottom: 5rem !important;
  }

  .pb-md-10 {
    padding-bottom: 5.5rem !important;
  }

  .pb-md-11 {
    padding-bottom: 6rem !important;
  }

  .pb-md-12 {
    padding-bottom: 6.5rem !important;
  }

  .pb-md-13 {
    padding-bottom: 7rem !important;
  }

  .pb-md-14 {
    padding-bottom: 7.5rem !important;
  }

  .pb-md-15 {
    padding-bottom: 8rem !important;
  }

  .pb-md-16 {
    padding-bottom: 8.5rem !important;
  }

  .pb-md-17 {
    padding-bottom: 9rem !important;
  }

  .pb-md-18 {
    padding-bottom: 9.5rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: .25rem !important;
  }

  .ps-md-2 {
    padding-left: .5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }

  .ps-md-6 {
    padding-left: 3.5rem !important;
  }

  .ps-md-7 {
    padding-left: 4rem !important;
  }

  .ps-md-8 {
    padding-left: 4.5rem !important;
  }

  .ps-md-9 {
    padding-left: 5rem !important;
  }

  .ps-md-10 {
    padding-left: 5.5rem !important;
  }

  .ps-md-11 {
    padding-left: 6rem !important;
  }

  .ps-md-12 {
    padding-left: 6.5rem !important;
  }

  .ps-md-13 {
    padding-left: 7rem !important;
  }

  .ps-md-14 {
    padding-left: 7.5rem !important;
  }

  .ps-md-15 {
    padding-left: 8rem !important;
  }

  .ps-md-16 {
    padding-left: 8.5rem !important;
  }

  .ps-md-17 {
    padding-left: 9rem !important;
  }

  .ps-md-18 {
    padding-left: 9.5rem !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: .25rem !important;
  }

  .gap-md-2 {
    gap: .5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 3rem !important;
  }

  .gap-md-6 {
    gap: 3.5rem !important;
  }

  .gap-md-7 {
    gap: 4rem !important;
  }

  .gap-md-8 {
    gap: 4.5rem !important;
  }

  .gap-md-9 {
    gap: 5rem !important;
  }

  .gap-md-10 {
    gap: 5.5rem !important;
  }

  .gap-md-11 {
    gap: 6rem !important;
  }

  .gap-md-12 {
    gap: 6.5rem !important;
  }

  .gap-md-13 {
    gap: 7rem !important;
  }

  .gap-md-14 {
    gap: 7.5rem !important;
  }

  .gap-md-15 {
    gap: 8rem !important;
  }

  .gap-md-16 {
    gap: 8.5rem !important;
  }

  .gap-md-17 {
    gap: 9rem !important;
  }

  .gap-md-18 {
    gap: 9.5rem !important;
  }

  .row-gap-md-0 {
    row-gap: 0 !important;
  }

  .row-gap-md-1 {
    row-gap: .25rem !important;
  }

  .row-gap-md-2 {
    row-gap: .5rem !important;
  }

  .row-gap-md-3 {
    row-gap: 1rem !important;
  }

  .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-md-5 {
    row-gap: 3rem !important;
  }

  .row-gap-md-6 {
    row-gap: 3.5rem !important;
  }

  .row-gap-md-7 {
    row-gap: 4rem !important;
  }

  .row-gap-md-8 {
    row-gap: 4.5rem !important;
  }

  .row-gap-md-9 {
    row-gap: 5rem !important;
  }

  .row-gap-md-10 {
    row-gap: 5.5rem !important;
  }

  .row-gap-md-11 {
    row-gap: 6rem !important;
  }

  .row-gap-md-12 {
    row-gap: 6.5rem !important;
  }

  .row-gap-md-13 {
    row-gap: 7rem !important;
  }

  .row-gap-md-14 {
    row-gap: 7.5rem !important;
  }

  .row-gap-md-15 {
    row-gap: 8rem !important;
  }

  .row-gap-md-16 {
    row-gap: 8.5rem !important;
  }

  .row-gap-md-17 {
    row-gap: 9rem !important;
  }

  .row-gap-md-18 {
    row-gap: 9.5rem !important;
  }

  .column-gap-md-0 {
    column-gap: 0 !important;
  }

  .column-gap-md-1 {
    column-gap: .25rem !important;
  }

  .column-gap-md-2 {
    column-gap: .5rem !important;
  }

  .column-gap-md-3 {
    column-gap: 1rem !important;
  }

  .column-gap-md-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-md-5 {
    column-gap: 3rem !important;
  }

  .column-gap-md-6 {
    column-gap: 3.5rem !important;
  }

  .column-gap-md-7 {
    column-gap: 4rem !important;
  }

  .column-gap-md-8 {
    column-gap: 4.5rem !important;
  }

  .column-gap-md-9 {
    column-gap: 5rem !important;
  }

  .column-gap-md-10 {
    column-gap: 5.5rem !important;
  }

  .column-gap-md-11 {
    column-gap: 6rem !important;
  }

  .column-gap-md-12 {
    column-gap: 6.5rem !important;
  }

  .column-gap-md-13 {
    column-gap: 7rem !important;
  }

  .column-gap-md-14 {
    column-gap: 7.5rem !important;
  }

  .column-gap-md-15 {
    column-gap: 8rem !important;
  }

  .column-gap-md-16 {
    column-gap: 8.5rem !important;
  }

  .column-gap-md-17 {
    column-gap: 9rem !important;
  }

  .column-gap-md-18 {
    column-gap: 9.5rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .object-fit-lg-contain {
    object-fit: contain !important;
  }

  .object-fit-lg-cover {
    object-fit: cover !important;
  }

  .object-fit-lg-fill {
    object-fit: fill !important;
  }

  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }

  .object-fit-lg-none {
    object-fit: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-inline-grid {
    display: inline-grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: .25rem !important;
  }

  .m-lg-2 {
    margin: .5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-6 {
    margin: 3.5rem !important;
  }

  .m-lg-7 {
    margin: 4rem !important;
  }

  .m-lg-8 {
    margin: 4.5rem !important;
  }

  .m-lg-9 {
    margin: 5rem !important;
  }

  .m-lg-10 {
    margin: 5.5rem !important;
  }

  .m-lg-11 {
    margin: 6rem !important;
  }

  .m-lg-12 {
    margin: 6.5rem !important;
  }

  .m-lg-13 {
    margin: 7rem !important;
  }

  .m-lg-14 {
    margin: 7.5rem !important;
  }

  .m-lg-15 {
    margin: 8rem !important;
  }

  .m-lg-16 {
    margin: 8.5rem !important;
  }

  .m-lg-17 {
    margin: 9rem !important;
  }

  .m-lg-18 {
    margin: 9.5rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: .25rem !important;
    margin-left: .25rem !important;
  }

  .mx-lg-2 {
    margin-right: .5rem !important;
    margin-left: .5rem !important;
  }

  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-6 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-lg-7 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-lg-8 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-lg-9 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-lg-10 {
    margin-right: 5.5rem !important;
    margin-left: 5.5rem !important;
  }

  .mx-lg-11 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }

  .mx-lg-12 {
    margin-right: 6.5rem !important;
    margin-left: 6.5rem !important;
  }

  .mx-lg-13 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }

  .mx-lg-14 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }

  .mx-lg-15 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }

  .mx-lg-16 {
    margin-right: 8.5rem !important;
    margin-left: 8.5rem !important;
  }

  .mx-lg-17 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }

  .mx-lg-18 {
    margin-right: 9.5rem !important;
    margin-left: 9.5rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-lg-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-6 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-lg-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-lg-8 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-lg-9 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-lg-10 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important;
  }

  .my-lg-11 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-lg-12 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important;
  }

  .my-lg-13 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }

  .my-lg-14 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }

  .my-lg-15 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .my-lg-16 {
    margin-top: 8.5rem !important;
    margin-bottom: 8.5rem !important;
  }

  .my-lg-17 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .my-lg-18 {
    margin-top: 9.5rem !important;
    margin-bottom: 9.5rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: .25rem !important;
  }

  .mt-lg-2 {
    margin-top: .5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-6 {
    margin-top: 3.5rem !important;
  }

  .mt-lg-7 {
    margin-top: 4rem !important;
  }

  .mt-lg-8 {
    margin-top: 4.5rem !important;
  }

  .mt-lg-9 {
    margin-top: 5rem !important;
  }

  .mt-lg-10 {
    margin-top: 5.5rem !important;
  }

  .mt-lg-11 {
    margin-top: 6rem !important;
  }

  .mt-lg-12 {
    margin-top: 6.5rem !important;
  }

  .mt-lg-13 {
    margin-top: 7rem !important;
  }

  .mt-lg-14 {
    margin-top: 7.5rem !important;
  }

  .mt-lg-15 {
    margin-top: 8rem !important;
  }

  .mt-lg-16 {
    margin-top: 8.5rem !important;
  }

  .mt-lg-17 {
    margin-top: 9rem !important;
  }

  .mt-lg-18 {
    margin-top: 9.5rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: .25rem !important;
  }

  .me-lg-2 {
    margin-right: .5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-6 {
    margin-right: 3.5rem !important;
  }

  .me-lg-7 {
    margin-right: 4rem !important;
  }

  .me-lg-8 {
    margin-right: 4.5rem !important;
  }

  .me-lg-9 {
    margin-right: 5rem !important;
  }

  .me-lg-10 {
    margin-right: 5.5rem !important;
  }

  .me-lg-11 {
    margin-right: 6rem !important;
  }

  .me-lg-12 {
    margin-right: 6.5rem !important;
  }

  .me-lg-13 {
    margin-right: 7rem !important;
  }

  .me-lg-14 {
    margin-right: 7.5rem !important;
  }

  .me-lg-15 {
    margin-right: 8rem !important;
  }

  .me-lg-16 {
    margin-right: 8.5rem !important;
  }

  .me-lg-17 {
    margin-right: 9rem !important;
  }

  .me-lg-18 {
    margin-right: 9.5rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: .25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: .5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-6 {
    margin-bottom: 3.5rem !important;
  }

  .mb-lg-7 {
    margin-bottom: 4rem !important;
  }

  .mb-lg-8 {
    margin-bottom: 4.5rem !important;
  }

  .mb-lg-9 {
    margin-bottom: 5rem !important;
  }

  .mb-lg-10 {
    margin-bottom: 5.5rem !important;
  }

  .mb-lg-11 {
    margin-bottom: 6rem !important;
  }

  .mb-lg-12 {
    margin-bottom: 6.5rem !important;
  }

  .mb-lg-13 {
    margin-bottom: 7rem !important;
  }

  .mb-lg-14 {
    margin-bottom: 7.5rem !important;
  }

  .mb-lg-15 {
    margin-bottom: 8rem !important;
  }

  .mb-lg-16 {
    margin-bottom: 8.5rem !important;
  }

  .mb-lg-17 {
    margin-bottom: 9rem !important;
  }

  .mb-lg-18 {
    margin-bottom: 9.5rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: .25rem !important;
  }

  .ms-lg-2 {
    margin-left: .5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-6 {
    margin-left: 3.5rem !important;
  }

  .ms-lg-7 {
    margin-left: 4rem !important;
  }

  .ms-lg-8 {
    margin-left: 4.5rem !important;
  }

  .ms-lg-9 {
    margin-left: 5rem !important;
  }

  .ms-lg-10 {
    margin-left: 5.5rem !important;
  }

  .ms-lg-11 {
    margin-left: 6rem !important;
  }

  .ms-lg-12 {
    margin-left: 6.5rem !important;
  }

  .ms-lg-13 {
    margin-left: 7rem !important;
  }

  .ms-lg-14 {
    margin-left: 7.5rem !important;
  }

  .ms-lg-15 {
    margin-left: 8rem !important;
  }

  .ms-lg-16 {
    margin-left: 8.5rem !important;
  }

  .ms-lg-17 {
    margin-left: 9rem !important;
  }

  .ms-lg-18 {
    margin-left: 9.5rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-lg-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-lg-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-lg-n3 {
    margin-top: -1rem !important;
  }

  .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-lg-n5 {
    margin-top: -3rem !important;
  }

  .me-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .me-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .me-lg-n3 {
    margin-right: -1rem !important;
  }

  .me-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .me-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-lg-n3 {
    margin-left: -1rem !important;
  }

  .ms-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-lg-n5 {
    margin-left: -3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: .25rem !important;
  }

  .p-lg-2 {
    padding: .5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .p-lg-6 {
    padding: 3.5rem !important;
  }

  .p-lg-7 {
    padding: 4rem !important;
  }

  .p-lg-8 {
    padding: 4.5rem !important;
  }

  .p-lg-9 {
    padding: 5rem !important;
  }

  .p-lg-10 {
    padding: 5.5rem !important;
  }

  .p-lg-11 {
    padding: 6rem !important;
  }

  .p-lg-12 {
    padding: 6.5rem !important;
  }

  .p-lg-13 {
    padding: 7rem !important;
  }

  .p-lg-14 {
    padding: 7.5rem !important;
  }

  .p-lg-15 {
    padding: 8rem !important;
  }

  .p-lg-16 {
    padding: 8.5rem !important;
  }

  .p-lg-17 {
    padding: 9rem !important;
  }

  .p-lg-18 {
    padding: 9.5rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: .25rem !important;
    padding-left: .25rem !important;
  }

  .px-lg-2 {
    padding-right: .5rem !important;
    padding-left: .5rem !important;
  }

  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-lg-6 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-lg-7 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-lg-8 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-lg-9 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .px-lg-10 {
    padding-right: 5.5rem !important;
    padding-left: 5.5rem !important;
  }

  .px-lg-11 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }

  .px-lg-12 {
    padding-right: 6.5rem !important;
    padding-left: 6.5rem !important;
  }

  .px-lg-13 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }

  .px-lg-14 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }

  .px-lg-15 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }

  .px-lg-16 {
    padding-right: 8.5rem !important;
    padding-left: 8.5rem !important;
  }

  .px-lg-17 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }

  .px-lg-18 {
    padding-right: 9.5rem !important;
    padding-left: 9.5rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-lg-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-lg-6 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-lg-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-lg-8 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-lg-9 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-lg-10 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important;
  }

  .py-lg-11 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-lg-12 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important;
  }

  .py-lg-13 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }

  .py-lg-14 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }

  .py-lg-15 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .py-lg-16 {
    padding-top: 8.5rem !important;
    padding-bottom: 8.5rem !important;
  }

  .py-lg-17 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .py-lg-18 {
    padding-top: 9.5rem !important;
    padding-bottom: 9.5rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: .25rem !important;
  }

  .pt-lg-2 {
    padding-top: .5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pt-lg-6 {
    padding-top: 3.5rem !important;
  }

  .pt-lg-7 {
    padding-top: 4rem !important;
  }

  .pt-lg-8 {
    padding-top: 4.5rem !important;
  }

  .pt-lg-9 {
    padding-top: 5rem !important;
  }

  .pt-lg-10 {
    padding-top: 5.5rem !important;
  }

  .pt-lg-11 {
    padding-top: 6rem !important;
  }

  .pt-lg-12 {
    padding-top: 6.5rem !important;
  }

  .pt-lg-13 {
    padding-top: 7rem !important;
  }

  .pt-lg-14 {
    padding-top: 7.5rem !important;
  }

  .pt-lg-15 {
    padding-top: 8rem !important;
  }

  .pt-lg-16 {
    padding-top: 8.5rem !important;
  }

  .pt-lg-17 {
    padding-top: 9rem !important;
  }

  .pt-lg-18 {
    padding-top: 9.5rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: .25rem !important;
  }

  .pe-lg-2 {
    padding-right: .5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pe-lg-6 {
    padding-right: 3.5rem !important;
  }

  .pe-lg-7 {
    padding-right: 4rem !important;
  }

  .pe-lg-8 {
    padding-right: 4.5rem !important;
  }

  .pe-lg-9 {
    padding-right: 5rem !important;
  }

  .pe-lg-10 {
    padding-right: 5.5rem !important;
  }

  .pe-lg-11 {
    padding-right: 6rem !important;
  }

  .pe-lg-12 {
    padding-right: 6.5rem !important;
  }

  .pe-lg-13 {
    padding-right: 7rem !important;
  }

  .pe-lg-14 {
    padding-right: 7.5rem !important;
  }

  .pe-lg-15 {
    padding-right: 8rem !important;
  }

  .pe-lg-16 {
    padding-right: 8.5rem !important;
  }

  .pe-lg-17 {
    padding-right: 9rem !important;
  }

  .pe-lg-18 {
    padding-right: 9.5rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: .25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: .5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pb-lg-6 {
    padding-bottom: 3.5rem !important;
  }

  .pb-lg-7 {
    padding-bottom: 4rem !important;
  }

  .pb-lg-8 {
    padding-bottom: 4.5rem !important;
  }

  .pb-lg-9 {
    padding-bottom: 5rem !important;
  }

  .pb-lg-10 {
    padding-bottom: 5.5rem !important;
  }

  .pb-lg-11 {
    padding-bottom: 6rem !important;
  }

  .pb-lg-12 {
    padding-bottom: 6.5rem !important;
  }

  .pb-lg-13 {
    padding-bottom: 7rem !important;
  }

  .pb-lg-14 {
    padding-bottom: 7.5rem !important;
  }

  .pb-lg-15 {
    padding-bottom: 8rem !important;
  }

  .pb-lg-16 {
    padding-bottom: 8.5rem !important;
  }

  .pb-lg-17 {
    padding-bottom: 9rem !important;
  }

  .pb-lg-18 {
    padding-bottom: 9.5rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: .25rem !important;
  }

  .ps-lg-2 {
    padding-left: .5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }

  .ps-lg-6 {
    padding-left: 3.5rem !important;
  }

  .ps-lg-7 {
    padding-left: 4rem !important;
  }

  .ps-lg-8 {
    padding-left: 4.5rem !important;
  }

  .ps-lg-9 {
    padding-left: 5rem !important;
  }

  .ps-lg-10 {
    padding-left: 5.5rem !important;
  }

  .ps-lg-11 {
    padding-left: 6rem !important;
  }

  .ps-lg-12 {
    padding-left: 6.5rem !important;
  }

  .ps-lg-13 {
    padding-left: 7rem !important;
  }

  .ps-lg-14 {
    padding-left: 7.5rem !important;
  }

  .ps-lg-15 {
    padding-left: 8rem !important;
  }

  .ps-lg-16 {
    padding-left: 8.5rem !important;
  }

  .ps-lg-17 {
    padding-left: 9rem !important;
  }

  .ps-lg-18 {
    padding-left: 9.5rem !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: .25rem !important;
  }

  .gap-lg-2 {
    gap: .5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 3rem !important;
  }

  .gap-lg-6 {
    gap: 3.5rem !important;
  }

  .gap-lg-7 {
    gap: 4rem !important;
  }

  .gap-lg-8 {
    gap: 4.5rem !important;
  }

  .gap-lg-9 {
    gap: 5rem !important;
  }

  .gap-lg-10 {
    gap: 5.5rem !important;
  }

  .gap-lg-11 {
    gap: 6rem !important;
  }

  .gap-lg-12 {
    gap: 6.5rem !important;
  }

  .gap-lg-13 {
    gap: 7rem !important;
  }

  .gap-lg-14 {
    gap: 7.5rem !important;
  }

  .gap-lg-15 {
    gap: 8rem !important;
  }

  .gap-lg-16 {
    gap: 8.5rem !important;
  }

  .gap-lg-17 {
    gap: 9rem !important;
  }

  .gap-lg-18 {
    gap: 9.5rem !important;
  }

  .row-gap-lg-0 {
    row-gap: 0 !important;
  }

  .row-gap-lg-1 {
    row-gap: .25rem !important;
  }

  .row-gap-lg-2 {
    row-gap: .5rem !important;
  }

  .row-gap-lg-3 {
    row-gap: 1rem !important;
  }

  .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-lg-5 {
    row-gap: 3rem !important;
  }

  .row-gap-lg-6 {
    row-gap: 3.5rem !important;
  }

  .row-gap-lg-7 {
    row-gap: 4rem !important;
  }

  .row-gap-lg-8 {
    row-gap: 4.5rem !important;
  }

  .row-gap-lg-9 {
    row-gap: 5rem !important;
  }

  .row-gap-lg-10 {
    row-gap: 5.5rem !important;
  }

  .row-gap-lg-11 {
    row-gap: 6rem !important;
  }

  .row-gap-lg-12 {
    row-gap: 6.5rem !important;
  }

  .row-gap-lg-13 {
    row-gap: 7rem !important;
  }

  .row-gap-lg-14 {
    row-gap: 7.5rem !important;
  }

  .row-gap-lg-15 {
    row-gap: 8rem !important;
  }

  .row-gap-lg-16 {
    row-gap: 8.5rem !important;
  }

  .row-gap-lg-17 {
    row-gap: 9rem !important;
  }

  .row-gap-lg-18 {
    row-gap: 9.5rem !important;
  }

  .column-gap-lg-0 {
    column-gap: 0 !important;
  }

  .column-gap-lg-1 {
    column-gap: .25rem !important;
  }

  .column-gap-lg-2 {
    column-gap: .5rem !important;
  }

  .column-gap-lg-3 {
    column-gap: 1rem !important;
  }

  .column-gap-lg-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-lg-5 {
    column-gap: 3rem !important;
  }

  .column-gap-lg-6 {
    column-gap: 3.5rem !important;
  }

  .column-gap-lg-7 {
    column-gap: 4rem !important;
  }

  .column-gap-lg-8 {
    column-gap: 4.5rem !important;
  }

  .column-gap-lg-9 {
    column-gap: 5rem !important;
  }

  .column-gap-lg-10 {
    column-gap: 5.5rem !important;
  }

  .column-gap-lg-11 {
    column-gap: 6rem !important;
  }

  .column-gap-lg-12 {
    column-gap: 6.5rem !important;
  }

  .column-gap-lg-13 {
    column-gap: 7rem !important;
  }

  .column-gap-lg-14 {
    column-gap: 7.5rem !important;
  }

  .column-gap-lg-15 {
    column-gap: 8rem !important;
  }

  .column-gap-lg-16 {
    column-gap: 8.5rem !important;
  }

  .column-gap-lg-17 {
    column-gap: 9rem !important;
  }

  .column-gap-lg-18 {
    column-gap: 9.5rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .object-fit-xl-contain {
    object-fit: contain !important;
  }

  .object-fit-xl-cover {
    object-fit: cover !important;
  }

  .object-fit-xl-fill {
    object-fit: fill !important;
  }

  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }

  .object-fit-xl-none {
    object-fit: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-inline-grid {
    display: inline-grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: .25rem !important;
  }

  .m-xl-2 {
    margin: .5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-6 {
    margin: 3.5rem !important;
  }

  .m-xl-7 {
    margin: 4rem !important;
  }

  .m-xl-8 {
    margin: 4.5rem !important;
  }

  .m-xl-9 {
    margin: 5rem !important;
  }

  .m-xl-10 {
    margin: 5.5rem !important;
  }

  .m-xl-11 {
    margin: 6rem !important;
  }

  .m-xl-12 {
    margin: 6.5rem !important;
  }

  .m-xl-13 {
    margin: 7rem !important;
  }

  .m-xl-14 {
    margin: 7.5rem !important;
  }

  .m-xl-15 {
    margin: 8rem !important;
  }

  .m-xl-16 {
    margin: 8.5rem !important;
  }

  .m-xl-17 {
    margin: 9rem !important;
  }

  .m-xl-18 {
    margin: 9.5rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: .25rem !important;
    margin-left: .25rem !important;
  }

  .mx-xl-2 {
    margin-right: .5rem !important;
    margin-left: .5rem !important;
  }

  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-6 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-xl-7 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-xl-8 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-xl-9 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-xl-10 {
    margin-right: 5.5rem !important;
    margin-left: 5.5rem !important;
  }

  .mx-xl-11 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }

  .mx-xl-12 {
    margin-right: 6.5rem !important;
    margin-left: 6.5rem !important;
  }

  .mx-xl-13 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }

  .mx-xl-14 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }

  .mx-xl-15 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }

  .mx-xl-16 {
    margin-right: 8.5rem !important;
    margin-left: 8.5rem !important;
  }

  .mx-xl-17 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }

  .mx-xl-18 {
    margin-right: 9.5rem !important;
    margin-left: 9.5rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-xl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-6 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-xl-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-xl-8 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-xl-9 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-xl-10 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important;
  }

  .my-xl-11 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-xl-12 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important;
  }

  .my-xl-13 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }

  .my-xl-14 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }

  .my-xl-15 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .my-xl-16 {
    margin-top: 8.5rem !important;
    margin-bottom: 8.5rem !important;
  }

  .my-xl-17 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .my-xl-18 {
    margin-top: 9.5rem !important;
    margin-bottom: 9.5rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: .25rem !important;
  }

  .mt-xl-2 {
    margin-top: .5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-6 {
    margin-top: 3.5rem !important;
  }

  .mt-xl-7 {
    margin-top: 4rem !important;
  }

  .mt-xl-8 {
    margin-top: 4.5rem !important;
  }

  .mt-xl-9 {
    margin-top: 5rem !important;
  }

  .mt-xl-10 {
    margin-top: 5.5rem !important;
  }

  .mt-xl-11 {
    margin-top: 6rem !important;
  }

  .mt-xl-12 {
    margin-top: 6.5rem !important;
  }

  .mt-xl-13 {
    margin-top: 7rem !important;
  }

  .mt-xl-14 {
    margin-top: 7.5rem !important;
  }

  .mt-xl-15 {
    margin-top: 8rem !important;
  }

  .mt-xl-16 {
    margin-top: 8.5rem !important;
  }

  .mt-xl-17 {
    margin-top: 9rem !important;
  }

  .mt-xl-18 {
    margin-top: 9.5rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: .25rem !important;
  }

  .me-xl-2 {
    margin-right: .5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-6 {
    margin-right: 3.5rem !important;
  }

  .me-xl-7 {
    margin-right: 4rem !important;
  }

  .me-xl-8 {
    margin-right: 4.5rem !important;
  }

  .me-xl-9 {
    margin-right: 5rem !important;
  }

  .me-xl-10 {
    margin-right: 5.5rem !important;
  }

  .me-xl-11 {
    margin-right: 6rem !important;
  }

  .me-xl-12 {
    margin-right: 6.5rem !important;
  }

  .me-xl-13 {
    margin-right: 7rem !important;
  }

  .me-xl-14 {
    margin-right: 7.5rem !important;
  }

  .me-xl-15 {
    margin-right: 8rem !important;
  }

  .me-xl-16 {
    margin-right: 8.5rem !important;
  }

  .me-xl-17 {
    margin-right: 9rem !important;
  }

  .me-xl-18 {
    margin-right: 9.5rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: .25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: .5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-6 {
    margin-bottom: 3.5rem !important;
  }

  .mb-xl-7 {
    margin-bottom: 4rem !important;
  }

  .mb-xl-8 {
    margin-bottom: 4.5rem !important;
  }

  .mb-xl-9 {
    margin-bottom: 5rem !important;
  }

  .mb-xl-10 {
    margin-bottom: 5.5rem !important;
  }

  .mb-xl-11 {
    margin-bottom: 6rem !important;
  }

  .mb-xl-12 {
    margin-bottom: 6.5rem !important;
  }

  .mb-xl-13 {
    margin-bottom: 7rem !important;
  }

  .mb-xl-14 {
    margin-bottom: 7.5rem !important;
  }

  .mb-xl-15 {
    margin-bottom: 8rem !important;
  }

  .mb-xl-16 {
    margin-bottom: 8.5rem !important;
  }

  .mb-xl-17 {
    margin-bottom: 9rem !important;
  }

  .mb-xl-18 {
    margin-bottom: 9.5rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: .25rem !important;
  }

  .ms-xl-2 {
    margin-left: .5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-6 {
    margin-left: 3.5rem !important;
  }

  .ms-xl-7 {
    margin-left: 4rem !important;
  }

  .ms-xl-8 {
    margin-left: 4.5rem !important;
  }

  .ms-xl-9 {
    margin-left: 5rem !important;
  }

  .ms-xl-10 {
    margin-left: 5.5rem !important;
  }

  .ms-xl-11 {
    margin-left: 6rem !important;
  }

  .ms-xl-12 {
    margin-left: 6.5rem !important;
  }

  .ms-xl-13 {
    margin-left: 7rem !important;
  }

  .ms-xl-14 {
    margin-left: 7.5rem !important;
  }

  .ms-xl-15 {
    margin-left: 8rem !important;
  }

  .ms-xl-16 {
    margin-left: 8.5rem !important;
  }

  .ms-xl-17 {
    margin-left: 9rem !important;
  }

  .ms-xl-18 {
    margin-left: 9.5rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-xl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-xl-n3 {
    margin-top: -1rem !important;
  }

  .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-xl-n5 {
    margin-top: -3rem !important;
  }

  .me-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .me-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .me-xl-n3 {
    margin-right: -1rem !important;
  }

  .me-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .me-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-xl-n3 {
    margin-left: -1rem !important;
  }

  .ms-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-xl-n5 {
    margin-left: -3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: .25rem !important;
  }

  .p-xl-2 {
    padding: .5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .p-xl-6 {
    padding: 3.5rem !important;
  }

  .p-xl-7 {
    padding: 4rem !important;
  }

  .p-xl-8 {
    padding: 4.5rem !important;
  }

  .p-xl-9 {
    padding: 5rem !important;
  }

  .p-xl-10 {
    padding: 5.5rem !important;
  }

  .p-xl-11 {
    padding: 6rem !important;
  }

  .p-xl-12 {
    padding: 6.5rem !important;
  }

  .p-xl-13 {
    padding: 7rem !important;
  }

  .p-xl-14 {
    padding: 7.5rem !important;
  }

  .p-xl-15 {
    padding: 8rem !important;
  }

  .p-xl-16 {
    padding: 8.5rem !important;
  }

  .p-xl-17 {
    padding: 9rem !important;
  }

  .p-xl-18 {
    padding: 9.5rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: .25rem !important;
    padding-left: .25rem !important;
  }

  .px-xl-2 {
    padding-right: .5rem !important;
    padding-left: .5rem !important;
  }

  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-xl-6 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-xl-7 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-xl-8 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-xl-9 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .px-xl-10 {
    padding-right: 5.5rem !important;
    padding-left: 5.5rem !important;
  }

  .px-xl-11 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }

  .px-xl-12 {
    padding-right: 6.5rem !important;
    padding-left: 6.5rem !important;
  }

  .px-xl-13 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }

  .px-xl-14 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }

  .px-xl-15 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }

  .px-xl-16 {
    padding-right: 8.5rem !important;
    padding-left: 8.5rem !important;
  }

  .px-xl-17 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }

  .px-xl-18 {
    padding-right: 9.5rem !important;
    padding-left: 9.5rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-xl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xl-6 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-xl-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-xl-8 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-xl-9 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-xl-10 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important;
  }

  .py-xl-11 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-xl-12 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important;
  }

  .py-xl-13 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }

  .py-xl-14 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }

  .py-xl-15 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .py-xl-16 {
    padding-top: 8.5rem !important;
    padding-bottom: 8.5rem !important;
  }

  .py-xl-17 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .py-xl-18 {
    padding-top: 9.5rem !important;
    padding-bottom: 9.5rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: .25rem !important;
  }

  .pt-xl-2 {
    padding-top: .5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pt-xl-6 {
    padding-top: 3.5rem !important;
  }

  .pt-xl-7 {
    padding-top: 4rem !important;
  }

  .pt-xl-8 {
    padding-top: 4.5rem !important;
  }

  .pt-xl-9 {
    padding-top: 5rem !important;
  }

  .pt-xl-10 {
    padding-top: 5.5rem !important;
  }

  .pt-xl-11 {
    padding-top: 6rem !important;
  }

  .pt-xl-12 {
    padding-top: 6.5rem !important;
  }

  .pt-xl-13 {
    padding-top: 7rem !important;
  }

  .pt-xl-14 {
    padding-top: 7.5rem !important;
  }

  .pt-xl-15 {
    padding-top: 8rem !important;
  }

  .pt-xl-16 {
    padding-top: 8.5rem !important;
  }

  .pt-xl-17 {
    padding-top: 9rem !important;
  }

  .pt-xl-18 {
    padding-top: 9.5rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: .25rem !important;
  }

  .pe-xl-2 {
    padding-right: .5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pe-xl-6 {
    padding-right: 3.5rem !important;
  }

  .pe-xl-7 {
    padding-right: 4rem !important;
  }

  .pe-xl-8 {
    padding-right: 4.5rem !important;
  }

  .pe-xl-9 {
    padding-right: 5rem !important;
  }

  .pe-xl-10 {
    padding-right: 5.5rem !important;
  }

  .pe-xl-11 {
    padding-right: 6rem !important;
  }

  .pe-xl-12 {
    padding-right: 6.5rem !important;
  }

  .pe-xl-13 {
    padding-right: 7rem !important;
  }

  .pe-xl-14 {
    padding-right: 7.5rem !important;
  }

  .pe-xl-15 {
    padding-right: 8rem !important;
  }

  .pe-xl-16 {
    padding-right: 8.5rem !important;
  }

  .pe-xl-17 {
    padding-right: 9rem !important;
  }

  .pe-xl-18 {
    padding-right: 9.5rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: .25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: .5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pb-xl-6 {
    padding-bottom: 3.5rem !important;
  }

  .pb-xl-7 {
    padding-bottom: 4rem !important;
  }

  .pb-xl-8 {
    padding-bottom: 4.5rem !important;
  }

  .pb-xl-9 {
    padding-bottom: 5rem !important;
  }

  .pb-xl-10 {
    padding-bottom: 5.5rem !important;
  }

  .pb-xl-11 {
    padding-bottom: 6rem !important;
  }

  .pb-xl-12 {
    padding-bottom: 6.5rem !important;
  }

  .pb-xl-13 {
    padding-bottom: 7rem !important;
  }

  .pb-xl-14 {
    padding-bottom: 7.5rem !important;
  }

  .pb-xl-15 {
    padding-bottom: 8rem !important;
  }

  .pb-xl-16 {
    padding-bottom: 8.5rem !important;
  }

  .pb-xl-17 {
    padding-bottom: 9rem !important;
  }

  .pb-xl-18 {
    padding-bottom: 9.5rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: .25rem !important;
  }

  .ps-xl-2 {
    padding-left: .5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }

  .ps-xl-6 {
    padding-left: 3.5rem !important;
  }

  .ps-xl-7 {
    padding-left: 4rem !important;
  }

  .ps-xl-8 {
    padding-left: 4.5rem !important;
  }

  .ps-xl-9 {
    padding-left: 5rem !important;
  }

  .ps-xl-10 {
    padding-left: 5.5rem !important;
  }

  .ps-xl-11 {
    padding-left: 6rem !important;
  }

  .ps-xl-12 {
    padding-left: 6.5rem !important;
  }

  .ps-xl-13 {
    padding-left: 7rem !important;
  }

  .ps-xl-14 {
    padding-left: 7.5rem !important;
  }

  .ps-xl-15 {
    padding-left: 8rem !important;
  }

  .ps-xl-16 {
    padding-left: 8.5rem !important;
  }

  .ps-xl-17 {
    padding-left: 9rem !important;
  }

  .ps-xl-18 {
    padding-left: 9.5rem !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: .25rem !important;
  }

  .gap-xl-2 {
    gap: .5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 3rem !important;
  }

  .gap-xl-6 {
    gap: 3.5rem !important;
  }

  .gap-xl-7 {
    gap: 4rem !important;
  }

  .gap-xl-8 {
    gap: 4.5rem !important;
  }

  .gap-xl-9 {
    gap: 5rem !important;
  }

  .gap-xl-10 {
    gap: 5.5rem !important;
  }

  .gap-xl-11 {
    gap: 6rem !important;
  }

  .gap-xl-12 {
    gap: 6.5rem !important;
  }

  .gap-xl-13 {
    gap: 7rem !important;
  }

  .gap-xl-14 {
    gap: 7.5rem !important;
  }

  .gap-xl-15 {
    gap: 8rem !important;
  }

  .gap-xl-16 {
    gap: 8.5rem !important;
  }

  .gap-xl-17 {
    gap: 9rem !important;
  }

  .gap-xl-18 {
    gap: 9.5rem !important;
  }

  .row-gap-xl-0 {
    row-gap: 0 !important;
  }

  .row-gap-xl-1 {
    row-gap: .25rem !important;
  }

  .row-gap-xl-2 {
    row-gap: .5rem !important;
  }

  .row-gap-xl-3 {
    row-gap: 1rem !important;
  }

  .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-xl-5 {
    row-gap: 3rem !important;
  }

  .row-gap-xl-6 {
    row-gap: 3.5rem !important;
  }

  .row-gap-xl-7 {
    row-gap: 4rem !important;
  }

  .row-gap-xl-8 {
    row-gap: 4.5rem !important;
  }

  .row-gap-xl-9 {
    row-gap: 5rem !important;
  }

  .row-gap-xl-10 {
    row-gap: 5.5rem !important;
  }

  .row-gap-xl-11 {
    row-gap: 6rem !important;
  }

  .row-gap-xl-12 {
    row-gap: 6.5rem !important;
  }

  .row-gap-xl-13 {
    row-gap: 7rem !important;
  }

  .row-gap-xl-14 {
    row-gap: 7.5rem !important;
  }

  .row-gap-xl-15 {
    row-gap: 8rem !important;
  }

  .row-gap-xl-16 {
    row-gap: 8.5rem !important;
  }

  .row-gap-xl-17 {
    row-gap: 9rem !important;
  }

  .row-gap-xl-18 {
    row-gap: 9.5rem !important;
  }

  .column-gap-xl-0 {
    column-gap: 0 !important;
  }

  .column-gap-xl-1 {
    column-gap: .25rem !important;
  }

  .column-gap-xl-2 {
    column-gap: .5rem !important;
  }

  .column-gap-xl-3 {
    column-gap: 1rem !important;
  }

  .column-gap-xl-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-xl-5 {
    column-gap: 3rem !important;
  }

  .column-gap-xl-6 {
    column-gap: 3.5rem !important;
  }

  .column-gap-xl-7 {
    column-gap: 4rem !important;
  }

  .column-gap-xl-8 {
    column-gap: 4.5rem !important;
  }

  .column-gap-xl-9 {
    column-gap: 5rem !important;
  }

  .column-gap-xl-10 {
    column-gap: 5.5rem !important;
  }

  .column-gap-xl-11 {
    column-gap: 6rem !important;
  }

  .column-gap-xl-12 {
    column-gap: 6.5rem !important;
  }

  .column-gap-xl-13 {
    column-gap: 7rem !important;
  }

  .column-gap-xl-14 {
    column-gap: 7.5rem !important;
  }

  .column-gap-xl-15 {
    column-gap: 8rem !important;
  }

  .column-gap-xl-16 {
    column-gap: 8.5rem !important;
  }

  .column-gap-xl-17 {
    column-gap: 9rem !important;
  }

  .column-gap-xl-18 {
    column-gap: 9.5rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .object-fit-xxl-contain {
    object-fit: contain !important;
  }

  .object-fit-xxl-cover {
    object-fit: cover !important;
  }

  .object-fit-xxl-fill {
    object-fit: fill !important;
  }

  .object-fit-xxl-scale {
    object-fit: scale-down !important;
  }

  .object-fit-xxl-none {
    object-fit: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-inline-grid {
    display: inline-grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: .25rem !important;
  }

  .m-xxl-2 {
    margin: .5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .m-xxl-6 {
    margin: 3.5rem !important;
  }

  .m-xxl-7 {
    margin: 4rem !important;
  }

  .m-xxl-8 {
    margin: 4.5rem !important;
  }

  .m-xxl-9 {
    margin: 5rem !important;
  }

  .m-xxl-10 {
    margin: 5.5rem !important;
  }

  .m-xxl-11 {
    margin: 6rem !important;
  }

  .m-xxl-12 {
    margin: 6.5rem !important;
  }

  .m-xxl-13 {
    margin: 7rem !important;
  }

  .m-xxl-14 {
    margin: 7.5rem !important;
  }

  .m-xxl-15 {
    margin: 8rem !important;
  }

  .m-xxl-16 {
    margin: 8.5rem !important;
  }

  .m-xxl-17 {
    margin: 9rem !important;
  }

  .m-xxl-18 {
    margin: 9.5rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: .25rem !important;
    margin-left: .25rem !important;
  }

  .mx-xxl-2 {
    margin-right: .5rem !important;
    margin-left: .5rem !important;
  }

  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xxl-6 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-xxl-7 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-xxl-8 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-xxl-9 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-xxl-10 {
    margin-right: 5.5rem !important;
    margin-left: 5.5rem !important;
  }

  .mx-xxl-11 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }

  .mx-xxl-12 {
    margin-right: 6.5rem !important;
    margin-left: 6.5rem !important;
  }

  .mx-xxl-13 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }

  .mx-xxl-14 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }

  .mx-xxl-15 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }

  .mx-xxl-16 {
    margin-right: 8.5rem !important;
    margin-left: 8.5rem !important;
  }

  .mx-xxl-17 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }

  .mx-xxl-18 {
    margin-right: 9.5rem !important;
    margin-left: 9.5rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-xxl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-6 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-xxl-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-xxl-8 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-xxl-9 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-xxl-10 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important;
  }

  .my-xxl-11 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-xxl-12 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important;
  }

  .my-xxl-13 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }

  .my-xxl-14 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }

  .my-xxl-15 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .my-xxl-16 {
    margin-top: 8.5rem !important;
    margin-bottom: 8.5rem !important;
  }

  .my-xxl-17 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .my-xxl-18 {
    margin-top: 9.5rem !important;
    margin-bottom: 9.5rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: .25rem !important;
  }

  .mt-xxl-2 {
    margin-top: .5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .mt-xxl-6 {
    margin-top: 3.5rem !important;
  }

  .mt-xxl-7 {
    margin-top: 4rem !important;
  }

  .mt-xxl-8 {
    margin-top: 4.5rem !important;
  }

  .mt-xxl-9 {
    margin-top: 5rem !important;
  }

  .mt-xxl-10 {
    margin-top: 5.5rem !important;
  }

  .mt-xxl-11 {
    margin-top: 6rem !important;
  }

  .mt-xxl-12 {
    margin-top: 6.5rem !important;
  }

  .mt-xxl-13 {
    margin-top: 7rem !important;
  }

  .mt-xxl-14 {
    margin-top: 7.5rem !important;
  }

  .mt-xxl-15 {
    margin-top: 8rem !important;
  }

  .mt-xxl-16 {
    margin-top: 8.5rem !important;
  }

  .mt-xxl-17 {
    margin-top: 9rem !important;
  }

  .mt-xxl-18 {
    margin-top: 9.5rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: .25rem !important;
  }

  .me-xxl-2 {
    margin-right: .5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 3rem !important;
  }

  .me-xxl-6 {
    margin-right: 3.5rem !important;
  }

  .me-xxl-7 {
    margin-right: 4rem !important;
  }

  .me-xxl-8 {
    margin-right: 4.5rem !important;
  }

  .me-xxl-9 {
    margin-right: 5rem !important;
  }

  .me-xxl-10 {
    margin-right: 5.5rem !important;
  }

  .me-xxl-11 {
    margin-right: 6rem !important;
  }

  .me-xxl-12 {
    margin-right: 6.5rem !important;
  }

  .me-xxl-13 {
    margin-right: 7rem !important;
  }

  .me-xxl-14 {
    margin-right: 7.5rem !important;
  }

  .me-xxl-15 {
    margin-right: 8rem !important;
  }

  .me-xxl-16 {
    margin-right: 8.5rem !important;
  }

  .me-xxl-17 {
    margin-right: 9rem !important;
  }

  .me-xxl-18 {
    margin-right: 9.5rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: .25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: .5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-6 {
    margin-bottom: 3.5rem !important;
  }

  .mb-xxl-7 {
    margin-bottom: 4rem !important;
  }

  .mb-xxl-8 {
    margin-bottom: 4.5rem !important;
  }

  .mb-xxl-9 {
    margin-bottom: 5rem !important;
  }

  .mb-xxl-10 {
    margin-bottom: 5.5rem !important;
  }

  .mb-xxl-11 {
    margin-bottom: 6rem !important;
  }

  .mb-xxl-12 {
    margin-bottom: 6.5rem !important;
  }

  .mb-xxl-13 {
    margin-bottom: 7rem !important;
  }

  .mb-xxl-14 {
    margin-bottom: 7.5rem !important;
  }

  .mb-xxl-15 {
    margin-bottom: 8rem !important;
  }

  .mb-xxl-16 {
    margin-bottom: 8.5rem !important;
  }

  .mb-xxl-17 {
    margin-bottom: 9rem !important;
  }

  .mb-xxl-18 {
    margin-bottom: 9.5rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: .25rem !important;
  }

  .ms-xxl-2 {
    margin-left: .5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .ms-xxl-6 {
    margin-left: 3.5rem !important;
  }

  .ms-xxl-7 {
    margin-left: 4rem !important;
  }

  .ms-xxl-8 {
    margin-left: 4.5rem !important;
  }

  .ms-xxl-9 {
    margin-left: 5rem !important;
  }

  .ms-xxl-10 {
    margin-left: 5.5rem !important;
  }

  .ms-xxl-11 {
    margin-left: 6rem !important;
  }

  .ms-xxl-12 {
    margin-left: 6.5rem !important;
  }

  .ms-xxl-13 {
    margin-left: 7rem !important;
  }

  .ms-xxl-14 {
    margin-left: 7.5rem !important;
  }

  .ms-xxl-15 {
    margin-left: 8rem !important;
  }

  .ms-xxl-16 {
    margin-left: 8.5rem !important;
  }

  .ms-xxl-17 {
    margin-left: 9rem !important;
  }

  .ms-xxl-18 {
    margin-left: 9.5rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .m-xxl-n1 {
    margin: -0.25rem !important;
  }

  .m-xxl-n2 {
    margin: -0.5rem !important;
  }

  .m-xxl-n3 {
    margin: -1rem !important;
  }

  .m-xxl-n4 {
    margin: -1.5rem !important;
  }

  .m-xxl-n5 {
    margin: -3rem !important;
  }

  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-xxl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-xxl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xxl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-xxl-n3 {
    margin-top: -1rem !important;
  }

  .mt-xxl-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-xxl-n5 {
    margin-top: -3rem !important;
  }

  .me-xxl-n1 {
    margin-right: -0.25rem !important;
  }

  .me-xxl-n2 {
    margin-right: -0.5rem !important;
  }

  .me-xxl-n3 {
    margin-right: -1rem !important;
  }

  .me-xxl-n4 {
    margin-right: -1.5rem !important;
  }

  .me-xxl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-xxl-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xxl-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-xxl-n3 {
    margin-left: -1rem !important;
  }

  .ms-xxl-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-xxl-n5 {
    margin-left: -3rem !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: .25rem !important;
  }

  .p-xxl-2 {
    padding: .5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .p-xxl-6 {
    padding: 3.5rem !important;
  }

  .p-xxl-7 {
    padding: 4rem !important;
  }

  .p-xxl-8 {
    padding: 4.5rem !important;
  }

  .p-xxl-9 {
    padding: 5rem !important;
  }

  .p-xxl-10 {
    padding: 5.5rem !important;
  }

  .p-xxl-11 {
    padding: 6rem !important;
  }

  .p-xxl-12 {
    padding: 6.5rem !important;
  }

  .p-xxl-13 {
    padding: 7rem !important;
  }

  .p-xxl-14 {
    padding: 7.5rem !important;
  }

  .p-xxl-15 {
    padding: 8rem !important;
  }

  .p-xxl-16 {
    padding: 8.5rem !important;
  }

  .p-xxl-17 {
    padding: 9rem !important;
  }

  .p-xxl-18 {
    padding: 9.5rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: .25rem !important;
    padding-left: .25rem !important;
  }

  .px-xxl-2 {
    padding-right: .5rem !important;
    padding-left: .5rem !important;
  }

  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-xxl-6 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-xxl-7 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-xxl-8 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-xxl-9 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .px-xxl-10 {
    padding-right: 5.5rem !important;
    padding-left: 5.5rem !important;
  }

  .px-xxl-11 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }

  .px-xxl-12 {
    padding-right: 6.5rem !important;
    padding-left: 6.5rem !important;
  }

  .px-xxl-13 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }

  .px-xxl-14 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }

  .px-xxl-15 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }

  .px-xxl-16 {
    padding-right: 8.5rem !important;
    padding-left: 8.5rem !important;
  }

  .px-xxl-17 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }

  .px-xxl-18 {
    padding-right: 9.5rem !important;
    padding-left: 9.5rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-xxl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xxl-6 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-xxl-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-xxl-8 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-xxl-9 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-xxl-10 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important;
  }

  .py-xxl-11 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-xxl-12 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important;
  }

  .py-xxl-13 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }

  .py-xxl-14 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }

  .py-xxl-15 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .py-xxl-16 {
    padding-top: 8.5rem !important;
    padding-bottom: 8.5rem !important;
  }

  .py-xxl-17 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .py-xxl-18 {
    padding-top: 9.5rem !important;
    padding-bottom: 9.5rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: .25rem !important;
  }

  .pt-xxl-2 {
    padding-top: .5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .pt-xxl-6 {
    padding-top: 3.5rem !important;
  }

  .pt-xxl-7 {
    padding-top: 4rem !important;
  }

  .pt-xxl-8 {
    padding-top: 4.5rem !important;
  }

  .pt-xxl-9 {
    padding-top: 5rem !important;
  }

  .pt-xxl-10 {
    padding-top: 5.5rem !important;
  }

  .pt-xxl-11 {
    padding-top: 6rem !important;
  }

  .pt-xxl-12 {
    padding-top: 6.5rem !important;
  }

  .pt-xxl-13 {
    padding-top: 7rem !important;
  }

  .pt-xxl-14 {
    padding-top: 7.5rem !important;
  }

  .pt-xxl-15 {
    padding-top: 8rem !important;
  }

  .pt-xxl-16 {
    padding-top: 8.5rem !important;
  }

  .pt-xxl-17 {
    padding-top: 9rem !important;
  }

  .pt-xxl-18 {
    padding-top: 9.5rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: .25rem !important;
  }

  .pe-xxl-2 {
    padding-right: .5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .pe-xxl-6 {
    padding-right: 3.5rem !important;
  }

  .pe-xxl-7 {
    padding-right: 4rem !important;
  }

  .pe-xxl-8 {
    padding-right: 4.5rem !important;
  }

  .pe-xxl-9 {
    padding-right: 5rem !important;
  }

  .pe-xxl-10 {
    padding-right: 5.5rem !important;
  }

  .pe-xxl-11 {
    padding-right: 6rem !important;
  }

  .pe-xxl-12 {
    padding-right: 6.5rem !important;
  }

  .pe-xxl-13 {
    padding-right: 7rem !important;
  }

  .pe-xxl-14 {
    padding-right: 7.5rem !important;
  }

  .pe-xxl-15 {
    padding-right: 8rem !important;
  }

  .pe-xxl-16 {
    padding-right: 8.5rem !important;
  }

  .pe-xxl-17 {
    padding-right: 9rem !important;
  }

  .pe-xxl-18 {
    padding-right: 9.5rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: .25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: .5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .pb-xxl-6 {
    padding-bottom: 3.5rem !important;
  }

  .pb-xxl-7 {
    padding-bottom: 4rem !important;
  }

  .pb-xxl-8 {
    padding-bottom: 4.5rem !important;
  }

  .pb-xxl-9 {
    padding-bottom: 5rem !important;
  }

  .pb-xxl-10 {
    padding-bottom: 5.5rem !important;
  }

  .pb-xxl-11 {
    padding-bottom: 6rem !important;
  }

  .pb-xxl-12 {
    padding-bottom: 6.5rem !important;
  }

  .pb-xxl-13 {
    padding-bottom: 7rem !important;
  }

  .pb-xxl-14 {
    padding-bottom: 7.5rem !important;
  }

  .pb-xxl-15 {
    padding-bottom: 8rem !important;
  }

  .pb-xxl-16 {
    padding-bottom: 8.5rem !important;
  }

  .pb-xxl-17 {
    padding-bottom: 9rem !important;
  }

  .pb-xxl-18 {
    padding-bottom: 9.5rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: .25rem !important;
  }

  .ps-xxl-2 {
    padding-left: .5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .ps-xxl-6 {
    padding-left: 3.5rem !important;
  }

  .ps-xxl-7 {
    padding-left: 4rem !important;
  }

  .ps-xxl-8 {
    padding-left: 4.5rem !important;
  }

  .ps-xxl-9 {
    padding-left: 5rem !important;
  }

  .ps-xxl-10 {
    padding-left: 5.5rem !important;
  }

  .ps-xxl-11 {
    padding-left: 6rem !important;
  }

  .ps-xxl-12 {
    padding-left: 6.5rem !important;
  }

  .ps-xxl-13 {
    padding-left: 7rem !important;
  }

  .ps-xxl-14 {
    padding-left: 7.5rem !important;
  }

  .ps-xxl-15 {
    padding-left: 8rem !important;
  }

  .ps-xxl-16 {
    padding-left: 8.5rem !important;
  }

  .ps-xxl-17 {
    padding-left: 9rem !important;
  }

  .ps-xxl-18 {
    padding-left: 9.5rem !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: .25rem !important;
  }

  .gap-xxl-2 {
    gap: .5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    gap: 3rem !important;
  }

  .gap-xxl-6 {
    gap: 3.5rem !important;
  }

  .gap-xxl-7 {
    gap: 4rem !important;
  }

  .gap-xxl-8 {
    gap: 4.5rem !important;
  }

  .gap-xxl-9 {
    gap: 5rem !important;
  }

  .gap-xxl-10 {
    gap: 5.5rem !important;
  }

  .gap-xxl-11 {
    gap: 6rem !important;
  }

  .gap-xxl-12 {
    gap: 6.5rem !important;
  }

  .gap-xxl-13 {
    gap: 7rem !important;
  }

  .gap-xxl-14 {
    gap: 7.5rem !important;
  }

  .gap-xxl-15 {
    gap: 8rem !important;
  }

  .gap-xxl-16 {
    gap: 8.5rem !important;
  }

  .gap-xxl-17 {
    gap: 9rem !important;
  }

  .gap-xxl-18 {
    gap: 9.5rem !important;
  }

  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }

  .row-gap-xxl-1 {
    row-gap: .25rem !important;
  }

  .row-gap-xxl-2 {
    row-gap: .5rem !important;
  }

  .row-gap-xxl-3 {
    row-gap: 1rem !important;
  }

  .row-gap-xxl-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-xxl-5 {
    row-gap: 3rem !important;
  }

  .row-gap-xxl-6 {
    row-gap: 3.5rem !important;
  }

  .row-gap-xxl-7 {
    row-gap: 4rem !important;
  }

  .row-gap-xxl-8 {
    row-gap: 4.5rem !important;
  }

  .row-gap-xxl-9 {
    row-gap: 5rem !important;
  }

  .row-gap-xxl-10 {
    row-gap: 5.5rem !important;
  }

  .row-gap-xxl-11 {
    row-gap: 6rem !important;
  }

  .row-gap-xxl-12 {
    row-gap: 6.5rem !important;
  }

  .row-gap-xxl-13 {
    row-gap: 7rem !important;
  }

  .row-gap-xxl-14 {
    row-gap: 7.5rem !important;
  }

  .row-gap-xxl-15 {
    row-gap: 8rem !important;
  }

  .row-gap-xxl-16 {
    row-gap: 8.5rem !important;
  }

  .row-gap-xxl-17 {
    row-gap: 9rem !important;
  }

  .row-gap-xxl-18 {
    row-gap: 9.5rem !important;
  }

  .column-gap-xxl-0 {
    column-gap: 0 !important;
  }

  .column-gap-xxl-1 {
    column-gap: .25rem !important;
  }

  .column-gap-xxl-2 {
    column-gap: .5rem !important;
  }

  .column-gap-xxl-3 {
    column-gap: 1rem !important;
  }

  .column-gap-xxl-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-xxl-5 {
    column-gap: 3rem !important;
  }

  .column-gap-xxl-6 {
    column-gap: 3.5rem !important;
  }

  .column-gap-xxl-7 {
    column-gap: 4rem !important;
  }

  .column-gap-xxl-8 {
    column-gap: 4.5rem !important;
  }

  .column-gap-xxl-9 {
    column-gap: 5rem !important;
  }

  .column-gap-xxl-10 {
    column-gap: 5.5rem !important;
  }

  .column-gap-xxl-11 {
    column-gap: 6rem !important;
  }

  .column-gap-xxl-12 {
    column-gap: 6.5rem !important;
  }

  .column-gap-xxl-13 {
    column-gap: 7rem !important;
  }

  .column-gap-xxl-14 {
    column-gap: 7.5rem !important;
  }

  .column-gap-xxl-15 {
    column-gap: 8rem !important;
  }

  .column-gap-xxl-16 {
    column-gap: 8.5rem !important;
  }

  .column-gap-xxl-17 {
    column-gap: 9rem !important;
  }

  .column-gap-xxl-18 {
    column-gap: 9.5rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }

  .fs-2 {
    font-size: 2rem !important;
  }

  .fs-3 {
    font-size: 1.75rem !important;
  }

  .fs-4 {
    font-size: 1.5rem !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-inline-grid {
    display: inline-grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}

.p-x2 {
  padding: 2rem !important;
}

.p-xs-x2 {
  padding: 2rem !important;
}

@media (min-width: 576px) {
  .p-sm-x2 {
    padding: 2rem !important;
  }
}

@media (min-width: 768px) {
  .p-md-x2 {
    padding: 2rem !important;
  }
}

@media (min-width: 992px) {
  .p-lg-x2 {
    padding: 2rem !important;
  }
}

@media (min-width: 1200px) {
  .p-xl-x2 {
    padding: 2rem !important;
  }
}

@media (min-width: 1400px) {
  .p-xxl-x2 {
    padding: 2rem !important;
  }
}

.ps-x2 {
  padding-left: 2rem !important;
}

.ps-xs-x2 {
  padding-left: 2rem !important;
}

@media (min-width: 576px) {
  .ps-sm-x2 {
    padding-left: 2rem !important;
  }
}

@media (min-width: 768px) {
  .ps-md-x2 {
    padding-left: 2rem !important;
  }
}

@media (min-width: 992px) {
  .ps-lg-x2 {
    padding-left: 2rem !important;
  }
}

@media (min-width: 1200px) {
  .ps-xl-x2 {
    padding-left: 2rem !important;
  }
}

@media (min-width: 1400px) {
  .ps-xxl-x2 {
    padding-left: 2rem !important;
  }
}

.pe-x2 {
  padding-right: 2rem !important;
}

.pe-xs-x2 {
  padding-right: 2rem !important;
}

@media (min-width: 576px) {
  .pe-sm-x2 {
    padding-right: 2rem !important;
  }
}

@media (min-width: 768px) {
  .pe-md-x2 {
    padding-right: 2rem !important;
  }
}

@media (min-width: 992px) {
  .pe-lg-x2 {
    padding-right: 2rem !important;
  }
}

@media (min-width: 1200px) {
  .pe-xl-x2 {
    padding-right: 2rem !important;
  }
}

@media (min-width: 1400px) {
  .pe-xxl-x2 {
    padding-right: 2rem !important;
  }
}

.pt-x2 {
  padding-top: 2rem !important;
}

.pt-xs-x2 {
  padding-top: 2rem !important;
}

@media (min-width: 576px) {
  .pt-sm-x2 {
    padding-top: 2rem !important;
  }
}

@media (min-width: 768px) {
  .pt-md-x2 {
    padding-top: 2rem !important;
  }
}

@media (min-width: 992px) {
  .pt-lg-x2 {
    padding-top: 2rem !important;
  }
}

@media (min-width: 1200px) {
  .pt-xl-x2 {
    padding-top: 2rem !important;
  }
}

@media (min-width: 1400px) {
  .pt-xxl-x2 {
    padding-top: 2rem !important;
  }
}

.pb-x2 {
  padding-bottom: 2rem !important;
}

.pb-xs-x2 {
  padding-bottom: 2rem !important;
}

@media (min-width: 576px) {
  .pb-sm-x2 {
    padding-bottom: 2rem !important;
  }
}

@media (min-width: 768px) {
  .pb-md-x2 {
    padding-bottom: 2rem !important;
  }
}

@media (min-width: 992px) {
  .pb-lg-x2 {
    padding-bottom: 2rem !important;
  }
}

@media (min-width: 1200px) {
  .pb-xl-x2 {
    padding-bottom: 2rem !important;
  }
}

@media (min-width: 1400px) {
  .pb-xxl-x2 {
    padding-bottom: 2rem !important;
  }
}

.m-x2 {
  margin: 2rem !important;
}

.m-xs-x2 {
  margin: 2rem !important;
}

@media (min-width: 576px) {
  .m-sm-x2 {
    margin: 2rem !important;
  }
}

@media (min-width: 768px) {
  .m-md-x2 {
    margin: 2rem !important;
  }
}

@media (min-width: 992px) {
  .m-lg-x2 {
    margin: 2rem !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-x2 {
    margin: 2rem !important;
  }
}

@media (min-width: 1400px) {
  .m-xxl-x2 {
    margin: 2rem !important;
  }
}

.ms-x2 {
  margin-left: 2rem !important;
}

.ms-xs-x2 {
  margin-left: 2rem !important;
}

@media (min-width: 576px) {
  .ms-sm-x2 {
    margin-left: 2rem !important;
  }
}

@media (min-width: 768px) {
  .ms-md-x2 {
    margin-left: 2rem !important;
  }
}

@media (min-width: 992px) {
  .ms-lg-x2 {
    margin-left: 2rem !important;
  }
}

@media (min-width: 1200px) {
  .ms-xl-x2 {
    margin-left: 2rem !important;
  }
}

@media (min-width: 1400px) {
  .ms-xxl-x2 {
    margin-left: 2rem !important;
  }
}

.me-x2 {
  margin-right: 2rem !important;
}

.me-xs-x2 {
  margin-right: 2rem !important;
}

@media (min-width: 576px) {
  .me-sm-x2 {
    margin-right: 2rem !important;
  }
}

@media (min-width: 768px) {
  .me-md-x2 {
    margin-right: 2rem !important;
  }
}

@media (min-width: 992px) {
  .me-lg-x2 {
    margin-right: 2rem !important;
  }
}

@media (min-width: 1200px) {
  .me-xl-x2 {
    margin-right: 2rem !important;
  }
}

@media (min-width: 1400px) {
  .me-xxl-x2 {
    margin-right: 2rem !important;
  }
}

.mt-x2 {
  margin-top: 2rem !important;
}

.mt-xs-x2 {
  margin-top: 2rem !important;
}

@media (min-width: 576px) {
  .mt-sm-x2 {
    margin-top: 2rem !important;
  }
}

@media (min-width: 768px) {
  .mt-md-x2 {
    margin-top: 2rem !important;
  }
}

@media (min-width: 992px) {
  .mt-lg-x2 {
    margin-top: 2rem !important;
  }
}

@media (min-width: 1200px) {
  .mt-xl-x2 {
    margin-top: 2rem !important;
  }
}

@media (min-width: 1400px) {
  .mt-xxl-x2 {
    margin-top: 2rem !important;
  }
}

.mb-x2 {
  margin-bottom: 2rem !important;
}

.mb-xs-x2 {
  margin-bottom: 2rem !important;
}

@media (min-width: 576px) {
  .mb-sm-x2 {
    margin-bottom: 2rem !important;
  }
}

@media (min-width: 768px) {
  .mb-md-x2 {
    margin-bottom: 2rem !important;
  }
}

@media (min-width: 992px) {
  .mb-lg-x2 {
    margin-bottom: 2rem !important;
  }
}

@media (min-width: 1200px) {
  .mb-xl-x2 {
    margin-bottom: 2rem !important;
  }
}

@media (min-width: 1400px) {
  .mb-xxl-x2 {
    margin-bottom: 2rem !important;
  }
}

.size--0 {
  width: 0;
  height: 0;
}

.size--1 {
  width: .25rem;
  height: .25rem;
}

.size--2 {
  width: .5rem;
  height: .5rem;
}

.size--3 {
  width: 1rem;
  height: 1rem;
}

.size--4 {
  width: 1.5rem;
  height: 1.5rem;
}

.size--5 {
  width: 3rem;
  height: 3rem;
}

.size--6 {
  width: 3.5rem;
  height: 3.5rem;
}

.size--7 {
  width: 4rem;
  height: 4rem;
}

.size--8 {
  width: 4.5rem;
  height: 4.5rem;
}

.size--9 {
  width: 5rem;
  height: 5rem;
}

.size--10 {
  width: 5.5rem;
  height: 5.5rem;
}

.size--11 {
  width: 6rem;
  height: 6rem;
}

.size--12 {
  width: 6.5rem;
  height: 6.5rem;
}

.size--13 {
  width: 7rem;
  height: 7rem;
}

.size--14 {
  width: 7.5rem;
  height: 7.5rem;
}

.size--15 {
  width: 8rem;
  height: 8rem;
}

.size--16 {
  width: 8.5rem;
  height: 8.5rem;
}

.size--17 {
  width: 9rem;
  height: 9rem;
}

.size--18 {
  width: 9.5rem;
  height: 9.5rem;
}

.flex-basis--1 {
  flex-basis: 8.3333333333%;
}

.flex-basis--2 {
  flex-basis: 16.6666666667%;
}

.flex-basis--3 {
  flex-basis: 25%;
}

.flex-basis--4 {
  flex-basis: 33.3333333333%;
}

.flex-basis--5 {
  flex-basis: 41.6666666667%;
}

.flex-basis--6 {
  flex-basis: 50%;
}

.flex-basis--7 {
  flex-basis: 58.3333333333%;
}

.flex-basis--8 {
  flex-basis: 66.6666666667%;
}

.flex-basis--9 {
  flex-basis: 75%;
}

.flex-basis--10 {
  flex-basis: 83.3333333333%;
}

.flex-basis--11 {
  flex-basis: 91.6666666667%;
}

.flex-basis--12 {
  flex-basis: 100%;
}

.flex-basis-xs--1 {
  flex-basis: 8.3333333333%;
}

.flex-basis-xs--2 {
  flex-basis: 16.6666666667%;
}

.flex-basis-xs--3 {
  flex-basis: 25%;
}

.flex-basis-xs--4 {
  flex-basis: 33.3333333333%;
}

.flex-basis-xs--5 {
  flex-basis: 41.6666666667%;
}

.flex-basis-xs--6 {
  flex-basis: 50%;
}

.flex-basis-xs--7 {
  flex-basis: 58.3333333333%;
}

.flex-basis-xs--8 {
  flex-basis: 66.6666666667%;
}

.flex-basis-xs--9 {
  flex-basis: 75%;
}

.flex-basis-xs--10 {
  flex-basis: 83.3333333333%;
}

.flex-basis-xs--11 {
  flex-basis: 91.6666666667%;
}

.flex-basis-xs--12 {
  flex-basis: 100%;
}

@media (min-width: 576px) {
  .flex-basis-sm--1 {
    flex-basis: 8.3333333333%;
  }

  .flex-basis-sm--2 {
    flex-basis: 16.6666666667%;
  }

  .flex-basis-sm--3 {
    flex-basis: 25%;
  }

  .flex-basis-sm--4 {
    flex-basis: 33.3333333333%;
  }

  .flex-basis-sm--5 {
    flex-basis: 41.6666666667%;
  }

  .flex-basis-sm--6 {
    flex-basis: 50%;
  }

  .flex-basis-sm--7 {
    flex-basis: 58.3333333333%;
  }

  .flex-basis-sm--8 {
    flex-basis: 66.6666666667%;
  }

  .flex-basis-sm--9 {
    flex-basis: 75%;
  }

  .flex-basis-sm--10 {
    flex-basis: 83.3333333333%;
  }

  .flex-basis-sm--11 {
    flex-basis: 91.6666666667%;
  }

  .flex-basis-sm--12 {
    flex-basis: 100%;
  }
}

@media (min-width: 768px) {
  .flex-basis-md--1 {
    flex-basis: 8.3333333333%;
  }

  .flex-basis-md--2 {
    flex-basis: 16.6666666667%;
  }

  .flex-basis-md--3 {
    flex-basis: 25%;
  }

  .flex-basis-md--4 {
    flex-basis: 33.3333333333%;
  }

  .flex-basis-md--5 {
    flex-basis: 41.6666666667%;
  }

  .flex-basis-md--6 {
    flex-basis: 50%;
  }

  .flex-basis-md--7 {
    flex-basis: 58.3333333333%;
  }

  .flex-basis-md--8 {
    flex-basis: 66.6666666667%;
  }

  .flex-basis-md--9 {
    flex-basis: 75%;
  }

  .flex-basis-md--10 {
    flex-basis: 83.3333333333%;
  }

  .flex-basis-md--11 {
    flex-basis: 91.6666666667%;
  }

  .flex-basis-md--12 {
    flex-basis: 100%;
  }
}

@media (min-width: 992px) {
  .flex-basis-lg--1 {
    flex-basis: 8.3333333333%;
  }

  .flex-basis-lg--2 {
    flex-basis: 16.6666666667%;
  }

  .flex-basis-lg--3 {
    flex-basis: 25%;
  }

  .flex-basis-lg--4 {
    flex-basis: 33.3333333333%;
  }

  .flex-basis-lg--5 {
    flex-basis: 41.6666666667%;
  }

  .flex-basis-lg--6 {
    flex-basis: 50%;
  }

  .flex-basis-lg--7 {
    flex-basis: 58.3333333333%;
  }

  .flex-basis-lg--8 {
    flex-basis: 66.6666666667%;
  }

  .flex-basis-lg--9 {
    flex-basis: 75%;
  }

  .flex-basis-lg--10 {
    flex-basis: 83.3333333333%;
  }

  .flex-basis-lg--11 {
    flex-basis: 91.6666666667%;
  }

  .flex-basis-lg--12 {
    flex-basis: 100%;
  }
}

@media (min-width: 1200px) {
  .flex-basis-xl--1 {
    flex-basis: 8.3333333333%;
  }

  .flex-basis-xl--2 {
    flex-basis: 16.6666666667%;
  }

  .flex-basis-xl--3 {
    flex-basis: 25%;
  }

  .flex-basis-xl--4 {
    flex-basis: 33.3333333333%;
  }

  .flex-basis-xl--5 {
    flex-basis: 41.6666666667%;
  }

  .flex-basis-xl--6 {
    flex-basis: 50%;
  }

  .flex-basis-xl--7 {
    flex-basis: 58.3333333333%;
  }

  .flex-basis-xl--8 {
    flex-basis: 66.6666666667%;
  }

  .flex-basis-xl--9 {
    flex-basis: 75%;
  }

  .flex-basis-xl--10 {
    flex-basis: 83.3333333333%;
  }

  .flex-basis-xl--11 {
    flex-basis: 91.6666666667%;
  }

  .flex-basis-xl--12 {
    flex-basis: 100%;
  }
}

@media (min-width: 1400px) {
  .flex-basis-xxl--1 {
    flex-basis: 8.3333333333%;
  }

  .flex-basis-xxl--2 {
    flex-basis: 16.6666666667%;
  }

  .flex-basis-xxl--3 {
    flex-basis: 25%;
  }

  .flex-basis-xxl--4 {
    flex-basis: 33.3333333333%;
  }

  .flex-basis-xxl--5 {
    flex-basis: 41.6666666667%;
  }

  .flex-basis-xxl--6 {
    flex-basis: 50%;
  }

  .flex-basis-xxl--7 {
    flex-basis: 58.3333333333%;
  }

  .flex-basis-xxl--8 {
    flex-basis: 66.6666666667%;
  }

  .flex-basis-xxl--9 {
    flex-basis: 75%;
  }

  .flex-basis-xxl--10 {
    flex-basis: 83.3333333333%;
  }

  .flex-basis-xxl--11 {
    flex-basis: 91.6666666667%;
  }

  .flex-basis-xxl--12 {
    flex-basis: 100%;
  }
}

.font-color--primary {
  color: #2178c4;
}

.font-color--secondary {
  color: #676879;
}

.font-color--success {
  color: #1ea382;
}

.font-color--info {
  color: #2178c4;
}

.font-color--warning {
  color: #d8761c;
}

.font-color--danger {
  color: #b7343f;
}

.font-color--light {
  color: #fff;
}

.font-color--dark {
  color: #22263f;
}

.tooltip {
  box-shadow: 0 2px 4px 0 #e7edf3;
  border: 1px solid #e7edf3;
  border-radius: 4px;
}

.btn.btn-primary:disabled {
  color: rgba(34,38,63,.5019607843);
  background-color: rgba(173,181,189,.5019607843);
  border-color: rgba(0,0,0,0);
}

.spinner-msg-pos {
  position: absolute;
  top: calc(50% + 4.5rem + 3rem);
}

.match-btn-width {
  min-width: 134px;
  width: auto;
}

@media (max-width: 768px) {
  .match-btn-width {
    width: 100%;
  }
}

.form-check-input[type=checkbox] {
  cursor: pointer;
}

.upload-files-w {
  padding-top: 40px;
}

.upload-files__validation {
  margin: 32px 0 0px;
  color: #d8761c;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  flex-direction: row;
}

.upload-files__validation-message {
  flex-direction: column;
}

.vault__btn--blackdiamond {
  height: 18px;
  margin-right: 2px;
}

.vault-search__container {
  position: relative;
}

.vault-search__container::after {
  position: absolute;
  display: block;
  left: 16px;
  top: 14px;
  content: "";
  font-family: "bootstrap-icons" !important;
  font-size: 18px;
  color: #2178c4;
}

.vault-search__input {
  position: relative;
  padding: 14px;
  padding-left: 48px;
  line-height: 28px;
  font-size: 18px;
}

.vault__table {
  width: 100%;
}

.vault__table-head {
  border-bottom: 1px solid #e7edf3;
  font-weight: bold;
}

.vault__table-row {
  border-bottom: 1px solid #e7edf3;
}

.vault__table-row:last-child {
  border-bottom-color: rgba(0,0,0,0);
}

.vault__table-row--button:hover {
  background-color: rgba(33,120,196,.1019607843);
}

.vault__table-cell {
  vertical-align: middle;
}

.vault-table__text {
  font-size: 16px;
}

.vault-modal__file-name-input {
  margin-bottom: 8px;
}

.vault-modal__title {
  color: #22263f;
  font-size: 24px;
  font-weight: normal;
  margin-bottom: 16px;
  padding: 0;
}

.vault-modal__body-text {
  padding: 25px 0;
  font-size: 16px;
  line-height: 26px;
  color: #22263f;
}

.vault-modal__content {
  margin-top: 24px;
}

.vault-modal__buttons {
  margin-top: 40px;
  display: flex;
}

.vault__dropdown-button .dropdown-menu {
  box-shadow: 0 2px 4px 0 #e6ecf3;
}

.vault__dropdown-item {
  color: #231f20;
  font-size: 14px;
}

.folder__null--stateBackground {
  background: #fff;
  height: 212px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.folder__null--title {
  font-size: 16px;
  color: #255eba;
  font-weight: 500;
  line-height: 19px;
  padding: 12px 12px 6px;
}

.folder__null--description {
  padding: 6px 12px 12px;
  color: #231f20;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
}

.folder__column--name {
  display: flex;
  flex-direction: row;
  align-items: center;
  display: flex;
  flex: 5;
  color: #231f20;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}

.folder__column--file-count {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  color: #231f20;
  font-size: 14px;
  line-height: 16px;
}

.folder__column-actions {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  color: #e5e5e5;
}

.folder__column-actions--delete {
  color: #d2d2d2;
}

.folder__column-actions--delete:hover {
  color: #8a8888;
  cursor: pointer;
}

.folder__column-actions--lock {
  color: #e5e5e5;
}

.folder__icon {
  margin-right: 8px;
}

.folder__icon--w {
  color: #8a8888;
}

.folder-detail__btn--back {
  display: block;
  color: #22263f;
  font-size: 16px;
}

.trash__card {
  background-color: #f2f5f9;
  margin-top: -55px;
  margin-bottom: 175px;
}

.trash__btn {
  margin-top: 5px;
  flex-grow: 0;
  border: 2px solid #2178c4;
  background-color: #f2f5f9;
}

.trash__btn:hover {
  background: #f2f5f9 !important;
  color: #2178c4 !important;
}

.trash__newicon {
  margin-left: -15px;
  margin-top: -5px;
  position: fixed;
}

.null-tasks {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 32px;
}

.null-tasks__w {
  padding: 56px 0;
  text-align: center;
}

.null-tasks__w h3,
.null-tasks__w .h3 {
  color: #22263f;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: .5em;
  margin-top: 0;
}

.null-tasks__w p {
  font-size: 14px;
}

@media (max-width: 575.98px) {
  .null-tasks__w {
    padding: 44px 0;
  }

  .null-tasks__w h3,
  .null-tasks__w .h3 {
    font-size: 18px;
    font-weight: bold;
  }
}

.tasks-header {
  margin-bottom: 8px;
}

@media (max-width: 576px) {
  .tasks-header {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.tasks-header h2,
.tasks-header .h2 {
  font-family: "Helvetica Neue",Arial,sans-serif;
  font-weight: bold;
  color: rgba(0,0,0,.8509803922);
  font-size: 24px;
  margin-bottom: 0;
  margin-top: 0;
  word-break: break-word;
}

@media (max-width: 575.98px) {
  .tasks-header h2,
  .tasks-header .h2 {
    margin-left: 8px;
  }
}

.tasks-header__button .btn {
  max-width: 140px;
  background: rgba(0,0,0,0);
  color: #2178c4;
  font-weight: bold;
  display: inline-flex;
  font-size: 16px;
  white-space: nowrap;
  padding: 16px 5px;
}

@media (min-width: 576px) {
  .tasks-header__sort {
    flex: 0 0 184px;
  }
}

.tasks-header .dropdown {
  cursor: pointer;
  padding: 0;
  min-width: 170px;
}

@media (max-width: 575.98px) {
  .tasks-header .dropdown {
    width: 100%;
  }
}

.tasks-header .dropdown.show .dropdown-toggle,
.tasks-header .dropdown.show .dropdown-menu {
  border-color: #2178c4;
  color: #2178c4;
}

.tasks-header .dropdown.show .dropdown-toggle .bi {
  transform: rotate(180deg);
}

.tasks-header .bi {
  color: #2177c4;
  font-size: 18px;
}

.tasks-header .show {
  border-color: #aeb6be;
  background-color: #fff;
}

.tasks-header .show:hover {
  background-color: #fff;
  color: #22263f;
  border-color: #aeb6be;
}

.tasks-header__dropdown-toggle {
  min-width: inherit;
  background-color: #fff;
  color: #22263f;
  border-color: #aeb6be;
}

.tasks-header__dropdown-toggle:hover,
.tasks-header__dropdown-toggle:focus,
.tasks-header__dropdown-toggle:active {
  background-color: #fff;
  color: #22263f;
  border-color: #aeb6be;
}

@media (max-width: 575.98px) {
  .tasks-header__dropdown-toggle {
    width: 100%;
  }
}

.tasks-header .dropdown-menu {
  background: #fff;
  min-width: inherit;
  padding: 0;
  border: solid 1px #aeb6be;
}

@media (max-width: 575.98px) {
  .tasks-header .dropdown-menu {
    width: 100%;
  }
}

.tasks-header__dropdown-item {
  color: #22263f;
  padding: 16px;
  font-size: 16px;
}

.tasks-header__dropdown-item.active,
.tasks-header__dropdown-item:active {
  color: #22263f;
  background-color: unset;
}

.tasks-header__dropdown-item:hover {
  background-color: rgba(33,120,196,.1019607843);
  color: #22263f;
}

.task-item {
  margin-bottom: 1px;
  min-height: 120px;
}

.task-item {
  transition: opacity 400ms ease-out;
}

.task-item__checkbox {
  flex: 0 0 64px;
  align-self: center;
  text-align: center;
  padding-right: 12px;
}

@media (max-width: 575.98px) {
  .task-item__checkbox {
    align-self: flex-start;
    padding-right: 0px;
    margin-top: 13px;
  }
}

.task-item__details {
  flex: 1;
}

.task-item__details-subject {
  color: #22263f;
  font-size: 16px;
  margin-bottom: 12px;
  max-width: 500px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow-wrap: break-word;
}

@media (max-width: 575.98px) {
  .task-item__details-subject {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .task-item__details-subject {
    max-width: 320px;
  }
}

@media (max-width: 575.98px) {
  .task-item__details-subject {
    margin-bottom: 4px;
    max-width: 232px;
  }
}

.task-item__details-date {
  color: #676879;
  font-size: 16px;
  margin-bottom: 0;
}

@media (max-width: 575.98px) {
  .task-item__details-date {
    font-size: 14px;
  }
}

@media (max-width: 575.98px) {
  .task-item__details-date {
    margin-bottom: 12px;
  }
}

.task-item__details-assigned-to {
  color: #676879;
  font-size: 14px;
}

.task-item__details-assignees {
  color: #22263f;
  font-size: 16px;
}

@media (max-width: 575.98px) {
  .task-item__details-assignees {
    font-size: 14px;
  }
}

.task-item__fade-animation-enter {
  opacity: 0;
}

.task-item__fade-animation-enter-active {
  opacity: 1;
}

.task-item__fade-animation-exit {
  opacity: 1;
}

.task-item__fade-animation-exit-active {
  opacity: .01;
}

.tasks__checkbox-input .form-check-input {
  border: 2px solid #22263f;
  height: 24px;
  width: 24px;
  cursor: pointer;
}

.tasks__checkbox-input .form-check-input:checked:disabled {
  background: #aeb6be;
  background-image: var(--bs-form-check-bg-image),url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='50' ry='50' stroke='%23E6ECF3FF' stroke-width='4' stroke-dasharray='20%25' stroke-dashoffset='9' stroke-linecap='butt'/%3e%3c/svg%3e");
  opacity: 1;
}

.tasks__checkbox-input .form-check-input:not(:checked) {
  background: #fff;
}

.tasks__checkbox-input .form-check-input:checked {
  border: 2px solid #22263f;
}

.tasks__checkbox-input .form-check-input:disabled {
  content: "";
  background: #fff;
  border: none;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='50' ry='50' stroke='%23AEB6BEFF' stroke-width='4' stroke-dasharray='20%25' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
}

.tasks__checkbox-input .form-check-input:enabled:hover {
  border-width: 2px;
}

.tasks__checkbox-input .form-check-input[type=checkbox] {
  border-radius: 100%;
}

.completed-tasks-card {
  background-color: #f2f5fa;
  border: none;
  padding: 24px;
  margin-bottom: 32px;
}

@media (min-width: 576px) {
  .completed-tasks-card {
    margin-bottom: 64px;
  }
}

@media (min-width: 768px) {
  .completed-tasks-card {
    padding: 32px;
  }
}

.completed-tasks-card__text {
  flex: 1;
  margin-bottom: 24px;
}

.completed-tasks-card__text h3,
.completed-tasks-card__text .h3 {
  color: #22263f;
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 8px;
}

.completed-tasks-card__text p {
  color: #676879;
  margin-bottom: 0;
}

.completed-tasks-card__btn {
  min-width: 185px;
}

.completed-tasks-page__btn--back {
  background: rgba(0,0,0,0);
  border: none;
  color: #22263f;
  font-weight: 400;
  text-align: start;
  margin-bottom: 20px;
  margin-left: 10px;
  padding: 0;
}

.completed-tasks-page__btn--back:hover {
  color: inherit;
  background-color: inherit;
  border-color: inherit;
}

@media (max-width: 576px) {
  .completed-tasks-page__btn--back {
    padding: 10px 20px;
  }
}

.completed-tasks-page__name {
  flex: 5;
}

.completed-tasks-page__date-created {
  flex: 1;
  text-align: right;
}

.completed-tasks-page__actions {
  flex: 1;
  text-align: right;
}

.completed-tasks-page__padding {
  padding: 14px 16px;
}

.tasks-page__body--completed {
  margin-bottom: 24px;
}

.tasks-page__body--not-completed {
  margin-bottom: 32px;
}

@media (min-width: 576px) {
  .tasks-page__body--not-completed {
    margin-bottom: 64px;
  }
}

.marketplace__w {
  color: #000;
}

@media (max-width: 576px) {
  .marketplace__w {
    margin: 0;
  }
}

@media (max-width: 576px) {
  .marketplace__content {
    margin: 12px;
  }
}

.marketplace__tile-header-left {
  display: flex;
  align-items: center;
}

.marketplace__divider {
  width: 1px;
  height: 100%;
  left: 188px;
  top: 24px;
  background: #d2d2d2;
  margin: 0 24px;
}

@media (max-width: 576px) {
  .marketplace__divider {
    display: none;
  }
}

.marketplace__offer-w {
  display: flex;
  width: 100%;
  padding: 24px;
  height: 208px;
  cursor: pointer;
  background: #fff;
}

.marketplace__offer-w:hover {
  box-shadow: 0px 0px 4px #d2d2d2;
}

.marketplace__offer-w:not(:last-child) {
  margin-bottom: 24px;
}

@media (max-width: 576px) {
  .marketplace__offer-w {
    flex-direction: column;
    height: auto;
    border: none;
    box-shadow: 0px 0px 8px #d2d2d2;
    padding: 18px 15px;
  }
}

.marketplace__offer-left {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.marketplace__offer-left img {
  width: 139px;
  height: 44.13px;
}

@media (max-width: 576px) {
  .marketplace__offer-left {
    height: auto;
    flex-direction: row;
  }
}

@media (max-width: 576px) {
  .marketplace__offer-left--desktop {
    display: none;
  }
}

.marketplace__offer-left--mobile {
  display: none;
}

@media (max-width: 576px) {
  .marketplace__offer-left--mobile {
    display: flex;
  }
}

.marketplace__offer-right {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 14px;
  flex: 4;
}

.marketplace__offer-name {
  font-weight: 500;
  font-size: 16px;
  inline-size: 137px;
}

@media (max-width: 576px) {
  .marketplace__offer-name {
    margin: 20px 0;
    inline-size: auto;
  }
}

.marketplace__offer-tag {
  border: 1px solid #0068ae;
  font-size: 10px;
  margin-top: 8px;
  padding: 0 6px;
  border-radius: 4px;
  display: inline-block;
}

@media (max-width: 576px) {
  .marketplace__offer-tag {
    margin: 0;
    text-transform: uppercase;
    height: 18px;
  }
}

.marketplace__offer-details {
  margin-bottom: 8px;
}

.marketplace__offer-details--gray {
  color: #918f8f;
  font-size: 12px;
  margin: 0;
}

.marketplace__offer-details span {
  text-decoration: underline;
  cursor: pointer;
  margin-left: 3px;
}

.marketplace__disclosure {
  position: relative;
  bottom: 0;
  line-height: 1.5;
  color: #231f20;
  font-size: 14px;
}

.marketplace__disclosure:hover {
  color: #40a9ff;
}

.marketplace__title-resources {
  font-family: "Helvetica Neue",Arial,sans-serif;
  font-weight: bold;
  color: rgba(0,0,0,.8509803922);
  font-size: 24px;
  margin-top: 24px;
  margin-bottom: 24px;
}

@media (max-width: 576px) {
  .marketplace__title-resources {
    margin-left: 12px;
  }
}

.networth-hero__container {
  display: flex;
  flex-direction: column;
  height: 443px;
}

@media (max-width: 768px) {
  .networth-hero__container {
    height: 368px;
  }
}

@media (max-width: 576px) {
  .networth-hero__container {
    height: 224px;
  }
}

.networth-hero__worth {
  color: #fff;
  display: flex;
  flex-direction: row;
}

@media (max-width: 576px) {
  .networth-hero__worth {
    margin: -20px 0px 0px 0px;
    flex-direction: column;
  }
}

.networth-hero__worth div:not(:last-child) {
  border-right: solid 1px rgba(255,255,255,.3);
}

@media (max-width: 576px) {
  .networth-hero__worth div:not(:last-child) {
    border-right: none;
  }
}

.networth-hero__worth div:not(:first-child) {
  padding-left: 18px;
}

.networth-hero__worth div {
  flex-grow: 1;
}

@media (max-width: 576px) {
  .networth-hero__worth div {
    padding: 0px 18px 0px 18px;
  }
}

.networth-hero__worth div:first-child {
  flex-grow: 3;
}

.networth-hero__worth-col {
  display: flex;
  flex-direction: column;
  order: 1;
}

@media (max-width: 576px) {
  .networth-hero__worth-col {
    flex-direction: row;
    justify-content: space-between;
  }
}

@media (max-width: 768px) {
  .networth-hero__worth-col {
    max-height: 80px;
  }
}

@media (max-width: 576px) {
  .networth-hero__worth-col--hide {
    display: none;
  }
}

@media (max-width: 576px) {
  .networth-hero__worth-col--ordered {
    order: 99;
  }
}

.networth-hero__worth-title {
  font-size: 46px;
}

@media (max-width: 768px) {
  .networth-hero__worth-title {
    margin-top: -5px;
    font-size: 30px;
  }
}

@media (max-width: 576px) {
  .networth-hero__worth-title {
    display: none;
  }
}

.networth-hero__worth-title-light {
  border-right: solid 1px rgba(255,255,255,.3);
}

.networth-hero__worth__label {
  font-size: 16px;
  color: #fff;
  display: inline-flex;
  align-items: center;
}

@media (max-width: 768px) {
  .networth-hero__worth__label {
    font-size: 18px;
    line-height: 30px;
  }
}

.networth-hero__worth__label-light {
  opacity: .7;
}

@media (max-width: 768px) {
  .networth-hero__worth__label-button {
    margin-top: 5px;
  }
}

@media (max-width: 576px) {
  .networth-hero__worth__label-mobile {
    font-size: 12px;
    line-height: 18px;
  }
}

.networth-hero__worth__label .bi-chevron-right {
  color: #676879;
}

.networth-hero__worth__label:hover .bi-chevron-right {
  color: #40a9ff;
}

.networth-hero__worth__sum {
  display: inline-block;
  font-size: 40px;
  position: relative;
  margin-top: 25px;
}

@media (max-width: 768px) {
  .networth-hero__worth__sum {
    font-size: 30px;
    margin-top: 15px;
  }
}

@media (max-width: 576px) {
  .networth-hero__worth__sum {
    margin-top: 0px;
  }
}

@media (max-width: 576px) {
  .networth-hero__worth__sum-negative {
    font-size: 18px;
    line-height: 14px;
  }
}

.networth-hero__worth__sum-dollar:first-letter {
  margin-right: 4px;
  float: left;
  font-size: 24px;
}

@media (max-width: 576px) {
  .networth-hero__worth__sum-dollar {
    font-size: 18px;
    line-height: 14px;
  }

  .networth-hero__worth__sum-dollar:first-letter {
    margin-top: 0px;
    margin-right: 0px;
    font-size: 18px;
  }
}

.networth-hero__worth__sum-long {
  font-size: 25px;
}

.networth-account-card {
  display: flex;
  justify-content: space-between;
}

.networth-account-card__light {
  background-color: #f2f5fa;
}

@media (max-width: 991.98px) {
  .networth-account-card__light h3,
  .networth-account-card__light .h3 {
    font-size: 18px;
  }
}

.networth-account-card__ins-btn .btn {
  min-width: 134px;
  font-size: 16px;
  padding: 16px 0px;
}

@media (min-width: 992px) {
  .networth-account-card__ins-btn .btn {
    max-width: 172px;
  }
}

.networth-account-card__btn {
  display: flex;
  flex-direction: row-reverse;
  margin-left: auto;
  max-height: 55px;
  flex: 1;
}

.networth-account-card__btn .btn {
  min-width: 134px;
  font-size: 16px;
  padding: 16px 32px;
}

@media (max-width: 576px) {
  .networth-account-card__btn {
    flex: 2;
  }
}

.networth-account-card-sum {
  font-size: 24px;
  line-height: 30px;
}

@media (max-width: 768px) {
  .networth-account-card-sum {
    font-size: 18px;
  }
}

@media (max-width: 576px) {
  .networth-account-card-sum {
    font-size: 18px;
  }
}

.networth-account-card__title {
  flex: 2;
}

@media (max-width: 991.98px) {
  .networth-account-card__title h3,
  .networth-account-card__title .h3 {
    font-size: 18px;
  }
}

@media (max-width: 576px) {
  .networth-account-card__title {
    display: none;
  }
}

.account-category {
  border: none;
}

.account-category__header {
  border-bottom: solid 1px #e6ecf3;
}

.account-category__header-error {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.account-category__header h3,
.account-category__header .h3 {
  font-size: 16px;
  line-height: 26px;
}

@media (max-width: 768px) {
  .account-category__header h3,
  .account-category__header .h3 {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .account-category__header h3,
  .account-category__header .h3 {
    font-size: 12px;
  }
}

.account-category__error-title {
  flex: 1;
  padding: 13px 13px 0 0;
}

@media (max-width: 576px) {
  .account-category__error-title {
    width: 30%;
  }
}

.account-category__error-name {
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: .005em;
  color: #22263f;
  flex-grow: 1;
}

.account-category__error-message {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: .005em;
  color: #676879;
}

.account-category__error-button {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

@media (max-width: 576px) {
  .account-category__error-button {
    margin-left: 10px;
    width: 55%;
  }
}

.account-category__error-show {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@media (max-width: 576px) {
  .account-category__error-show {
    width: 10%;
    margin-left: 10px;
  }
}

.account-category__item:not(last-child) {
  border-bottom: solid 1px #e6ecf3;
}

.account-category-sum {
  font-size: 24px;
  line-height: 30px;
}

@media (max-width: 768px) {
  .account-category-sum {
    font-size: 18px;
  }
}

@media (max-width: 576px) {
  .account-category-sum {
    font-size: 18px;
  }
}

.account-category__table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
}

.account-category__cell-name {
  width: 50%;
  word-break: break-word;
  font-weight: 400;
}

@media (max-width: 768px) {
  .account-category__cell-name {
    width: 30%;
  }
}

@media (max-width: 576px) {
  .account-category__cell-name {
    width: 75%;
  }
}

.account-category__cell-total {
  width: 20%;
  font-weight: 400;
}

@media (max-width: 576px) {
  .account-category__cell-total {
    display: none;
  }
}

.account-category__cell-date {
  width: 15%;
  font-weight: 400;
}

@media (max-width: 576px) {
  .account-category__cell-date {
    display: none;
  }
}

.account-category__cell-error {
  width: 7%;
}

.account-category__cell-error img {
  width: 24px;
  height: 24px;
}

@media (max-width: 768px) {
  .account-category__cell-error {
    width: 8%;
  }
}

@media (max-width: 576px) {
  .account-category__cell-error {
    width: 10%;
  }
}

.account-category__cell-overflow {
  width: 7%;
  padding: 0 32px 0 0;
}

@media (max-width: 768px) {
  .account-category__cell-overflow {
    width: 8%;
  }
}

@media (max-width: 576px) {
  .account-category__cell-overflow {
    width: 15%;
  }
}

.account-category__cell-overflow:focus {
  border: dotted 1px #3b98e2;
}

.account-category__cell-overflow .dropdown-menu.show {
  display: block;
  min-width: unset;
  border: 1px solid #e6ecf3;
  padding-right: 10px;
}

.account-category__cell-overflow .btn {
  padding: 10px 15px;
  color: #2178c4;
  border: dotted 1px rgba(0,0,0,0);
  font-size: 18px;
}

.account-category__cell-overflow .btn:active {
  border: dotted 1px rgba(0,0,0,0);
}

.account-category__cell-overflow .btn.show {
  border: dotted 1px #3b98e2;
}

.account-category__cell-overflow .dropdown-item {
  padding: 0;
  font-size: 14px;
}

.account-category__cell-overflow .dropdown-item:hover,
.account-category__cell-overflow .dropdown-item:focus,
.account-category__cell-overflow .dropdown-item:active {
  background-color: unset;
  outline: unset;
  color: unset;
}

.account-category__btn-lg {
  padding: 14px 32px;
  pointer-events: auto;
}

@media (min-width: 768px) {
  .account-category__btn-lg {
    width: initial;
  }
}

.account-category__accordion {
  --bs-accordion-body-padding-x: 0;
  --bs-accordion-body-padding-y: 0;
}

.account-category__accordion .accordion-button {
  height: 110px;
}

.account-category__accordion .accordion-button:focus {
  outline: none;
  box-shadow: none;
  border-bottom: 1px solid #e6ecf3;
}

.account-category__accordion .accordion-item {
  border: none;
}

.account-table__row-w {
  background: #fff;
  display: flex;
  align-items: center;
  color: #676879;
}

.account-table__row-w:not(:first-child) {
  border-top: 1px solid #e6ecf3;
}

@media (max-width: 576px) {
  .account-table__row-w {
    padding: 5px;
  }
}

.account-table__name {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  color: #22263f;
}

@media (max-width: 576px) {
  .account-table__name {
    padding-top: 5px;
  }
}

.account-table__account-number {
  margin-left: 3px;
  color: #676879;
}

.account-table__category {
  display: inline-block;
  color: #676879;
  font-size: 14px;
  margin-top: 5px;
  border-radius: 4px;
  white-space: nowrap;
}

@media (max-width: 576px) {
  .account-table__category {
    white-space: normal;
  }
}

.account-table__actions {
  flex: 2;
}

.account-table__actions-link {
  color: red;
}

.account-table__overflow {
  display: flex;
  justify-content: flex-end;
}

.account-table__type {
  flex: 2;
  text-align: right;
  font-size: 14px;
}

.account-table__field {
  flex: 2;
  text-align: right;
}

.account-table__field-value {
  font-size: 16px;
  color: #22263f;
  min-height: 26px;
}

.account-table__field-mobile {
  display: none;
}

@media (max-width: 576px) {
  .account-table__field-mobile {
    display: inline-block;
  }
}

.account-table__label {
  color: #676879;
  font-size: 14px;
}

.account-error {
  padding: 0px;
  margin: 0px;
  border: 0px;
}

.account-error__accordion>.accordion-item .accordion-button {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  color: #22263f;
}

.account-error__accordion>.accordion-item>.accordion-header>.accordion-button {
  border-left: 3px solid #d8761c;
  height: 110px;
  border-bottom: 1px solid #e6ecf3;
  border-top-left-radius: 0;
  outline: none;
  box-shadow: none;
  color: #22263f;
}

.account-error__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.account-error__header--name {
  display: flex;
  padding: 13px;
}

.account-error__header--alert {
  display: flex;
  color: #d9771c;
}

.accounts-null__w {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.accounts-null__body-header {
  font-size: 24px;
  color: #22263f;
  margin: 0;
}

.accounts-null__body-description {
  font-size: 16px;
  color: #676879;
  padding: 15px 0 30px 0;
}

.networth-footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 80px;
  margin-top: 32px;
}

@media (max-width: 768px) {
  .networth-footer {
    margin-bottom: 24px;
  }
}

@media (max-width: 576px) {
  .networth-footer {
    padding: 0 16px;
  }
}

.networth-footer__link.Link__text {
  color: #2178c4;
  margin: 8px 0px 8px;
  font-size: 12px;
  font-weight: normal;
}

.networth-hero-graph {
  color: #fff;
}

.networth-hero-graph__title {
  margin-right: auto;
  box-sizing: border-box;
  width: 107px;
  font-size: 16px;
}

@media (max-width: 576px) {
  .networth-hero-graph__title {
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 18px;
    text-align: left;
    order: 1;
    flex: 1;
    color: rgba(230,236,243,.7);
  }
}

.networth-hero-graph__bar-fill {
  height: 12px;
  width: 50%;
  transition: width .6s;
  border-radius: 2px;
  background-color: #068667;
  display: inline-block;
  vertical-align: middle;
}

.networth-hero-graph__bar-fill--liabilities {
  height: 12px;
  width: 0;
  transition: width .6s;
  border-radius: 2px;
  background-color: #eaaa53;
  display: inline-block;
  vertical-align: middle;
}

.networth-hero-graph__bar-fill--liabilities-null {
  height: 12px;
  transition: width .6s;
  border-radius: 2px;
  background-color: #d8761c;
  display: inline-block;
  vertical-align: middle;
  width: 30%;
}

.networth-hero-graph__bar {
  vertical-align: middle;
  line-height: 0;
  border-radius: 2px;
  background-color: rgba(230,236,243,.2);
  height: 12px;
  margin-right: 20px;
  flex: 2;
}

.networth-hero-graph__bar--null {
  opacity: .3;
}

@media (max-width: 576px) {
  .networth-hero-graph__bar {
    flex: none;
    margin-right: 0;
    width: 100%;
  }
}

.networth-hero-graph__amount {
  margin-left: auto;
  font-size: 18px;
  flex-direction: row-reverse;
  text-align: left;
  min-width: 100px;
  width: auto;
  color: #fff;
}

@media (max-width: 576px) {
  .networth-hero-graph__amount {
    margin-top: -4px;
    font-size: 14px;
    text-align: right;
    flex: 1;
    order: 2;
  }
}

.networth-hero-graph__content {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.networth-hero-graph__content>span {
  display: flex;
}

.networth-hero-graph__content:first-child {
  margin-top: 46px;
  margin-bottom: 18px;
}

@media (max-width: 576px) {
  .networth-hero-graph__content {
    font-size: 12px;
    line-height: 18px;
    padding: 0px 18px;
  }

  .networth-hero-graph__content:first-child {
    margin: 0px 0px;
  }
}

.networth-hero-graph__dollar:first-letter {
  text-align: right;
  margin-right: 4px;
  float: left;
  font-size: 12px;
}

@media (max-width: 576px) {
  .networth-hero-graph__dollar:first-letter {
    text-align: inherit;
    font-size: inherit;
  }
}

.networth-interstitial__title {
  display: inline-block;
  max-width: 500px;
}

.networth-interstitial__title h2,
.networth-interstitial__title .h2 {
  font-family: "Helvetica Neue",Arial,sans-serif;
  font-weight: bold;
  color: rgba(0,0,0,.8509803922);
  font-size: 24px;
  margin-bottom: 0;
  margin-top: 0;
}

.networth-interstitial__sub-title {
  color: #22263f;
  font-size: 14px;
}

.networth-interstitial__option {
  max-width: 500px;
  margin-top: 24px;
  border: solid 1px #e6ecf3;
}

.networth-interstitial__option h2,
.networth-interstitial__option .h2 {
  font-family: "Helvetica Neue",Arial,sans-serif;
  font-weight: bold;
  color: rgba(0,0,0,.8509803922);
  font-size: 24px;
  margin-bottom: 0;
  margin-top: 0;
}

.networth-interstitial__option-manual {
  margin-left: 16px;
  margin-top: 24px;
  border: solid 1px #e6ecf3;
}

.networth-interstitial__option-manual h2,
.networth-interstitial__option-manual .h2 {
  font-family: "Helvetica Neue",Arial,sans-serif;
  font-weight: bold;
  color: rgba(0,0,0,.8509803922);
  font-size: 24px;
  margin-bottom: 0;
  margin-top: 0;
}

@media (max-width: 576px) {
  .networth-interstitial__option-manual {
    margin-left: 0px;
  }
}

@media (max-width: 575.98px) {
  .networth-add-account-form {
    margin-bottom: 64px;
  }
}

.networth-add-account-form .was-validated .form-control:invalid {
  box-shadow: none;
}

.networth-add-account-form .was-validated .form-control:invalid:hover {
  border-color: #d9771c;
}

.networth-add-account-form .was-validated .form-control:valid {
  border-color: #adb5bd;
  box-shadow: none;
}

.networth-add-account-form .was-validated .form-control:valid:hover {
  border-color: #2178c4;
}

.networth-add-account-form .form-control {
  border: 1px solid #adb5bd;
  border-radius: 5px;
  align-items: center;
  background: #fff;
  display: flex;
  position: relative;
  color: #22263f;
  flex-basis: 100%;
  font-size: 18px;
  line-height: 27px;
  min-height: 50px;
  padding: 0 16px;
  width: 100%;
  margin-bottom: 20px;
}

.networth-add-account-form .form-control:hover {
  border-color: #2178c4;
}

.networth-add-account-form .form-control:focus {
  border-color: #2178c4;
  color: #2178c4;
}

.networth-add-account-form__linked-form-title {
  padding: 20px 0;
  font-size: 28px;
}

.networth-add-account-form__linked-form-sub-title {
  font-size: 14px;
}

.networth-add-account-form__container {
  padding: 40px 0;
}

.networth-add-account-form__btns {
  margin-top: 20px;
}

.networth-add-account-form__btns .btn {
  width: auto;
  height: 56px;
}

.networth-yodlee {
  -webkit-overflow-scrolling: "touch";
  position: relative;
}

.networth-yodlee-close {
  position: absolute;
  right: 24px;
  top: 8px;
}

.networth-yodlee-container {
  margin-bottom: 24px;
}

.networth-yodlee .yodlee-fastlink {
  max-height: 670px;
}

.networth-delete-account__close {
  display: inline-block;
  position: absolute;
  max-width: 16px;
  top: 24px;
  right: 24px;
}

.networth-delete-account__title {
  margin-top: 14px;
  font-weight: normal;
  color: #22263f;
}

.networth-delete-account .Title__subheading {
  margin-top: 24px;
  font-size: 16px;
  color: #22263f;
}

.networth-delete-account__buttons {
  display: flex;
  margin-top: 48px;
  flex-direction: row;
  flex-wrap: wrap;
}

.networth-delete-account__button {
  margin-right: 22px;
}

@media (max-width: 576px) {
  .networth-delete-account__button {
    width: 100%;
    margin-right: 0px;
  }
}

.manual-link__button {
  padding-left: 0;
  color: #22263f;
  width: auto;
  position: relative;
  min-height: 56px;
  justify-content: center;
  display: inline-flex;
  font-size: 16px;
  font-weight: bold;
  align-items: center;
}

.manual-link__icon {
  margin-right: 10px;
}

.networth-table td {
  padding: 32px 8px;
  border-bottom: 1px solid #e7edf3;
}

.yodlee-edit-modal__content-container {
  padding-top: 40px;
  padding-bottom: 40px;
}

.networth-account {
  margin-top: 120px;
}

@media (max-width: 768px) {
  .networth-account {
    margin-top: 70px;
  }
}

@media (max-width: 576px) {
  .networth-account {
    margin-top: 48px;
  }
}

@media screen and (max-width: 768px) {
  .networth-account-modal {
    position: absolute;
  }
}

.networth-account-modal__title {
  color: #22263f;
  font-size: 24px;
  font-weight: normal;
  margin-bottom: 0;
  padding: 0;
}

.networth-account-modal__content {
  margin-top: 24px;
}

.networth-account-modal__buttons {
  margin-top: 40px;
  font-family: Basis Grotesque Pro;
  font-size: 18px;
  line-height: 130%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: .005em;
  color: #fff;
}

.profile-nav {
  margin-right: 32px;
}

.profile-nav__container {
  flex-direction: row;
  padding-bottom: 16px;
}

@media screen and (max-width: 640px) {
  .profile-nav__container {
    margin-left: 24px;
  }
}

@media (min-width: 768px) {
  .profile-nav__container {
    flex-direction: column;
  }
}

.profile-nav__container.nav-underline {
  gap: 16px;
}

.profile-nav__link {
  color: #676879;
  font-weight: bold;
}

.profile-nav__link:focus,
.profile-nav__link:hover {
  outline: none;
}

.profile-nav__link-sm {
  padding-bottom: 4px;
  font-size: 14px;
}

.profile-nav__link-sm--active,
.profile-nav__link-sm:hover {
  border-bottom: solid 2px #22263f !important;
}

.profile-nav__link-md {
  position: relative;
  border-bottom: 1px solid #aeb6be;
  padding: 6px 45px 6px 0px;
  font-size: 16px;
}

.profile-nav__link-md:hover {
  color: #676879;
}

.profile-nav__link-md--active {
  color: #22263f;
}

.profile-nav__link-md--active:focus,
.profile-nav__link-md--active:hover {
  color: #22263f;
}

.profile-nav__link-md--active:before {
  position: absolute;
  content: "";
  width: 2px;
  height: 21px;
  background-color: #1ea382;
  left: -16px;
}

.profile-card__title {
  display: inline-block;
  font-size: 46px;
  margin: -5px 0px 24px;
  flex-basis: 100%;
}

.profile-card__title--sub {
  font-size: 18px;
  padding-top: 16px;
}

@media (max-width: 576px) {
  .profile-card__title--sub {
    font-size: 16px;
    padding-top: 8px;
  }
}

@media (max-width: 768px) {
  .profile-card__title {
    font-size: 26px;
  }
}

@media (max-width: 576px) {
  .profile-card__title {
    font-size: 24px;
  }
}

@media screen and (max-width: 640px) {
  .profile-card__title {
    margin-left: 24px;
    flex-basis: 40%;
  }
}

.profile-card__subtitle {
  display: inline-block;
  font-size: 46px;
  margin: -5px 0px 24px;
}

@media (max-width: 768px) {
  .profile-card__subtitle {
    font-size: 26px;
  }
}

@media (max-width: 576px) {
  .profile-card__subtitle {
    font-size: 24px;
  }
}

@media screen and (max-width: 640px) {
  .profile-card__subtitle {
    margin-left: 24px;
  }
}

.profile-card__btn-lg {
  min-width: 134px;
  width: 100%;
}

@media (min-width: 768px) {
  .profile-card__btn-lg {
    width: initial;
  }
}

.CheckboxInput {
  display: block;
  position: relative;
}

.CheckboxInput__input {
  display: block;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
}

.CheckboxInput__input--with-error+.CheckboxInput__label::before,
.CheckboxInput__input--empty-warning+.CheckboxInput__label .CheckboxInput__box::before {
  border-color: 0;
}

.CheckboxInput__input:enabled:hover+.CheckboxInput__label .CheckboxInput__box {
  background: #fff;
  box-shadow: 0 0 0 10px #fff;
  transition: box-shadow 0 0,background 0 0;
}

.CheckboxInput__input:enabled:hover+.CheckboxInput__label .CheckboxInput__box::before {
  border: 1px solid #22263f;
}

.CheckboxInput__input:checked+.CheckboxInput__label .CheckboxInput__box::before {
  animation: shrink-pop 0 ease;
  background: #22263f;
  border: 1px solid #22263f;
}

.CheckboxInput__input:checked+.CheckboxInput__label .CheckboxInput__box::after {
  animation: checkmark 0 ease;
  opacity: 1;
  transform: scaleX(-1) rotate(135deg);
}

.CheckboxInput__input:disabled+.CheckboxInput__label {
  cursor: not-allowed;
}

.CheckboxInput__input:disabled+.CheckboxInput__label .CheckboxInput__box::before {
  background: 0;
  color: #fff;
  content: "—";
  font-size: 19px;
  line-height: 19px;
  text-align: center;
}

.CheckboxInput__input:not(:checked)+.CheckboxInput__label .CheckboxInput__box::before {
  animation: pop-shrink 0 ease;
}

.contact-info__row {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (min-width: 768px) {
  .contact-info__row {
    flex-direction: row;
    justify-content: space-between;
  }
}

.contact-info__row-value,
.contact-info__row-label {
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  word-break: break-word;
}

.contact-info__row-label {
  color: #676879;
}

.contact-info__row-value {
  color: #22263f;
}

.contact-info__address {
  text-align: right;
}

@media (max-width: 768px) {
  .contact-info__address {
    text-align: left;
  }
}

.contact-info__tile-title {
  font-weight: normal;
  margin-bottom: 24px;
}

@media (max-width: 768px) {
  .contact-info__tile-title {
    margin-bottom: 2px;
  }
}

.profile-notif__link {
  color: #3b98e2;
  font-size: 14px;
  font-weight: bold;
}

.notif-settings__check {
  margin-top: 24px;
}

.notif-settings__check .form-check-input {
  margin-top: 0px;
  width: 24px;
  height: 24px;
  margin-right: 16px;
}

.notif-settings__check .form-check-label {
  color: #22263f;
}

.edit-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.edit-modal__overlay {
  position: absolute;
  pointer-events: none;
  opacity: .6;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  top: 0;
  width: 49%;
  height: 100%;
}

.edit-modal__overlay--horizontal-line {
  position: absolute;
  margin: 0;
  width: 100%;
  top: 33%;
  border-color: #ccc;
  opacity: 1;
}

.edit-modal__overlay--horizontal-line2 {
  position: absolute;
  margin: 0;
  width: 100%;
  top: 66%;
  border-color: #ccc;
  opacity: 1;
}

.edit-modal__overlay--vertical-line {
  position: absolute;
  margin: 0;
  height: 100%;
  left: 33%;
  border-right: 1px solid #ccc;
  opacity: 1;
}

.edit-modal__overlay--vertical-line2 {
  position: absolute;
  margin: 0;
  height: 100%;
  left: 66%;
  border-right: 1px solid #ccc;
  opacity: 1;
}

.edit-modal__btn-container {
  display: flex;
  flex-direction: column;
  justify-content: end;
  padding: 40px 32px 32px 32px;
}

@media (min-width: 768px) {
  .edit-modal__btn-container {
    flex-direction: row;
  }
}

.investment-hero__container {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 470px;
  background: #22263f;
}

@media (max-width: 768px) {
  .investment-hero__container {
    height: 388px;
  }
}

@media (max-width: 576px) {
  .investment-hero__container {
    height: 408px;
  }
}

.investment-hero__worth {
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media (max-width: 576px) {
  .investment-hero__worth {
    margin: -20px 0px 0px 0px;
    flex-direction: column;
    padding: 0 24px;
  }
}

.investment-hero__worth-col {
  display: flex;
  flex-direction: column;
  order: 1;
  justify-content: space-between;
}

@media (max-width: 576px) {
  .investment-hero__worth-col--hide {
    display: none;
  }
}

.investment-hero__worth-title {
  font-size: 46px;
}

@media (max-width: 768px) {
  .investment-hero__worth-title {
    margin-top: -5px;
    font-size: 30px;
  }
}

@media (max-width: 576px) {
  .investment-hero__worth-title {
    font-size: 18px;
  }
}

.investment-hero__worth-subtitle {
  font-size: 18px;
  opacity: .7;
}

@media (max-width: 768px) {
  .investment-hero__worth-subtitle {
    font-size: 16px;
  }
}

@media (max-width: 576px) {
  .investment-hero__worth-subtitle {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .investment-hero__worth-balance {
    margin-top: 32px;
  }
}

.investment-hero__worth__label {
  font-size: 18px;
  color: #fff;
}

@media (max-width: 768px) {
  .investment-hero__worth__label {
    font-size: 16px;
    line-height: 30px;
  }
}

.investment-hero__worth__label-light {
  opacity: .7;
}

@media (max-width: 576px) {
  .investment-hero__worth__label-mobile {
    font-size: 14px;
  }
}

.investment-hero__worth__sum {
  display: inline-block;
  font-size: 40px;
  line-height: 50px;
  position: relative;
}

@media (max-width: 768px) {
  .investment-hero__worth__sum {
    font-size: 30px;
    line-height: 34px;
  }
}

@media (max-width: 576px) {
  .investment-hero__worth__sum {
    font-size: 24px;
    line-height: 40px;
  }
}

.investment-hero__worth__sum:first-letter {
  margin-top: -10px;
  margin-right: 8px;
  float: left;
  font-size: 14px;
}

@media (max-width: 768px) {
  .investment-hero__worth__sum:first-letter {
    margin-right: 4px;
    font-size: 12px;
  }
}

@media (max-width: 576px) {
  .investment-hero__worth__sum:first-letter {
    margin-top: -6px;
  }
}

.investment-hero__worth__sum-long {
  font-size: 34px;
}

@media (max-width: 768px) {
  .investment-hero__worth__sum-long {
    font-size: 24px;
  }
}

@media (max-width: 768px) {
  .investment-hero__worth-charts {
    margin-top: -28px;
  }
}

@media (max-width: 576px) {
  .investment-hero__worth-charts {
    margin-top: 0;
  }
}

.inv-allocations__charts {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 576px) {
  .inv-allocations__charts {
    justify-content: space-between;
  }
}

.inv-allocations__chart {
  height: 266px;
  width: 45%;
}

@media (max-width: 576px) {
  .inv-allocations__chart {
    width: 50%;
    height: 162px;
  }
}

.inv-allocations__chart-label {
  display: none;
  line-height: 22px;
  padding-top: 4px;
  text-align: center;
}

@media (max-width: 576px) {
  .inv-allocations__chart-label {
    display: block;
  }
}

.inv-allocations__legend {
  margin-top: 36px;
  width: 90%;
}

@media (max-width: 768px) {
  .inv-allocations__legend {
    margin-top: 0;
  }
}

@media (max-width: 576px) {
  .inv-allocations__legend {
    display: none;
  }
}

.inv-allocations__legend-item {
  margin-left: 24px;
  flex: 0 1 auto;
  max-width: 100%;
}

.inv-allocations__legend-item span {
  display: inline-block;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: .5%;
  vertical-align: middle;
  margin-right: 8px;
}

.inv-allocations__legend-item span:last-of-type {
  margin-right: 0;
}

.inv-allocations__legend-item span:not(:first-of-type) {
  opacity: .7;
}

.inv-allocations__legend-item-dot {
  display: "inline-block";
  height: 12px;
  width: 12px;
  border-radius: 50%;
}

@media (max-width: 576px) {
  .inv-partners-login__title {
    margin: 0px 24px 16px 24px;
  }
}

.inv-partners-login__title--sub {
  color: #22263f;
  font-size: 16px;
  line-height: 24px;
}

@media (min-width: 768px) {
  .inv-partners-login__title--sub {
    margin-top: .5rem;
  }
}

.inv-partners-login__list {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 22px 22px;
  position: relative;
}

@media only screen and (max-width: 1024px) {
  .inv-partners-login__list {
    gap: 10px 10px;
  }
}

@media only screen and (max-width: 768px) {
  .inv-partners-login__list {
    gap: 20px 20px;
    margin-left: 0px;
  }
}

@media only screen and (max-width: 640px) {
  .inv-partners-login__list {
    gap: 6px 14px;
    margin-left: 22px;
  }
}

@media only screen and (max-width: 568px) {
  .inv-partners-login__list {
    flex-direction: column;
    margin-top: 12px;
    margin-left: 0px;
  }

  .inv-partners-login__list>a {
    width: 100%;
  }
}

.inv-partners-login__item {
  min-width: 189px;
  min-height: 96px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: box-shadow 300ms;
  background-color: #fff;
  box-shadow: 0px 0px 0px 1px #fff;
  border-radius: 0px;
}

@media (max-width: 768px) {
  .inv-partners-login__item {
    width: 224px;
    min-height: 106px;
  }
}

@media (max-width: 576px) {
  .inv-partners-login__item {
    width: 100%;
    min-height: 80px;
    margin-top: 8px;
  }
}

.inv-partners-login__item img {
  width: 100%;
  max-width: 120px;
}

.holding-table {
  width: 100%;
  table-layout: fixed;
}

.holding-table__container {
  margin-top: 48px;
  margin-bottom: 160px;
}

@media (max-width: 768px) {
  .holding-table__container {
    margin-top: 40px;
    margin-bottom: 64px;
  }
}

@media (max-width: 576px) {
  .holding-table__container {
    margin-bottom: 32px;
  }
}

.holding-table__row-w {
  background: #fff;
  color: #676879;
  width: 100%;
}

.holding-table__row-w:not(:first-child) {
  border-top: 1px solid #e6ecf3;
}

@media (max-width: 576px) {
  .holding-table__row-w {
    padding: 5px;
  }
}

.holding-table__header {
  font-size: 16px;
  padding: 30px 8px;
}

@media (max-width: 768px) {
  .holding-table__header {
    display: none;
  }
}

@media (max-width: 576px) {
  .holding-table__header {
    display: none;
  }
}

.holding-table__header:last-child {
  padding-left: 6%;
}

.holding-table__header--first {
  width: 2%;
  white-space-collapse: collapsible;
}

.holding-table__header--tickerSymbol {
  width: 11%;
}

.holding-table__header--securityDescription {
  width: 22%;
}

.holding-table__header--allocation {
  width: 27%;
}

.holding-table__header--quantity {
  width: 12%;
}

.holding-table__header--value {
  width: 15%;
}

.holding-table__header--price {
  width: 12%;
}

.holding-table__header--sortable {
  display: inline-flex;
  flex-wrap: nowrap;
  flex-direction: row;
  width: 100%;
  cursor: pointer;
}

.holding-table__title {
  font-size: 24px;
  color: #fff;
}

@media (max-width: 768px) {
  .holding-table__title {
    font-size: 18px;
  }
}

@media (max-width: 576px) {
  .holding-table__title {
    font-size: 16px;
    color: #22263f;
    padding-left: 3%;
    padding-right: 3%;
  }
}

.holding-table__row {
  border-bottom: 1px solid #e7edf3;
}

.holding-table__row:last-of-type {
  border-bottom: 1px solid rgba(0,0,0,0);
}

.holding-table__cell {
  font-size: 16px;
  color: #22263f;
  padding: 32px 8px;
  padding-right: 4%;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 576px) {
  .holding-table__cell {
    font-size: 14px;
    padding: 16px 3%;
  }
}

.holding-table__cell-first {
  width: 2%;
}

@media (max-width: 768px) {
  .holding-table__cell-first {
    display: none;
  }
}

@media (max-width: 576px) {
  .holding-table__cell-first {
    display: none;
  }
}

.holding-table__cell-name {
  width: 11%;
  word-break: break-word;
}

@media (max-width: 768px) {
  .holding-table__cell-name {
    display: none;
  }
}

@media (max-width: 576px) {
  .holding-table__cell-name {
    display: none;
  }
}

.holding-table__cell-description {
  width: 23%;
}

@media (max-width: 768px) {
  .holding-table__cell-description {
    width: 40%;
  }
}

@media (max-width: 576px) {
  .holding-table__cell-description {
    width: 70%;
  }
}

.holding-table__cell-allocation {
  overflow: visible;
  width: 27%;
}

@media (max-width: 768px) {
  .holding-table__cell-allocation {
    width: 40%;
  }
}

@media (max-width: 576px) {
  .holding-table__cell-allocation {
    display: none;
  }
}

.holding-table__cell-qty {
  width: 12%;
}

@media (max-width: 768px) {
  .holding-table__cell-qty {
    display: none;
  }
}

@media (max-width: 576px) {
  .holding-table__cell-qty {
    display: none;
  }
}

.holding-table__cell-value {
  text-align: end;
  width: 15%;
  padding-right: 3%;
}

@media (max-width: 768px) {
  .holding-table__cell-value {
    width: 20%;
  }
}

@media (max-width: 576px) {
  .holding-table__cell-value {
    width: 30%;
  }
}

.holding-table__cell-price {
  width: 12%;
}

@media (max-width: 768px) {
  .holding-table__cell-price {
    display: none;
  }
}

@media (max-width: 576px) {
  .holding-table__cell-price {
    display: none;
  }
}

.holding-table__sort-icon--default {
  position: relative;
  margin-left: 8px;
}

.holding-table__sort-icon--default:before {
  content: "▲";
  color: #676879;
  position: absolute;
  font-size: 8px;
  transform: scaleX(1.5);
  top: 2px;
}

.holding-table__sort-icon--default:after {
  content: "▼";
  color: #676879;
  position: absolute;
  font-size: 8px;
  transform: scaleX(1.5);
  top: 10px;
}

.holding-table__sort-icon--asc {
  position: relative;
  margin-left: 8px;
}

.holding-table__sort-icon--asc:after {
  content: "▼";
  color: #676879;
  position: absolute;
  font-size: 8px;
  transform: scaleX(1.5);
  top: 6px;
}

.holding-table__sort-icon--desc {
  position: relative;
  margin-left: 8px;
}

.holding-table__sort-icon--desc:before {
  content: "▲";
  color: #676879;
  position: absolute;
  font-size: 8px;
  transform: scaleX(1.5);
  top: 6px;
}

.investment-accounts-table {
  position: relative;
}

.investment-accounts-table__row {
  align-items: stretch;
  background-color: #fff;
  margin-bottom: 1px;
  min-height: 94px;
  padding: 24px 32px;
  position: relative;
}

@media (max-width: 768px) {
  .investment-accounts-table__row {
    padding: 24px;
    min-height: 149px;
  }
}

@media (max-width: 576px) {
  .investment-accounts-table__row {
    padding: 16px 24px;
    min-height: 75px;
  }
}

.investment-accounts-table__row--clickable {
  display: none;
}

@media (max-width: 576px) {
  .investment-accounts-table__row--clickable {
    display: block;
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.investment-accounts-table__cell-title {
  color: #22263f;
  font-size: 16px;
  line-height: 22px;
  max-width: 350px;
  word-break: break-word;
}

.investment-accounts-table__cell-subtitle {
  color: #676879;
  font-size: 14px;
  line-height: 22px;
}

.investment-accounts-table__cell-link {
  font-size: 16px;
}

@media (max-width: 768px) {
  .investment-accounts-table__cell-link {
    margin-bottom: 4px;
  }
}

.investment-accounts-table__cell-link>a {
  color: #2178c4;
}

.investment-accounts-table__cell-chart {
  width: 100%;
  max-width: 240px;
}

.investment-accounts-table__cell-chart+div {
  padding-top: 8px;
}

@media (max-width: 576px) {
  .investment-accounts-table__cell-title {
    font-size: 14px;
  }

  .investment-accounts-table__cell-subtitle {
    font-size: 12px;
    line-height: 18px;
  }
}

.holding-hero__main-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 703px;
  background: #22263f;
}

@media (max-width: 768px) {
  .holding-hero__main-container {
    height: 538px;
  }
}

@media (max-width: 576px) {
  .holding-hero__main-container {
    height: 480px;
  }
}

.holding-hero__container {
  display: flex;
  flex-direction: column;
}

.holding-hero__title {
  color: #fff;
  display: flex;
  flex-direction: column;
  margin-top: -20px;
  padding-bottom: 40px;
}

@media (max-width: 768px) {
  .holding-hero__title {
    padding-bottom: 10px;
  }
}

@media (max-width: 576px) {
  .holding-hero__title {
    margin-top: -30px;
    padding-bottom: 20px;
    margin-left: 3%;
  }
}

.holding-hero__title-lcontainer {
  padding-bottom: 6px;
}

.holding-hero__title-link {
  font-size: 16px;
  margin-left: -6px;
  justify-content: start;
  color: #fff;
}

@media (max-width: 576px) {
  .holding-hero__title-link {
    font-size: 14px;
  }
}

.holding-hero__title-name {
  font-size: 24px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@media (max-width: 768px) {
  .holding-hero__title-name {
    font-size: 18px;
  }
}

@media (max-width: 576px) {
  .holding-hero__title-name {
    font-size: 16px;
    margin-right: 3%;
  }
}

.holding-hero__inv {
  color: #fff;
  display: flex;
  flex-direction: row;
  margin-top: -25px;
}

@media (max-width: 576px) {
  .holding-hero__inv {
    flex-direction: column;
    margin-left: 3%;
  }
}

.holding-hero__inv-col {
  display: flex;
  flex-direction: column;
  order: 1;
}

@media (max-width: 576px) {
  .holding-hero__inv-col {
    justify-content: space-between;
    padding-right: 3%;
  }
}

@media (max-width: 576px) {
  .holding-hero__inv-col--hide {
    display: none;
  }
}

.holding-hero__inv-balance {
  margin-top: 32px;
  margin-bottom: 32px;
}

@media (max-width: 576px) {
  .holding-hero__inv-balance {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.holding-hero__inv-donut {
  width: 100%;
  max-width: 300px;
  justify-content: start;
}

@media (max-width: 768px) {
  .holding-hero__inv-donut {
    max-width: 200px;
  }
}

.holding-hero__inv__label {
  font-size: 14px;
  color: #fff;
}

.holding-hero__inv__label-light {
  opacity: .7;
}

.holding-hero__inv__sum {
  display: inline-block;
  font-size: 46px;
  line-height: 70px;
  position: relative;
}

@media (max-width: 768px) {
  .holding-hero__inv__sum {
    font-size: 30px;
    line-height: 60px;
  }
}

@media (max-width: 576px) {
  .holding-hero__inv__sum {
    font-size: 24px;
    line-height: 40px;
  }
}

.holding-hero__inv__sum:first-letter {
  margin-top: -10px;
  margin-right: 8px;
  float: left;
  font-size: 25px;
}

@media (max-width: 768px) {
  .holding-hero__inv__sum:first-letter {
    font-size: 12px;
    margin-right: 4px;
  }
}

@media (max-width: 576px) {
  .holding-hero__inv__sum:first-letter {
    font-size: 12px;
    margin-top: -6px;
    margin-right: 4px;
  }
}

.holding-hero__inv__sum-long {
  font-size: 25px;
}

.holding-hero__inv__sum-long:first-letter {
  font-size: 20px;
}

.holding-hero__inv__table {
  width: 100%;
  align-self: flex-end;
  margin-top: 40px;
}

@media (max-width: 576px) {
  .holding-hero__inv__table {
    width: 100%;
    align-self: flex-start;
    margin-top: -10px;
  }
}

.holding-hero__inv__table-cell {
  padding: 0px 0px;
  align-self: flex-end;
  background-color: #383b52;
  border-bottom: 2px solid #22263f;
  line-height: 24px;
}

.holding-hero__inv__table-content {
  padding: 25px 25px;
}

@media (max-width: 768px) {
  .holding-hero__inv__table-content {
    padding: 17px 17px;
  }
}

@media (max-width: 576px) {
  .holding-hero__inv__table-content {
    padding: 14px 14px;
  }
}

.holding-hero__inv__table-tag {
  width: 1%;
}

.holding-hero__inv__table-name {
  overflow: hidden;
  width: 55%;
}

.holding-hero__inv__table-percentage {
  width: 15%;
  text-align: end;
}

.holding-hero__inv__table-amount {
  width: 29%;
  text-align: end;
}

.holding-hero__inv__table-hover {
  background-color: #4e5165;
}

.inv-preferences-display__toggler {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 8px;
}

.inv-preferences-display__toggler-text {
  max-height: 24px;
  display: inline-block;
  min-width: 320px;
  padding-right: 8px;
  border-right: solid 1px #676879;
  font-weight: bold;
  font-size: 12px;
}

@media (max-width: 576px) {
  .inv-preferences-display__toggler-text {
    min-width: 100%;
    border-right: none;
  }
}

.inv-preferences-display__toggler-button {
  font-size: 12px;
  line-height: 32px;
  color: #676879;
  font-weight: bold;
  padding: 12px 0px;
}

@media (min-width: 768px) {
  .inv-preferences-display__toggler-button {
    padding: 12px 8px;
  }
}

.inv-preferences-display__toggler-icon {
  color: #676879;
  padding-left: 8px;
}

.inv-preferences-display__toggler-icon:before {
  line-height: 32px;
}

.inv-preferences-display__container {
  display: flex;
  justify-content: center;
  column-gap: 72px;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .inv-preferences-display__container {
    column-gap: 0px;
  }
}

@media (max-width: 576px) {
  .inv-preferences-display__container {
    column-gap: 0px;
  }
}

.inv-preferences-display__container .histogram__wrapper {
  width: 96px;
}

@media (max-width: 768px) {
  .inv-preferences-display__container .histogram__wrapper {
    width: 80px;
  }
}

@media (max-width: 576px) {
  .inv-preferences-display__container .histogram__wrapper {
    width: 72px;
  }
}

.inv-preferences-display__label {
  display: inline-block;
  line-height: 24px;
  font-size: 16px;
  min-width: 120px;
}

.inv-preferences-display__label img {
  margin-top: -3px;
  margin-right: 8px;
  width: 20px;
}

.inv-preferences-display__performance .histogram__bar {
  background: #1ea382;
}

.inv-preferences-display__protection .histogram__bar {
  background: #3b98e2;
}

.inv-preferences-display__cost .histogram__bar {
  background: #f6aa33;
}

.inv-preferences-display__taxes .histogram__bar {
  background: #89579d;
}

.investment-footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 80px;
  margin-top: 32px;
}

@media (max-width: 768px) {
  .investment-footer {
    margin-bottom: 24px;
  }
}

@media (max-width: 576px) {
  .investment-footer {
    padding: 0 16px;
  }
}

.investment-footer__link {
  color: #1890ff;
  font-size: 14px;
}

.investment-accounts {
  margin-top: 48px;
}

.investment-accounts--no-accounts {
  margin-top: 68px;
}

@media (max-width: 768px) {
  .investment-accounts {
    margin-top: 44px;
  }
}

@media (max-width: 576px) {
  .investment-accounts {
    margin-top: 88px;
  }

  .investment-accounts--no-accounts {
    margin-top: 124px;
  }
}

.investment-accounts__null {
  height: 212px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.investment-accounts__null-title {
  color: #22263f;
  font-size: 24px;
  line-height: 32px;
}

.investment-accounts__null-subtitle {
  color: #676879;
  line-height: 24px;
  margin-top: 10px;
  text-align: center;
  font-size: 14px;
}

@media (max-width: 768px) {
  .investment-accounts__null {
    height: 196px;
  }
}

@media (max-width: 576px) {
  .investment-accounts__null {
    height: 164px;
  }

  .investment-accounts__null-title {
    font-size: 18px;
    line-height: 24px;
  }
}

.dashboard-article .Carousel__dots--dark-theme li button::before {
  color: #2178c4;
}

.dashboard-article__title {
  font-size: 24px;
  color: #22263f;
}

@media (max-width: 576px) {
  .dashboard-article__title {
    font-size: 18px;
  }
}

.dashboard-article__subtitle {
  font-size: 14px;
  color: #676879;
}

.dashboard-article__subtitle-link {
  color: #2178c4;
}

.dashboard-message {
  color: #22263f;
}

.dashboard-message__container,
.dashboard-message__container--full {
  height: 210px;
}

@media (max-width: 768px) {
  .dashboard-message__container,
  .dashboard-message__container--full {
    height: 164px;
  }
}

@media (max-width: 576px) {
  .dashboard-message__container,
  .dashboard-message__container--full {
    height: 242px;
  }
}

.dashboard-message__container--full {
  height: 150px;
}

@media (max-width: 768px) {
  .dashboard-message__container--full {
    height: 164px;
  }
}

@media (max-width: 576px) {
  .dashboard-message__container--full {
    height: 242px;
  }
}

.dashboard-message__container--full .dashboard-message__body {
  max-width: none;
  -webkit-line-clamp: 4;
}

@media (max-width: 576px) {
  .dashboard-message__container--full .dashboard-message__body {
    -webkit-line-clamp: 8;
  }
}

.dashboard-message__subject {
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (max-width: 576px) {
  .dashboard-message__subject {
    font-size: 18px;
    line-height: 22px;
  }
}

.dashboard-message__author {
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 24px;
  opacity: .7;
}

@media (max-width: 576px) {
  .dashboard-message__author {
    line-height: 26px;
  }
}

.dashboard-message__body {
  color: #676879;
  font-size: 14px;
  line-height: 22px;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 364px;
}

@media (max-width: 768px) {
  .dashboard-message__body {
    max-width: none;
    -webkit-line-clamp: 4;
  }
}

@media (max-width: 576px) {
  .dashboard-message__body {
    -webkit-line-clamp: 8;
  }
}

.dashboard-message .dashboard-tile-null {
  height: 328px;
}

@media (max-width: 768px) {
  .dashboard-message .dashboard-tile-null {
    height: 270px;
  }
}

@media (max-width: 576px) {
  .dashboard-message .dashboard-tile-null {
    height: 358px;
  }
}

.dashboard-account__sum {
  display: inline-block;
  font-size: 46px;
  position: relative;
  margin-top: 8px;
}

@media (max-width: 576px) {
  .dashboard-account__sum {
    font-size: 32px;
    margin-top: 4px;
  }
}

@media (max-width: 576px) {
  .dashboard-account__sum-negative {
    font-size: 24px;
    line-height: 14px;
  }
}

.dashboard-account__sum-dollar:first-letter {
  margin-right: 4px;
  float: left;
  font-size: 24px;
}

@media (max-width: 576px) {
  .dashboard-account__sum-dollar:first-letter {
    margin-top: 0px;
    margin-right: 0px;
    font-size: 18px;
  }
}

.dashboard-account__sum-long {
  font-size: 25px;
}

.dashboard-account__sum-null {
  height: 12px;
  width: 80px;
  background-color: #aeb6be;
}

.dashboard-account__tile {
  font-size: 16px;
}

.dashboard-account__null-container {
  padding-top: 64px;
  padding-bottom: 64px;
}

.dashboard-team {
  justify-content: space-between;
}

.dashboard-team .dashboard-tile__content {
  margin-bottom: 0;
}

.dashboard-team__title {
  font-size: 24px;
  color: #22263f;
}

@media (max-width: 576px) {
  .dashboard-team__title {
    font-size: 18px;
  }
}

.dashboard-team__subtitle {
  font-size: 14px;
  color: #676879;
}

.dashboard-team__subtitle-link {
  color: #2178c4;
}

.dashboard-team__info-w {
  display: inline-flex;
  flex-direction: column;
  padding-left: 16px;
  justify-content: center;
  font-size: 16px;
  line-height: 24px;
}

.dashboard-team__row {
  display: flex;
  justify-content: space-between;
  padding: 18px 16px;
}

.dashboard-team__avatar {
  display: inline-flex;
}

.dashboard-team__name {
  line-height: 22px;
  letter-spacing: .005em;
  color: #22263f;
}

.dashboard-team__phone {
  color: #676879;
}

.dashboard-team__email {
  color: #2178c4;
}

.dash-task-row__w {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border-top: 1px solid #e6ecf3;
  padding: 20px 0;
  width: 100%;
  max-height: 100px;
}

.dash-task-row__w:first-child {
  border-top: none;
}

.dash-task-row__checkbox {
  display: flex;
  align-items: center;
  padding-right: 32px;
}

.dash-task-row__text {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
}

.dash-task-row__text-name {
  font-size: 18px;
  color: #22263f;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80%;
}

@media (max-width: 768px) {
  .dash-task-row__text-name {
    max-width: 60%;
  }
}

@media (max-width: 576px) {
  .dash-task-row__text-name {
    max-width: 75%;
  }
}

.dash-task-row__text-date {
  font-size: 16px;
  color: #676879;
}

.dash-priorities__table {
  height: auto;
}

.dash-priorities__card {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  border-top: 1px solid #e6ecf3;
  padding: 25px 0;
  width: 100%;
  max-height: 180px;
}

.dash-priorities__card__card:first-child {
  border-top: none;
}

.dash-priorities__card-text {
  font-size: 18px;
  color: #22263f;
}

.dash-priorities__accordion .accordion {
  --bs-accordion-border-color: transparent;
  --bs-accordion-btn-padding-x: 0;
}

.dash-priorities__accordion .accordion-button {
  font-size: 24px;
  padding: 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.dash-priorities__accordion .accordion-button.collapsed {
  font-size: 20px;
  color: #22263f;
  padding: 0;
}

.dash-priorities__accordion .accordion-body {
  padding: 16px 0 0 0;
}

.dashboard-plan-tile__null {
  padding-top: 64px;
  padding-bottom: 64px;
}

.dash-score-progress {
  display: flex;
  width: 100%;
}

.dash-score-progress__span {
  flex-grow: 1;
  height: 8px;
  margin-right: 2px;
  background-color: #adb5bd;
}

.dash-score-progress__span:last-child {
  margin-right: 0;
}

.dash-score-progress__span-lowscore {
  background: #d8761c;
}

.dash-score-progress__span-highscore {
  background-color: #068667;
}

.dashboard-p__hero {
  display: flex;
  flex-direction: column;
  height: 443px;
}

@media (max-width: 768px) {
  .dashboard-p__hero {
    height: 368px;
  }
}

@media (max-width: 576px) {
  .dashboard-p__hero {
    height: 224px;
  }
}

.dashboard-p__hero-content {
  position: relative;
}

.dashboard-p__hero-content h1,
.dashboard-p__hero-content .h1 {
  color: #fff;
  font-size: 46px;
  font-weight: normal;
  line-height: 50px;
}

.dashboard-p__tiles {
  position: relative;
  margin-bottom: 160px;
  margin-top: 64px;
}

@media (max-width: 768px) {
  .dashboard-p__tiles {
    margin-bottom: 85px;
  }
}

@media (max-width: 576px) {
  .dashboard-p__tiles {
    margin-bottom: 0;
  }
}

.goal-hero__container {
  display: flex;
  flex-direction: column;
  height: 354px;
}

@media (max-width: 768px) {
  .goal-hero__container {
    height: 398px;
  }
}

@media (max-width: 576px) {
  .goal-hero__container {
    height: 354px;
  }
}

.goal-hero__container-null {
  height: 244px;
}

@media (max-width: 576px) {
  .goal-hero__container-null {
    height: 86px;
  }
}

.goal-hero__content {
  color: #fff;
  margin-top: 16px;
  display: flex;
  flex-direction: row;
}

@media (max-width: 576px) {
  .goal-hero__content {
    margin: -20px 0px 0px 0px;
    flex-direction: column;
    margin-left: 20px;
    margin-right: 20px;
  }
}

@media (max-width: 768px) {
  .goal-hero__content {
    flex-direction: column;
  }
}

.goal-hero__content-null {
  margin-bottom: 100px;
}

.goal-hero__content-title {
  margin-top: -16px;
  font-size: 46px;
}

@media (max-width: 768px) {
  .goal-hero__content-title {
    margin-top: -16px;
    font-size: 30px;
  }
}

@media (max-width: 576px) {
  .goal-hero__content-title {
    font-size: 18px;
  }
}

.goal-hero__content-col {
  flex: 1;
}

.goal-hero__label {
  padding-left: 21px;
  display: flex;
  flex-direction: row;
  flex: 2;
}

.goal-hero__label div:first-child {
  padding-right: 15px;
}

@media (max-width: 768px) {
  .goal-hero__label {
    padding-left: 0px;
    margin-left: -20px;
    margin-top: 20px;
  }

  .goal-hero__label div:first-child {
    padding-right: 0px;
  }
}

@media (max-width: 576px) {
  .goal-hero__label {
    flex-direction: column;
  }

  .goal-hero__label div:first-child {
    padding-right: 0px;
  }
}

.goal-hero__label-col {
  display: flex;
  flex-direction: column;
  order: 1;
  align-items: flex-start;
  margin-left: 20px;
}

.goal-hero__label-col div {
  border-left: solid 1px rgba(255,255,255,.3);
}

@media (max-width: 576px) {
  .goal-hero__label-col {
    flex-direction: row;
  }

  .goal-hero__label-col div {
    border-left: none;
  }

  .goal-hero__label-col div:not(:last-child) {
    border-left: solid 1px rgba(255,255,255,.3);
  }
}

.goal-hero__label-subheader {
  padding-left: 21px;
}

@media (max-width: 576px) {
  .goal-hero__label-subheader {
    width: 90px;
  }
}

.goal-hero__label-amount {
  font-size: 46px;
  margin-top: 8px;
  margin-left: 16px;
}

@media (max-width: 768px) {
  .goal-hero__label-amount {
    font-size: 30px;
  }
}

@media (max-width: 576px) {
  .goal-hero__label-amount {
    font-size: 18px;
  }
}

.goal-hero__label-dot {
  vertical-align: 8px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  box-shadow: 0px 0px 0px 5px rgba(91,208,179,.25);
}

@media (max-width: 576px) {
  .goal-hero__label-dot {
    vertical-align: 0px;
  }
}

.goal-hero__label-light {
  opacity: .7;
  margin-top: 10px;
  font-size: 16px;
  display: table-cell;
  padding-left: 21px;
}

@media (max-width: 576px) {
  .goal-hero__label-light {
    font-size: 14px;
    margin-top: 0px;
    padding-top: 4px;
  }
}

.goal-hero__description {
  margin-top: 32px;
  margin-bottom: 32px;
  font-size: 16px;
  color: #fff;
  opacity: .7;
}

@media (max-width: 768px) {
  .goal-hero__description {
    font-size: 14px;
    margin-right: 20px;
  }
}

@media (max-width: 576px) {
  .goal-hero__description {
    margin-left: 20px;
  }
}

.goal-card {
  display: flex;
  justify-content: space-between;
}

.goal-card__upper {
  flex-wrap: nowrap;
}

@media (max-width: 576px) {
  .goal-card__upper {
    align-items: center;
  }
}

.goal-card__lower {
  width: 100%;
}

@media (max-width: 576px) {
  .goal-card__lower-main {
    display: none;
  }
}

.goal-card__row-item {
  display: flex;
  flex-direction: row;
}

.goal-card__name {
  align-self: center;
  flex-wrap: wrap;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
}

.goal-card__sort {
  align-self: end;
}

.goal-card__link {
  color: #2178c4;
  font-size: 16px;
  align-self: center;
}

@media (max-width: 576px) {
  .goal-card__link {
    display: none;
  }
}

.goal-card__date {
  margin-right: 35px;
}

@media (max-width: 576px) {
  .goal-card__date {
    margin-right: 0px;
  }
}

.goal-card__date>h4,
.goal-card__date>.h4 {
  font-size: 16px;
  font-weight: bold;
  text-align: right;
}

.goal-card__date>div {
  font-size: 14px;
}

.goal-card__type {
  color: #000;
  background-color: #e6ecf3;
  font-size: 12px;
  width: min-content;
  padding: 2px 8px;
  margin-right: 8px;
}

.goal-card__label {
  margin-right: 25px;
  align-self: center;
}

.goal-card__text {
  font-size: 14px;
  white-space: pre-wrap;
}

.goal-card__dot {
  vertical-align: 8px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
}

@media (max-width: 576px) {
  .goal-card__dot {
    vertical-align: 0px;
  }
}

.goal-detail {
  display: flex;
}

.goal-detail__container {
  margin-bottom: 10px;
}

.goal-detail__card {
  padding: 16px;
  margin-bottom: 2px;
}

@media (min-width: 768px) {
  .goal-detail__card {
    padding: 32px;
  }
}

.goal-detail__upper {
  flex-direction: row;
  flex-wrap: nowrap;
}

@media (max-width: 576px) {
  .goal-detail__upper {
    flex-direction: column;
  }
}

.goal-detail__row-item {
  display: flex;
  flex-direction: row;
}

.goal-detail__row-priorities {
  margin-bottom: 20px;
  margin-left: 2px;
  flex-direction: column;
}

.goal-detail__type {
  color: #000;
  background-color: #e6ecf3;
  font-size: smaller;
  width: min-content;
  padding: 2px 8px;
  margin-right: 8px;
}

.goal-detail__lower {
  margin-top: -5px;
}

.goal-detail__label {
  margin-right: 25px;
  align-self: center;
}

.goal-detail__text {
  font-size: 14px;
  padding: 32px;
}

@media (max-width: 576px) {
  .goal-detail__dot {
    margin-top: -4px;
  }
}

.goal-detail__name {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}

@media (max-width: 992px) {
  .goal-detail__name {
    font-size: 24px;
  }
}

.goal-detail__title-link {
  font-size: 16px;
  justify-content: start;
  color: #22263f;
}

@media (max-width: 576px) {
  .goal-detail__title-link {
    font-size: 14px;
  }
}

.goal-detail__title-link .Link__icon {
  transform: rotate(180deg);
  justify-content: start;
}

.goal-detail__title-link .Link__text {
  color: #22263f;
}

.goal-detail__priority-box {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media only screen and (max-width: 600px) {
  .goal-detail__priority-box {
    flex-direction: column;
    align-items: center;
  }
}

.goal-detail__priority-title {
  font-size: 18px;
  font-weight: bold;
  padding: 10px;
}

@media (max-width: 992px) {
  .goal-detail__priority-title {
    font-size: 24px;
    font-weight: bold;
  }
}

@media only screen and (max-width: 600px) {
  .goal-detail__priority-title {
    width: 90%;
    margin: 5px;
    padding: 20px;
  }
}

.goal-detail__priorities {
  font-size: 14px;
  margin-bottom: 20px;
  height: 134px;
  width: 188px;
  color: #fff;
  padding: 10px;
}

@media only screen and (max-width: 600px) {
  .goal-detail__priorities {
    width: 90%;
    height: 35%;
    margin: 5px;
    padding: 20px;
  }
}

.goal-detail__priorities-0,
.goal-detail__priorities-3 {
  background-color: #098667;
}

.goal-detail__priorities-1,
.goal-detail__priorities-4 {
  background-color: #1358ad;
}

.goal-detail__priorities-2 {
  background-color: #6a4084;
}

.goal-detail__dummy-div {
  display: none;
}

@media only screen and (min-width: 600px) {
  .goal-detail__dummy-div {
    margin-bottom: 20px;
    height: 134px;
    width: 188px;
    padding: 10px;
    visibility: hidden;
    display: flex;
  }
}

.goal-detail__date-small {
  display: none;
}

@media (max-width: 576px) {
  .goal-detail__date-small {
    display: flex;
    margin-bottom: 20px;
  }
}

@media (max-width: 576px) {
  .goal-detail__date-large {
    display: none;
  }
}

.goal-sort {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.goal-sort__title {
  font-size: 24px;
  font-weight: bold;
}

@media (min-width: 576px) {
  .goal-sort__title {
    font-size: 18px;
  }
}

.goal-sort .dropdown.show .dropdown-toggle .bi {
  transform: rotate(180deg);
}

.goal-sort .bi {
  color: #2177c4;
  font-size: 18px;
}

.goal-sort .show {
  border-color: #aeb6be;
}

.goal-sort .show:hover {
  background-color: #fff;
  color: #22263f;
  border-color: #aeb6be;
}

.goal-sort__dropdown-toggle {
  min-width: inherit;
  background-color: #fff;
  color: #22263f;
  border-color: #aeb6be;
}

.goal-sort__dropdown-toggle:hover,
.goal-sort__dropdown-toggle:focus,
.goal-sort__dropdown-toggle:active {
  background-color: #fff;
  color: #22263f;
  border-color: #aeb6be;
}

.goal-sort .dropdown-menu {
  background: #fff;
  min-width: inherit;
  padding: 0;
  border: solid 1px #aeb6be;
}

.goal-sort__dropdown-item {
  color: #22263f;
  padding: 16px;
  font-size: 16px;
}

.goal-sort__dropdown-item.active,
.goal-sort__dropdown-item:active {
  color: #22263f;
  background-color: unset;
}

.goal-sort__dropdown-item:hover {
  background-color: rgba(33,120,196,.1019607843);
  color: #22263f;
}

.goal-sort__card {
  margin: 32px 0 24px 0;
  padding: 24px;
}

@media (min-width: 576px) {
  .goal-sort__card {
    margin-top: 24px;
  }
}

@media (min-width: 768px) {
  .goal-sort__card {
    padding: 32px;
  }
}

@media (max-width: 576px) {
  .goal-sort {
    flex-direction: column;
    align-items: flex-start;
  }
}

.goal-null__w {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 60px 0px;
}

@media (max-width: 768px) {
  .goal-null__w {
    padding: 40px 0px;
  }
}

@media (max-width: 768px) {
  .goal-null__w {
    padding: 20px 0px;
  }
}

.goal-null__body-header {
  font-size: 24px;
  color: #22263f;
  margin: 0;
  text-align: center;
}

@media (max-width: 576px) {
  .goal-null__body-header {
    font-size: 18px;
  }
}

.goal-null__body-description {
  font-size: 16px;
  color: #676879;
  padding: 15px 0 30px 0;
  text-align: center;
}

@media (max-width: 576px) {
  .goal-null__body-description {
    font-size: 14px;
  }
}

.plan-hero {
  color: #fff;
}

.plan-hero .ControlledModal__card {
  border-radius: 0;
}

.plan-hero__background {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 512px;
  background: #22263f;
}

@media (max-width: 768px) {
  .plan-hero__background {
    height: 522px;
  }
}

@media (max-width: 576px) {
  .plan-hero__background {
    height: 491px;
  }
}

.plan-hero__container {
  padding-top: 32px;
  padding-bottom: 0;
}

@media (max-width: 768px) {
  .plan-hero__container {
    padding-top: 29px;
  }
}

@media (max-width: 576px) {
  .plan-hero__container {
    padding: 0 24px 0;
  }
}

.plan-hero__header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

@media (max-width: 576px) {
  .plan-hero__header {
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 22px;
  }
}

.plan-hero__title {
  color: #fff;
  font-size: 46px;
  font-weight: normal;
  line-height: 50px;
  margin: 0;
  padding: 0;
  z-index: 1;
}

@media (max-width: 576px) {
  .plan-hero__title {
    font-size: 28px;
    line-height: 36px;
    order: 2;
  }
}

.plan-hero__software,
.plan-hero__software--clickable {
  font-size: 18px;
  opacity: .7;
}

@media (max-width: 576px) {
  .plan-hero__software,
  .plan-hero__software--clickable {
    order: 1;
  }
}

@media (max-width: 576px) {
  .plan-hero__software--clickable {
    display: none;
  }
}

.plan-hero__date {
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 25px;
}

@media (max-width: 576px) {
  .plan-hero__date {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 6px;
  }
}

.plan-hero__description {
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 32px;
}

@media (max-width: 576px) {
  .plan-hero__description {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 24px;
  }
}

.plan-hero__description .Button {
  color: #fff;
  opacity: 1;
  min-height: unset;
  padding: 0;
  width: auto;
}

.plan-hero__description .Button:hover,
.plan-hero__description .Button:focus {
  color: #fff;
}

.plan-hero__description .Button:hover::after,
.plan-hero__description .Button:focus::after {
  color: #fff;
}

@media (max-width: 576px) {
  .plan-hero__description .Button {
    font-size: 14px;
  }
}

.plan-hero__date,
.plan-hero__description>span {
  opacity: .7;
}

.plan-hero__score {
  margin-bottom: 64px;
}

.plan-hero__score--null {
  margin-top: 100px;
  margin-bottom: 140px;
}

@media (max-width: 576px) {
  .plan-hero__score--null {
    margin-bottom: 124px;
  }
}

.plan-hero__modal {
  color: #22263f;
  padding: 20px 24px 40px;
}

.plan-hero__modal-close {
  display: flex;
  justify-content: flex-end;
}

.plan-hero__modal-close .Button {
  padding: 0;
  color: #22263f;
  height: 16px;
  min-height: unset;
  width: auto;
}

.plan-hero__modal-title h2,
.plan-hero__modal-title .h2 {
  color: #22263f;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 16px;
}

.plan-hero__modal-section {
  margin-top: 32px;
}

.plan-hero__modal-color,
.plan-hero__modal-color--underfunded,
.plan-hero__modal-color--ideal,
.plan-hero__modal-color--overfunded {
  height: 4px;
  margin-bottom: 16px;
  width: 48px;
}

.plan-hero__modal-color--underfunded {
  background-color: #e1607a;
}

.plan-hero__modal-color--ideal {
  background-color: #5bd0b3;
}

.plan-hero__modal-color--overfunded {
  background-color: #f4a83e;
}

.plan-hero__modal-label {
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 16px;
  text-transform: capitalize;
}

.plan-hero__modal-text {
  font-size: 16px;
  line-height: 26px;
}

.plan-hero__emoney {
  background-color: rgba(0,0,0,0);
  border-color: #fff;
  color: #fff;
  font-size: 14px;
  font-weight: normal;
  height: 40px;
  line-height: 20px;
  min-height: unset;
  width: 148px;
}

.plan-update__container {
  padding: 32px;
  margin-bottom: 20px;
}

.plan-update__row {
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  display: flex;
  box-sizing: border-box;
  flex: 0 1 auto;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  vertical-align: initial;
}

.plan-update__column {
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  vertical-align: initial;
}

.plan-update__cell-title {
  color: #22263f;
  font-size: 18px;
  line-height: 22px;
  max-width: 350px;
  word-break: break-word;
  flex: 0 1 auto;
  box-sizing: border-box;
  border: none;
  padding: 0;
  margin: 0;
  vertical-align: initial;
}

.plan-update__cell-subtitle {
  color: #676879;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 0;
  box-sizing: border-box;
  flex: 0 1 auto;
  max-width: 100%;
}

.plan-partners-login__container {
  margin-bottom: 20px;
}

@media (min-width: 576px) {
  .plan-partners-login__container {
    margin-top: 32px;
  }
}

@media (min-width: 768px) {
  .plan-partners-login__container {
    margin-left: 4px;
  }
}

@media (max-width: 576px) {
  .plan-partners-login__title {
    margin: 0px 24px 16px 15px;
  }
}

@media (min-width: 768px) {
  .plan-partners-login__title {
    margin-bottom: 16px;
  }
}

.plan-partners-login__list {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 22px 22px;
}

@media (max-width: 768px) {
  .plan-partners-login__list {
    gap: 20px 20px;
    margin-left: 0px;
  }
}

@media (max-width: 576px) {
  .plan-partners-login__list {
    flex-direction: column;
    margin-top: 12px;
    margin-left: 0px;
  }

  .plan-partners-login__list>a {
    width: 100%;
  }
}

.plan-partners-login__item {
  min-width: 189px;
  min-height: 96px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: box-shadow 300ms;
  background-color: #fff;
  box-shadow: 0px 0px 0px 1px #fff;
  border-radius: 0px;
}

@media (max-width: 768px) {
  .plan-partners-login__item {
    width: 224px;
    min-height: 106px;
  }
}

@media (max-width: 576px) {
  .plan-partners-login__item {
    width: 100%;
    min-height: 80px;
    margin-top: 8px;
  }
}

.plan-partners-login__item img {
  width: 100%;
  max-width: 120px;
}

.plan-footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 80px;
  margin-top: 32px;
}

@media (max-width: 768px) {
  .plan-footer {
    margin-bottom: 24px;
  }
}

@media (max-width: 576px) {
  .plan-footer {
    padding: 0 16px;
  }
}

.plan-footer__link.Link__text {
  color: #2178c4;
  margin: 8px 0px 8px;
  font-size: 12px;
  font-weight: normal;
}

.plan-footer__link-container {
  margin-bottom: 40px;
  margin-top: 40px;
}

.card-margin {
  margin-bottom: 20px;
}

.card-margin--link {
  margin-bottom: 40px;
}

.card-margin--placeholder {
  margin-bottom: 60px;
}

.card-margin--update {
  margin-bottom: 0;
}

@media (max-width: 576px) {
  .card-margin--update {
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .card-margin--update {
    margin-bottom: 10px;
  }
}

.card-margin--hero {
  margin-bottom: 20px;
}

@media (max-width: 576px) {
  .card-margin--hero {
    margin-bottom: 0;
  }
}

@media (max-width: 768px) {
  .card-margin--hero {
    margin-bottom: 20px;
  }
}

@media (max-width: 576px) {
  .card-margin--input-title-mobile {
    margin-right: 60px;
  }
}

.plan-inputs__overflow {
  border: none;
}

.plan-inputs__overflow:focus {
  border: dotted 1px #3b98e2;
}

.plan-inputs__overflow .dropdown-menu.show {
  display: block;
  min-width: unset;
  border: 1px solid #e6ecf3;
}

.plan-inputs__overflow .btn {
  color: #2178c4;
  border: dotted 1px rgba(0,0,0,0);
  font-size: 22px;
}

.plan-inputs__overflow .btn:active {
  border: dotted 1px rgba(0,0,0,0);
}

.plan-inputs__overflow .btn.show {
  border: dotted 1px #3b98e2;
}

.plan-inputs__overflow .dropdown-item:hover,
.plan-inputs__overflow .dropdown-item:focus,
.plan-inputs__overflow .dropdown-item:active {
  background-color: unset;
  outline: unset;
  color: unset;
}

.plan-inputs-table {
  margin-bottom: 24px;
}

.plan-inputs-table__row {
  border-bottom: 1px solid #e7edf3;
}

.plan-inputs-table__title {
  color: #22263f;
  font-size: 30px;
  font-weight: normal;
  line-height: 34px;
  padding: 32px;
  margin-bottom: 0;
  border-bottom: 1px solid #e6ecf3;
}

@media (max-width: 576px) {
  .plan-inputs-table__title {
    font-size: 24px;
    line-height: 32px;
    padding: 32px 24px;
  }
}

.plan-inputs-table__column,
.plan-inputs-table__column--only-mobile,
.plan-inputs-table__column--no-mobile {
  padding-top: 24px;
  padding-bottom: 24px;
  vertical-align: middle;
}

@media (max-width: 576px) {
  .plan-inputs-table__column--no-mobile {
    display: none;
  }
}

.plan-inputs-table__column--only-mobile {
  display: none;
}

@media (max-width: 576px) {
  .plan-inputs-table__column--only-mobile {
    display: table-cell;
    padding-left: 24px;
  }
}

.plan-inputs-table__column:first-of-type,
.plan-inputs-table__column--no-mobile:first-of-type,
.plan-inputs-table__column--only-mobile:first-of-type {
  padding-left: 32px;
  width: 35%;
}

@media (max-width: 576px) {
  .plan-inputs-table__column:first-of-type,
  .plan-inputs-table__column--no-mobile:first-of-type,
  .plan-inputs-table__column--only-mobile:first-of-type {
    padding-left: 24px;
    width: auto;
  }
}

@media (max-width: 768px) {
  .plan-inputs-table__column:last-of-type,
  .plan-inputs-table__column--no-mobile:last-of-type,
  .plan-inputs-table__column--only-mobile:last-of-type {
    width: 10%;
  }
}

@media (max-width: 576px) {
  .plan-inputs-table__column:last-of-type,
  .plan-inputs-table__column--no-mobile:last-of-type,
  .plan-inputs-table__column--only-mobile:last-of-type {
    width: 20%;
  }
}

.plan-inputs-table__field {
  color: #22263f;
  font-size: 16px;
  line-height: 22px;
  min-height: 22px;
  word-break: break-word;
}

.plan-inputs-table__label {
  color: #676879;
  font-size: 14px;
  line-height: 22px;
}

.plan-score-bar {
  color: #22263f;
}

.plan-score-bar__score-box {
  height: 86px;
}

.plan-score-bar__score {
  position: absolute;
  top: -88px;
  width: 16px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 576px) {
  .plan-score-bar__score {
    top: -74px;
  }
}

.plan-score-bar__score--min {
  align-items: flex-start;
}

.plan-score-bar__score--max {
  align-items: flex-end;
}

.plan-score-bar__score-num {
  font-size: 46px;
  line-height: 50px;
}

@media (max-width: 576px) {
  .plan-score-bar__score-num {
    font-size: 28px;
    line-height: 36px;
  }
}

.plan-score-bar__score-marker {
  height: 48px;
  width: 16px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.plan-score-bar__score-line {
  background-color: #22263f;
  height: 28px;
  opacity: .3;
  width: 2px;
}

.plan-score-bar__score-dot,
.plan-score-bar__score-dot--underfunded,
.plan-score-bar__score-dot--ideal,
.plan-score-bar__score-dot--overfunded {
  border: 2px solid #fff;
  border-radius: 50%;
  box-shadow: 0px 2px 4px rgba(0,0,0,.1);
  height: 16px;
  width: 100%;
}

.plan-score-bar__score-dot--underfunded {
  background-color: #e1607a;
}

.plan-score-bar__score-dot--ideal {
  background-color: #5bd0b3;
}

.plan-score-bar__score-dot--overfunded {
  background-color: #f4a83e;
}

.plan-score-bar__score-line {
  background-color: #22263f;
  height: 28px;
  opacity: .3;
  width: 2px;
}

.plan-score-bar__score-dot,
.plan-score-bar__score-dot--underfunded,
.plan-score-bar__score-dot--ideal,
.plan-score-bar__score-dot--overfunded {
  border: 2px solid #fff;
  border-radius: 50%;
  box-shadow: 0px 2px 4px rgba(0,0,0,.1);
  height: 16px;
  width: 100%;
  z-index: 99;
}

.plan-score-bar__score-dot--underfunded {
  background-color: #e1607a;
}

.plan-score-bar__score-dot--ideal {
  background-color: #5bd0b3;
}

.plan-score-bar__score-dot--overfunded {
  background-color: #f4a83e;
}

.plan-score-bar__bar {
  position: relative;
}

.plan-score-bar__sections {
  display: flex;
  width: 100%;
}

@media (max-width: 576px) {
  .plan-score-bar__section:nth-child(2) {
    text-align: center;
  }
}

.plan-score-bar__section-bar,
.plan-score-bar__section-bar--null,
.plan-score-bar__section-bar--underfunded,
.plan-score-bar__section-bar--ideal,
.plan-score-bar__section-bar--overfunded {
  height: 4px;
  margin-bottom: 12px;
}

.plan-score-bar__section-bar--underfunded {
  background-color: #e1607a;
}

.plan-score-bar__section-bar--ideal {
  background-color: #5bd0b3;
}

.plan-score-bar__section-bar--overfunded {
  background-color: #f4a83e;
}

.plan-score-bar__section-bar--null {
  background-color: #f2f5f9;
  opacity: .2;
}

.plan-score-bar__section-label {
  font-size: 16px;
  line-height: 22px;
  opacity: .7;
  text-transform: capitalize;
}

@media (max-width: 576px) {
  .plan-score-bar__section-label {
    font-size: 13.5px;
    line-height: 24px;
  }
}

.plan-score-bar--light {
  color: #fff;
}

.plan-score-bar--light .plan-score-bar__score-line {
  background-color: #e4e5e7;
}

.plan-score-bar--light .plan-score-bar__gradient-label {
  opacity: .7;
}

.plan-score-bar__gradient-graph-w {
  display: flex;
  flex-direction: row;
}

.plan-score-bar__gradient-section {
  width: 100%;
}

.plan-score-bar__gradient-section--underfunded-placeholder {
  width: 70%;
}

.plan-score-bar__gradient-section--ideal-placeholder {
  width: 20%;
}

.plan-score-bar__gradient-section--overfunded-placeholder {
  width: 10%;
}

.plan-score-bar__gradient-bar-w {
  align-items: center;
  display: flex;
}

.plan-score-bar__gradient-bar-w--gradient {
  width: 100%;
}

.plan-score-bar__gradient-bar {
  height: 4px;
  flex: 1;
}

.plan-score-bar__gradient-bar--underfunded {
  background-color: #e1607a;
}

.plan-score-bar__gradient-bar--ideal {
  background-color: #5bd0b3;
}

.plan-score-bar__gradient-bar--overfunded {
  background-color: #f4a83e;
}

.plan-score-bar__gradient-bar--ideal {
  position: relative;
  display: inline-block;
  height: 16px;
  width: 100%;
}

.plan-score-bar__gradient-bar--ideal:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 50%;
  transform: translateY(50%);
  height: 34px;
  width: 1px;
  border-left: .5px dashed #22263f;
  opacity: .3;
}

.plan-score-bar__gradient-bar--ideal:after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 50%;
  transform: translateY(50%);
  height: 34px;
  width: 1px;
  border-right: .5px dashed #22263f;
  opacity: .3;
}

.plan-score-bar__gradient-gradient {
  width: 100%;
  background-image: linear-gradient(to right, #e1607a, #5bd0b3, #f4a83e);
}

.plan-score-bar__gradient-label-w {
  display: flex;
  flex-direction: row;
}

.plan-score-bar__gradient-label {
  flex: 1;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-top: 16px;
  max-width: 385px;
  width: 100%;
}

.plan-score-bar__gradient-label--overfunded {
  max-width: 76px;
  text-align: right;
  width: 100%;
}

.plan-score-bar__gradient-label--ideal {
  width: 100%;
}

@media (max-width: 576px) {
  .plan-score-bar__gradient-label--ideal {
    text-align: center;
  }
}

.plan-score-bar__gradient-w {
  position: relative;
  flex-direction: column;
  display: flex;
  justify-content: flex-end;
}

.plan-score-bar__gradient-w--placeholder {
  background: #fff;
  opacity: .15;
  filter: blur(5px);
}

.funded-score {
  background: none;
  padding: 10px;
}

.funded-score__graph {
  position: relative;
  height: 200px;
  width: 100%;
  padding-right: 15px;
}

@media (max-width: 576px) {
  .funded-score__container--no-mobile {
    display: none;
  }
}

.funded-score__container-title {
  color: #22263f;
  font-size: 18px;
  line-height: 22px;
  max-width: 350px;
  word-break: break-word;
}

.funded-score__container-subtitle {
  color: #676879;
  font-size: 14px;
  line-height: 22px;
}

@media (max-width: 576px) {
  .funded-score__container-title {
    font-size: 14px;
  }

  .funded-score__container-subtitle {
    font-size: 12px;
    line-height: 18px;
  }
}

.funded-score__modal .modal-dialog {
  max-width: 320px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .funded-score__modal .modal-dialog {
    max-width: 585px;
  }
}

.funded-score__modal-score-bar {
  padding: 32px 0;
}

.funded-score__modal-title {
  font-family: "Helvetica Neue",Arial,sans-serif;
  font-weight: bold;
  color: rgba(0,0,0,.8509803922);
  font-size: 24px;
  line-height: 32px;
}

.funded-score__modal-subtitle {
  font-size: 16px;
  line-height: 26px;
  color: #676879;
}

.funded-score__modal-date {
  margin-top: 8px;
  font-size: 14px;
}

.funded-score__note-card {
  padding: 0 32px 32px 32px;
  margin: 2px 0 20px;
}

.plan-null__row-container {
  text-align: center;
  min-height: 280px;
  box-sizing: border-box;
  flex-wrap: wrap;
  max-width: 972px;
}

.plan-null__heading-container {
  height: 98px;
  width: 430px;
  flex-basis: 45%;
  box-sizing: border-box;
  flex: 0 1 auto;
  max-width: 100%;
}

.plan-null__heading {
  font-weight: bold;
  margin-bottom: 15px;
}

.plan-null__title-subheading {
  font-size: 16px;
}

.plan-input-forms .form-control {
  border: 1px solid #adb5bd;
  border-radius: 5px;
  align-items: center;
  background: #fff;
  display: flex;
  position: relative;
  color: #22263f;
  flex-basis: 100%;
  font-size: 18px;
  line-height: 27px;
  min-height: 50px;
  padding: 0 16px;
  width: 100%;
  margin-bottom: 20px;
}

.plan-input-forms .form-control:hover {
  border-color: #2178c4;
}

.plan-input-forms .form-control:focus {
  border-color: #2178c4;
  color: #2178c4;
}

.plan-input-forms .form-label {
  display: flex;
  row-gap: 8px;
}

.plan-input-forms__card {
  border: none;
  max-width: 588px;
  padding: 0 32px 32px 32px;
}

.plan-input-forms__input-half {
  width: 47.5%;
}

@media (max-width: 576px) {
  .plan-input-forms__input-half {
    width: 100%;
  }
}

.plan-input-forms h2,
.plan-input-forms .h2 {
  font-weight: 400;
  font-size: 46px;
  line-height: 50px;
  letter-spacing: .005em;
  color: #22263f;
  margin-bottom: 23px;
}

.plan-input-forms__label-dropdown {
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: bold;
  color: #22263f;
}

.plan-input-forms__button {
  padding: 16px 32px;
  font-size: 16px;
}

.plan-input-forms__button-cancel {
  color: #2178c4;
  font-weight: bold;
  margin-left: 8px;
}

@media (max-width: 767.98px) {
  .plan-input-forms__button-cancel {
    margin-left: 0;
    margin-top: 8px;
  }
}

.plan-input-forms__dropdown-item {
  color: rgba(0,0,0,.6509803922);
  padding: 16px;
  font-size: 16px;
}

.plan-input-forms__dropdown-item.active,
.plan-input-forms__dropdown-item:active {
  color: rgba(0,0,0,.6509803922);
  background-color: unset;
}

.plan-input-forms__dropdown-item:hover {
  background-color: rgba(33,120,196,.1019607843);
  color: rgba(0,0,0,.6509803922);
}

.plan-input-forms__subheading {
  margin-top: 8px;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 24px;
  color: #22263f;
}

@media (max-width: 576px) {
  .plan-input-forms h2,
  .plan-input-forms .h2 {
    font-size: 25px;
    line-height: 36px;
    color: #22263f;
  }
}

.plan-input-forms .dropdown {
  padding: 0;
  min-width: 170px;
  margin-bottom: 20px;
}

.plan-input-forms .dropdown-menu.show {
  display: block;
  width: 100%;
  border: 1px solid #2178c4;
  padding: 0;
}

.plan-input-forms .dropdown-toggle {
  border: 1px solid #adb5bd;
  font-size: 16px;
  border-radius: 4px;
  line-height: 27px;
  color: #22263f;
}

.plan-input-forms .dropdown-toggle:hover {
  border: 1px solid #2178c4;
}

.plan-input-forms .dropdown-toggle.show {
  border: 1px solid #2178c4;
  color: #2178c4;
}

.plan-input-forms .dropdown .dropdown-toggle .bi {
  margin-right: 10px;
  color: #2178c4;
}

.plan-input-forms .dropdown.show .dropdown-toggle .bi-chevron-down {
  transform: rotate(180deg);
}

.plan-input-forms__dropdown-is-invalid .form-control {
  border: 1px solid rgba(0,0,0,0);
  border-radius: 5px;
}

.plan-input-forms__dropdown-is-invalid .form-control:hover {
  border-color: rgba(0,0,0,0);
}

.plan-input-forms__dropdown-is-invalid .form-control:focus {
  border-color: rgba(0,0,0,0);
}

.plan-input-forms__dropdown-is-invalid .dropdown,
.plan-input-forms__dropdown-is-invalid .dropdown-menu,
.plan-input-forms__dropdown-is-invalid .dropdown-menu.show,
.plan-input-forms__dropdown-is-invalid .dropdown-toggle.show {
  border: 1px solid #d9771c;
  border-radius: 4px;
  margin-bottom: 8px;
}

.plan-input-forms__dropdown-is-invalid .dropdown-toggle .bi {
  color: #d9771c;
}

.plan-input-forms .plan-input-forms__dropdown-is-invalid .dropdown-toggle {
  border: 1px solid rgba(0,0,0,0);
}

.plan-input-forms .plan-input-forms__dropdown-is-invalid .dropdown-toggle:hover {
  border: 1px solid rgba(0,0,0,0);
}

.plan-input-forms .plan-input-forms__dropdown-is-invalid .dropdown .dropdown-toggle .bi {
  color: #d9771c;
}

.pending-updates__header {
  margin-bottom: 24px;
  padding: 32px;
  margin-bottom: 0;
  border-bottom: 1px solid #e6ecf3;
}

.pending-updates__header-title {
  color: #22263f;
  font-size: 18px;
  line-height: 22px;
  font-weight: normal;
}

.pending-updates__header-subtitle {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: .005em;
  color: #676879;
  display: flex;
}

.pending-updates__header-link {
  width: 85px;
  margin-left: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.pending-updates__table-row {
  border-bottom: 1px solid #e7edf3;
}

.pending-updates__table-tag {
  font-size: 12px;
  line-height: 14px;
  color: #22263f;
  background: #e6ecf3;
  width: 60px;
  text-align: center;
  padding: 4px;
}

.pending-updates__table-val--strike {
  font-size: 16px;
  line-height: 22px;
  min-height: 22px;
  word-break: break-word;
  text-decoration: line-through;
  color: #aeb6be;
}

.pending-updates__icon-up {
  transform: rotate(180deg);
}

.delete-input-modal .modal-dialog {
  max-width: 400px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .delete-input-modal .modal-dialog {
    max-width: 500px;
  }
}

.delete-input-modal__close {
  display: inline-block;
  position: absolute;
  max-width: 16px;
  top: 24px;
  right: 24px;
}

.delete-input-modal__title {
  font-size: 18px;
  font-weight: normal;
  color: #22263f;
}

@media (min-width: 992px) {
  .delete-input-modal__title {
    font-size: 24px;
  }
}

.delete-input-modal__subheading {
  margin-top: 24px;
  font-size: 16px;
  color: #22263f;
}

.delete-input-modal__buttons {
  display: flex;
  margin-top: 48px;
  flex-direction: row;
  flex-wrap: wrap;
}

.delete-input-modal__button-delete {
  border-radius: 4px;
  margin-right: 22px;
}

@media (max-width: 576px) {
  .delete-input-modal__button-delete {
    width: 100%;
    margin-right: 0px;
  }
}

.delete-input-modal__button-cancel {
  color: #2178c4;
  background-color: rgba(0,0,0,0);
  border-radius: 0;
  border: none;
}

@media (max-width: 767.98px) {
  .delete-input-modal__button-cancel {
    margin-right: 22px;
    margin-top: 8px;
  }
}

.plan-inputs__overflow {
  border: none;
}

.plan-inputs__overflow:focus {
  border: dotted 1px #3b98e2;
}

.plan-inputs__overflow .dropdown-menu.show {
  display: block;
  min-width: unset;
  border: 1px solid #e6ecf3;
}

.plan-inputs__overflow .btn {
  color: #2178c4;
  border: dotted 1px rgba(0,0,0,0);
  font-size: 22px;
}

.plan-inputs__overflow .btn:active {
  border: dotted 1px rgba(0,0,0,0);
}

.plan-inputs__overflow .btn.show {
  border: dotted 1px #3b98e2;
}

.plan-inputs__overflow .dropdown-item:hover,
.plan-inputs__overflow .dropdown-item:focus,
.plan-inputs__overflow .dropdown-item:active {
  background-color: unset;
  outline: unset;
  color: unset;
}

.plan-inputs-table {
  margin-bottom: 24px;
}

.plan-inputs-table__row {
  border-bottom: 1px solid #e7edf3;
}

.plan-inputs-table__title {
  color: #22263f;
  font-size: 30px;
  font-weight: normal;
  line-height: 34px;
  padding: 32px;
  margin-bottom: 0;
  border-bottom: 1px solid #e6ecf3;
}

@media (max-width: 576px) {
  .plan-inputs-table__title {
    font-size: 24px;
    line-height: 32px;
    padding: 32px 24px;
  }
}

.plan-inputs-table__column,
.plan-inputs-table__column--no-mobile,
.plan-inputs-table__column--only-mobile {
  padding-top: 24px;
  padding-bottom: 24px;
  vertical-align: middle;
}

@media (max-width: 576px) {
  .plan-inputs-table__column--no-mobile {
    display: none;
  }
}

.plan-inputs-table__column--only-mobile {
  display: none;
}

@media (max-width: 576px) {
  .plan-inputs-table__column--only-mobile {
    display: table-cell;
    padding-left: 24px;
  }
}

.plan-inputs-table__column:first-of-type,
.plan-inputs-table__column--no-mobile:first-of-type,
.plan-inputs-table__column--only-mobile:first-of-type {
  padding-left: 32px;
  width: 35%;
}

@media (max-width: 576px) {
  .plan-inputs-table__column:first-of-type,
  .plan-inputs-table__column--no-mobile:first-of-type,
  .plan-inputs-table__column--only-mobile:first-of-type {
    padding-left: 24px;
    width: auto;
  }
}

@media (max-width: 768px) {
  .plan-inputs-table__column:last-of-type,
  .plan-inputs-table__column--no-mobile:last-of-type,
  .plan-inputs-table__column--only-mobile:last-of-type {
    width: 10%;
  }
}

@media (max-width: 576px) {
  .plan-inputs-table__column:last-of-type,
  .plan-inputs-table__column--no-mobile:last-of-type,
  .plan-inputs-table__column--only-mobile:last-of-type {
    width: 20%;
  }
}

.plan-inputs-table__field {
  color: #22263f;
  font-size: 16px;
  line-height: 22px;
  min-height: 22px;
  word-break: break-word;
}

.plan-inputs-table__label {
  color: #676879;
  font-size: 14px;
  line-height: 22px;
}

.score-history-tile__notes-title {
  color: #22263f;
  font-weight: 400;
  padding-top: 32px;
  margin: 0;
  font-size: 18px;
  line-height: 30px;
}

.score-history-tile__notes-title--with-border {
  border-top: 1px solid #d2d2d2;
}

.score-history-tile__notes-subtitle {
  color: #22263f;
  font-size: 14px;
  line-height: 22px;
}

.plan-emoney-login,
.plan-emoney-login--no-chart {
  display: none;
  min-height: 36px;
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
  padding: 12px 16px 36px;
}

.plan-emoney-login--no-chart {
  margin-top: 160px;
}

@media screen and (max-width: 485px) {
  .plan-emoney-login--no-chart {
    margin-top: 120px;
  }
}

@media screen and (max-width: 340px) {
  .plan-emoney-login--no-chart {
    margin-top: 100px;
  }
}

@media (max-width: 576px) {
  .plan-emoney-login,
  .plan-emoney-login--no-chart {
    display: block;
  }
}

.mm-hero__container {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 680px;
  background-color: #f2f5f9;
}

@media (max-width: 992px) {
  .mm-hero__container {
    height: 600px;
  }
}

@media (max-width: 768px) {
  .mm-hero__container {
    height: 460px;
  }
}

@media (max-width: 576px) {
  .mm-hero__container {
    height: 0px;
  }
}

.mm-hero__result {
  display: flex;
  flex-direction: column;
}

@media (max-width: 992px) {
  .mm-hero__result {
    padding: 0 20px;
  }
}

@media (max-width: 768px) {
  .mm-hero__result {
    padding: 0 30px;
  }
}

@media (max-width: 576px) {
  .mm-hero__result {
    justify-content: center;
    padding: 0 30px;
  }
}

.mm-hero__title {
  flex-grow: 3;
  font-size: 18px;
}

.mm-hero__title-w {
  display: flex;
  flex-direction: row;
}

@media (max-width: 576px) {
  .mm-hero__title-w {
    flex-direction: column;
    justify-content: center;
  }
}

.mm-hero__title-text {
  font-size: 46px;
  display: flex;
}

.mm-hero__title-text--Protection {
  color: #3b8469;
}

.mm-hero__title-text--Commitment {
  color: #3878be;
}

.mm-hero__title-text--Happiness {
  color: #6a4084;
}

@media (max-width: 768px) {
  .mm-hero__title-text {
    font-size: 36px;
  }
}

@media (max-width: 576px) {
  .mm-hero__title-text {
    font-size: 24px;
  }
}

.mm-hero__title-col {
  display: flex;
  flex-direction: column;
}

.mm-hero__title-plus {
  padding: 0 10px;
}

.mm-hero__btn {
  margin-left: auto;
  background-color: rgba(0,0,0,0);
  color: #22263f;
}

.mm-hero__btn:hover {
  color: #fff;
}

@media (max-width: 576px) {
  .mm-hero__btn {
    margin: 20px 0;
  }
}

.mm-hero__card {
  background: none;
}

.mm-graphs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 280px;
  max-height: 280px;
}

.mm-graphs__w {
  display: flex;
  justify-content: space-between;
}

.mm-graphs__result {
  width: 30%;
  display: flex;
  flex-direction: column;
}

.mm-graphs__result__title {
  font-size: 24px;
  color: #3878be;
  margin: 15px 0 10px 0;
}

@media (max-width: 768px) {
  .mm-graphs__result__title {
    font-size: 20px;
  }
}

@media (max-width: 576px) {
  .mm-graphs__result__title {
    font-size: 15px;
  }
}

.mm-graphs__result__percent {
  font-size: 32px;
  color: #22263f;
  margin: 15px 0 10px 0;
}

@media (max-width: 768px) {
  .mm-graphs__result__percent {
    font-size: 26px;
  }
}

@media (max-width: 576px) {
  .mm-graphs__result__percent {
    font-size: 20px;
  }
}

.mm-graphs__graph {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.mm-graphs__graph__row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.mm-graphs__graph__cell {
  overflow: hidden;
  position: relative;
  margin: 4px;
}

@media (max-width: 1200px) {
  .mm-graphs__graph__cell {
    margin: 4px;
  }
}

@media (max-width: 992px) {
  .mm-graphs__graph__cell {
    margin: 3px;
  }
}

@media (max-width: 768px) {
  .mm-graphs__graph__cell {
    margin: 2px;
  }
}

@media (max-width: 576px) {
  .mm-graphs__graph__cell {
    margin: 1px;
  }
}

.mm-graphs__graph__cell:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.mm-graphs__graph__cell--10 {
  flex-basis: 7%;
}

@media (max-width: 768px) {
  .mm-graphs__graph__cell--10 {
    flex-basis: 8%;
  }
}

@media (max-width: 576px) {
  .mm-graphs__graph__cell--10 {
    flex-basis: 9%;
  }
}

.null__container {
  background-color: #f2f5f9;
  width: 100%;
}

.null__margin-flexbox {
  margin-top: 40px;
  margin-left: 20px;
}

@media (max-width: 576px) {
  .null__margin-flexbox {
    margin-left: 10px;
  }
}

.null__margin-second-text {
  margin-bottom: 50px;
}

.null__margin-button {
  font-size: 1rem;
  padding: 16px 32px;
  border-radius: 4px;
  margin-bottom: 100px;
}

@media (max-width: 576px) {
  .null__margin-button {
    width: 90%;
    margin-left: 10px;
  }
}

.null__fonts-first {
  font-size: 18px;
}

.null__fonts-second {
  font-size: 46px;
}

@media (max-width: 576px) {
  .null__fonts-second {
    font-size: 36px;
  }
}

.null__fonts-third {
  font-size: 16px;
}

.description {
  margin-top: 16px;
}

.description--plus {
  margin-top: 0px;
  font-size: 24px;
}

@media (max-width: 576px) {
  .description--plus {
    font-size: 14px;
  }
}

.description--influenceFonts,
.description__influence__title--commitment,
.description__influence__title--happiness,
.description__influence__title--protection {
  font-size: 24px;
  text-transform: capitalize;
}

@media (max-width: 576px) {
  .description--influenceFonts,
  .description__influence__title--commitment,
  .description__influence__title--happiness,
  .description__influence__title--protection {
    font-size: 14px;
  }
}

.description__card {
  margin-top: 32px;
  padding: 32px;
}

@media (max-width: 767.98px) {
  .description__card {
    padding: 16px;
  }
}

.description__influence {
  height: auto;
  width: 100%;
  background-color: #fff;
  margin-bottom: 16px;
  padding-bottom: 10px;
}

@media (max-width: 576px) {
  .description__influence {
    height: auto;
  }
}

.description__influence__title {
  background-color: #fff;
  text-transform: capitalize;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  height: 50px;
  padding-left: 16px;
}

@media (max-width: 576px) {
  .description__influence__title {
    font-size: 12px;
  }
}

.description__influence__title--protection {
  color: #3b8469;
}

.description__influence__title--happiness {
  color: #6a4084;
}

.description__influence__title--commitment {
  color: #3878be;
}

.description__influence__content {
  color: #22263f;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 14px;
}

@media (max-width: 576px) {
  .description__influence__content {
    font-size: 11px;
  }
}

.description__influence__content ul {
  list-style: none;
  padding-left: 0;
  margin-top: 24px;
}

.description__influence__content ul li {
  margin-bottom: 16px;
}

.description__influence__content ul b {
  float: left;
  color: #676879;
  font-size: 11px;
  letter-spacing: .7px;
  text-transform: capitalize;
  min-width: 93px;
}

@media (max-width: 576px) {
  .description__influence__content ul b {
    float: none;
    display: block;
  }
}

.mm-partner-card__light {
  background-color: #f2f5fa;
  padding: 24px;
  margin-top: 24px;
  margin-bottom: 24px;
}

@media (min-width: 768px) {
  .mm-partner-card__light {
    padding: 32px;
  }
}

.mm-partner-card__contact-container {
  margin-bottom: 24px;
  padding-right: 8px;
}

@media (min-width: 576px) {
  .mm-partner-card__contact-container {
    margin-bottom: 0;
  }
}

.mm-partner-card__contact {
  font-family: "Helvetica Neue",Arial,sans-serif;
  font-weight: bold;
  color: rgba(0,0,0,.8509803922);
  font-size: 18px;
  margin-bottom: .5em;
  margin-top: 0;
}

@media (min-width: 992px) {
  .mm-partner-card__contact {
    font-family: "Helvetica Neue",Arial,sans-serif;
    font-weight: bold;
    color: rgba(0,0,0,.8509803922);
    font-size: 24px;
  }
}

.mm-page {
  margin: 48px 0 160px 0;
}

@media (max-width: 768px) {
  .mm-page {
    margin-top: 70px;
  }
}

@media (max-width: 576px) {
  .mm-page {
    margin-top: 48px;
  }
}

.insurance-null__w {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.insurance-null__body-header {
  font-size: 24px;
  color: #22263f;
  margin: 0;
}

.insurance-null__body-description {
  font-size: 16px;
  color: #676879;
  padding: 15px 0 30px 0;
  text-align: center;
}

.insurance-card__text-amount {
  text-align: end;
}

.insurance-card__text-amount h3,
.insurance-card__text-amount .h3 {
  font-family: "Helvetica Neue",Arial,sans-serif;
  font-weight: bold;
  color: rgba(0,0,0,.8509803922);
  font-size: 24px;
}

@media (max-width: 992px) {
  .insurance-card__text-amount h3,
  .insurance-card__text-amount .h3 {
    font-size: 18px;
  }
}

.insurance-card__text-amount h4,
.insurance-card__text-amount .h4 {
  font-family: "Helvetica Neue",Arial,sans-serif;
  font-weight: bold;
  color: rgba(0,0,0,.8509803922);
  font-size: 16px;
  margin-bottom: 1em;
  margin-top: 0;
}

@media (max-width: 768px) {
  .insurance-card__text-amount {
    padding-left: 10px;
  }
}

@media (max-width: 576px) {
  .insurance-card__text-amount {
    text-align: start;
  }
}

.insurance-card__text-name h3,
.insurance-card__text-name .h3 {
  font-family: "Helvetica Neue",Arial,sans-serif;
  font-weight: bold;
  color: rgba(0,0,0,.8509803922);
  font-size: 24px;
}

@media (max-width: 992px) {
  .insurance-card__text-name h3,
  .insurance-card__text-name .h3 {
    font-size: 18px;
  }
}

.insurance-card__text-name h4,
.insurance-card__text-name .h4 {
  font-family: "Helvetica Neue",Arial,sans-serif;
  font-weight: bold;
  color: rgba(0,0,0,.8509803922);
  font-size: 16px;
  margin-bottom: 1em;
  margin-top: 0;
}

@media (max-width: 768px) {
  .insurance-card__text-name {
    padding-left: 10px;
  }
}

.insurance-card__text-null {
  margin-bottom: 35px;
}

.insurance-card__upper {
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0;
  padding: 32px 20px;
}

@media (max-width: 576px) {
  .insurance-card__upper .insurance-card__text {
    padding: 10px;
  }
}

@media (max-width: 576px) {
  .insurance-card__upper {
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .insurance-card__upper {
    padding: 16px;
  }
}

.insurance-card__lower {
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0;
  padding: 32px;
}

.insurance-card__lower .insurance-card__text {
  font-family: "Helvetica Neue",Arial,sans-serif;
  font-size: 14px;
  box-sizing: border-box;
  flex: 0 1 auto;
  max-width: 100%;
}

.insurance-card__lower .insurance-card__text h4,
.insurance-card__lower .insurance-card__text .h4 {
  font-family: "Helvetica Neue",Arial,sans-serif;
  font-weight: bold;
  color: rgba(0,0,0,.8509803922);
  font-size: 16px;
  margin-bottom: 1em;
  margin-top: 0;
}

@media (max-width: 768px) {
  .insurance-card__lower .insurance-card__text {
    padding: 10px;
  }
}

@media (max-width: 768px) {
  .insurance-card__lower {
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .insurance-card__lower {
    padding: 16px;
  }
}

.insurance-container__bg-image {
  right: 0px;
  top: 0px;
  position: absolute;
  max-width: 30%;
  height: auto;
}

@media (max-width: 576px) {
  .insurance-container__bg-image {
    display: none;
  }
}

@media (max-width: 768px) {
  .insurance-container__bg-image {
    max-width: 45%;
  }
}

.insurance-container__button-back .btn {
  color: rgba(0,0,0,.85);
  font-weight: bold;
}

@media only screen and (max-width: 768px) {
  .insurance-container__button-back .btn {
    margin-left: 14px;
  }
}

@media (max-width: 576px) {
  .insurance-container__button-back .btn {
    margin: 0 0 0 14px;
    padding: 13px 5px;
  }
}

.insurance-container__button-back .bi {
  color: rgba(0,0,0,.85);
  font-weight: bold;
}

.insurance-container__title {
  font-size: 30px;
  color: rgba(0,0,0,.85);
  font-weight: bold;
  margin-left: 10px;
}

@media (max-width: 992px) {
  .insurance-container__title {
    font-size: 24px;
  }
}

@media only screen and (max-width: 768px) {
  .insurance-container__title {
    margin-left: 24px;
  }
}

.honestConversations-hero__container {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 443px;
  background: #22263f;
}

@media (max-width: 768px) {
  .honestConversations-hero__container {
    height: 378px;
  }
}

@media (max-width: 576px) {
  .honestConversations-hero__container {
    height: 408px;
  }
}

.honestConversations-hero__worth {
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media (max-width: 576px) {
  .honestConversations-hero__worth {
    margin: -20px 0px 0px 0px;
    flex-direction: column;
    padding: 0 24px;
  }
}

.honestConversations-hero__worth--col {
  flex: 1;
  display: flex;
  flex-direction: column;
  order: 1;
  justify-content: space-between;
}

@media (max-width: 576px) {
  .honestConversations-hero__button {
    margin-top: 16px;
  }
}

@media (max-width: 768px) {
  .honestConversations-hero__title {
    margin-top: -5px;
  }
}

.honestConversations-hero__number {
  color: #8ccc33;
}

.honestConversations-hero__number--low {
  color: #ffbe1a;
}

.honestConversations-hero__subtitle {
  opacity: .7;
}

.honestConversations-hero__button {
  display: flex;
  flex-direction: column;
  order: 1;
  justify-content: space-between;
}

.honestConversations-score__tracker {
  width: 60%;
  margin-bottom: 14px;
}

@media (max-width: 576px) {
  .honestConversations-score__tracker {
    width: 100%;
    padding-bottom: 25px;
  }
}

.honestConversations-score__slider {
  width: 60%;
}

@media (max-width: 576px) {
  .honestConversations-score__slider {
    width: 100%;
  }
}

.honestConversations-score__range {
  padding-bottom: 20px;
  position: relative;
}

.honestConversations-score__range--low .honestConversations-score__range-label {
  color: #d8761c;
}

.honestConversations-score__range--low .honestConversations-score__range-progress {
  background: #d8761c;
}

.honestConversations-score__range--high .honestConversations-score__range-label {
  color: #1ea382;
}

.honestConversations-score__range--high .honestConversations-score__range-progress {
  background: #1ea382;
}

.honestConversations-score__range-progress {
  position: absolute;
  max-width: 100%;
  height: 2px;
  left: 0;
  transform: translateY(-50%);
  top: calc((24px + .5rem)/2);
}

.honestConversations-score__range-label {
  font-size: 40px;
  line-height: 40px;
  margin-bottom: 24px;
  font-weight: normal;
  color: #22263f;
}

@media (min-width: 992px) {
  .honestConversations-score__range-label {
    font-size: 48px;
    line-height: 48px;
  }
}

.honestConversations-score__range-min,
.honestConversations-score__range-max {
  position: absolute;
  bottom: 0;
}

.honestConversations-score__range-min {
  left: 0;
}

.honestConversations-score__range-max {
  right: 0;
}

.honestConversations-score__range .form-range::-webkit-slider-thumb:active,
.honestConversations-score__range .form-range::-webkit-slider-thumb:hover {
  border-width: 7px;
}

.honestConversations-score__range .form-range::-moz-range-thumb:active,
.honestConversations-score__range .form-range::-moz-range-thumb:hover {
  border-width: 7px;
}

.honestConversations-score__comment {
  width: 60%;
}

@media (max-width: 576px) {
  .honestConversations-score__comment {
    width: 100%;
  }
}

.honestConversations-score__comment--input {
  display: flex;
  margin-bottom: 16px;
  flex-direction: column;
  height: 148px;
  border: 1px solid #c2c2c2;
  border-radius: 4px;
}

@media (max-width: 576px) {
  .honestConversations-score__comment--input {
    height: 106px;
  }
}

.honestConversations-score__comment--text {
  padding: 8px 8px 0 8px;
  height: 130px;
  font-size: 14px;
  color: #231f20;
  border-radius: 4px;
  border: none;
  resize: none;
  outline: none;
}

@media (max-width: 576px) {
  .honestConversations-score__comment--text {
    height: 88px;
  }
}

.honestConversations-score__comment--limit {
  color: #8a8888;
  font-size: 14px;
  text-align: right;
  margin: 0;
  margin-right: 8px;
}

.honestConversations-score__comment--count {
  color: #aeb6be;
  left: 90%;
  position: relative;
}

@media (max-width: 576px) {
  .honestConversations-score__comment--count {
    left: 85%;
  }
}

.honestConversations-score__goal-header .accordion-button:not(.collapsed) {
  box-shadow: none;
}

.honestConversations-score__close {
  position: absolute;
  top: -80px;
  right: 0px;
  z-index: 1021;
}

.honestConversations-score__button--next {
  float: right;
}

.honestConversations-cards {
  background: #22263f;
  border: 0px;
  margin: 32px 0px -32px 0px;
}

@media (min-width: 768px) {
  .honestConversations-cards {
    margin: 32px -24px -32px -24px;
  }
}

.honestConversations-cards__container {
  padding: 16px 40px 40px 40px;
}

.honestConversations-cards__card,
.honestConversations-cards__card--inactive:not(.honestConversations-cards__card--dark-inactive),
.honestConversations-cards__card--dark,
.honestConversations-cards__card--dark-inactive {
  position: relative;
  background-color: #fff;
  color: #22263f;
  height: 138px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  opacity: 1;
  width: 100%;
}

@media (max-width: 768px) {
  .honestConversations-cards__card,
  .honestConversations-cards__card--inactive:not(.honestConversations-cards__card--dark-inactive),
  .honestConversations-cards__card--dark,
  .honestConversations-cards__card--dark-inactive {
    height: 96px;
    padding: 12px;
  }
}

@media (max-width: 576px) {
  .honestConversations-cards__card,
  .honestConversations-cards__card--inactive:not(.honestConversations-cards__card--dark-inactive),
  .honestConversations-cards__card--dark,
  .honestConversations-cards__card--dark-inactive {
    height: 180px;
  }
}

.honestConversations-cards__card--dark,
.honestConversations-cards__card--dark-inactive {
  background-color: #22263f;
  color: #fff;
  opacity: 1;
  box-shadow: 0 2px 4px 0 #b9bbbc;
}

.honestConversations-cards__card--dark-inactive {
  background-color: #e6ecf3;
  color: #22263f;
}

.honestConversations-cards__card--inactive:not(.honestConversations-cards__card--dark-inactive) {
  opacity: .5;
}

.honestConversations-cards__button {
  width: 100%;
  padding: 5px 12px 10px 12px;
}

.honestConversations-cards__text {
  flex: 1;
  display: flex;
  text-align: left;
  white-space: pre-wrap;
}

@media (max-width: 576px) {
  .honestConversations-cards__text {
    align-items: center;
    justify-content: center;
  }
}

.honestConversations-cards__text span {
  font-size: 18px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

@media (max-width: 768px) {
  .honestConversations-cards__text span {
    font-size: 14px;
    line-height: 18px;
  }
}

@media (max-width: 576px) {
  .honestConversations-cards__text span {
    display: block;
    max-height: none;
  }
}

.honestConversations-cards__score-container {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
}

.honestConversations-cards__score {
  text-align: center;
  margin: 0px 2px;
  background-color: #b9bbbc;
  font-size: 14px;
  display: inline-block;
  min-width: 22px;
  min-height: 22px;
  vertical-align: bottom;
}

@media (max-width: 768px) {
  .honestConversations-cards__score {
    padding: 1px 4px;
    font-size: 12px;
    line-height: 18px;
  }
}

.honestConversations-cards__logo {
  width: 18px;
}

@media (max-width: 768px) {
  .honestConversations-cards__logo {
    width: 20px;
  }
}

.honestConversations-cards__underline,
.honestConversations-cards__underline--inactive {
  width: 100%;
  height: 2px;
  right: 0px;
  left: 0px;
  background-color: #fff;
  position: absolute;
  bottom: -10px;
  float: left;
}

.honestConversations-cards__underline--inactive {
  display: none;
}

.honestConversations-cards__underline--dark {
  background-color: #22263f;
}

@media (max-width: 576px) {
  .honestConversations-cards__underline,
  .honestConversations-cards__underline--inactive {
    display: none;
  }
}

@media (max-width: 576px) {
  .priority-graphs__chart {
    margin-top: 32px;
  }
}

.priority-graphs__chart section:last-child {
  margin-bottom: 48px;
}

@media (max-width: 576px) {
  .priority-graphs__chart section:last-child {
    margin-bottom: 40px;
  }
}

.priority-graphs-w {
  padding: 20px;
}

.priority-graphs__graph-w {
  width: 100%;
  height: 310px;
}

.priority-graphs__comment {
  display: inline-block;
  line-height: 22px;
  font-size: 14px;
  color: #22263f;
}

.priority-graphs__title {
  display: flex;
  flex-direction: row;
  margin-bottom: 21px;
  align-items: flex-start;
  justify-content: flex-start;
}

@media (max-width: 576px) {
  .priority-graphs__title {
    flex-direction: column;
  }
}

.priority-graphs__title-names {
  display: flex;
  width: auto;
  margin-left: 26px;
}

.priority-graphs__legend {
  margin: 5px 5px 0 5px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.priority-graphs__line {
  margin-top: 8px;
  margin-right: 5px;
  height: 4px;
  width: 24px;
}

.priority-graphs__line--primary {
  border-top: solid 2px #cabeb3;
}

.priority-graphs__line--primary:before,
.priority-graphs__line--primary:after {
  background: #cabeb3;
}

.priority-graphs__line--secondary {
  border-top: solid 2px #989dc2;
}

.priority-graphs__line--secondary:before,
.priority-graphs__line--secondary:after {
  background: #989dc2;
}

.priority-graphs__line:before,
.priority-graphs__line:after {
  content: "";
  width: 4px;
  height: 4px;
  border-radius: 50%;
  float: left;
  margin-top: -3px;
}

.priority-graphs__line:after {
  float: right;
}

.personal-priorities__card {
  background-color: #f2f5fa;
  padding: 24px;
  position: relative;
}

@media (min-width: 768px) {
  .personal-priorities__card {
    padding: 40px;
  }
}

.personal-priorities__carousel-cont {
  padding: 16px 24px 40px 24px;
}

@media (min-width: 768px) {
  .personal-priorities__carousel-cont {
    padding: 16px 0px 40px;
    margin: 0px -8px;
  }
}

.personal-priorities__priority-button {
  padding: 0px 0px 10px;
}

@media (min-width: 576px) {
  .personal-priorities__priority-button {
    padding: 0px 8px 10px 8px;
  }
}

.personal-priorities__sub-title {
  font-size: 18px;
}

@media (min-width: 992px) {
  .personal-priorities__sub-title {
    font-size: 24px;
  }
}

.personal-priorities__bg-image {
  right: 0px;
  top: 0px;
  position: absolute;
  max-width: 30%;
  height: auto;
}

@media (max-width: 576px) {
  .personal-priorities__bg-image {
    display: none;
  }
}

@media (max-width: 768px) {
  .personal-priorities__bg-image {
    max-width: 45%;
  }
}

@media (max-width: 768px) {
  .personal-priorities__title {
    margin-left: 24px;
  }
}

.hc-null {
  margin-top: 60px;
  z-index: 0;
}

@media (max-width: 576px) {
  .hc-null {
    margin-top: 36px;
    padding: 0 16px;
  }
}

.hc-null__bg-image,
.hc-null__bg-image--upper,
.hc-null__bg-image--lower {
  position: absolute;
  right: 0;
}

.hc-null__bg-image--lower {
  bottom: 140px;
  max-width: 60%;
  max-height: 80%;
  z-index: 0;
}

@media (max-width: 576px) {
  .hc-null__bg-image--lower {
    bottom: 160px;
  }
}

.hc-null__bg-image--upper {
  top: 0;
  max-width: 40%;
}

@media (max-width: 576px) {
  .hc-null__bg-image--upper {
    display: none;
  }
}

.hc-null__main-title {
  background: rgba(0,0,0,0);
  border: none;
  padding: 0;
}

.hc-null__description {
  color: #676879;
  font-size: 16px;
}

.hc-null h4,
.hc-null .h4 {
  font-size: 18px;
}

.hc-null h1,
.hc-null .h1 {
  font-size: 46px;
}

@media (max-width: 576px) {
  .hc-null h1,
  .hc-null .h1 {
    font-size: 36px;
  }
}

.hc-null h1,
.hc-null .h1,
.hc-null h4,
.hc-null .h4 {
  color: #22263f;
  font-weight: normal;
}

.hc-null__descriptions {
  position: relative;
  z-index: 1;
  width: 50%;
}

@media (max-width: 576px) {
  .hc-null__descriptions {
    width: 100%;
  }
}

.hc-null-progress {
  z-index: 1;
}

.hc-null-progress__title,
.hc-null-progress__description {
  width: 55%;
}

@media (max-width: 768px) {
  .hc-null-progress__title,
  .hc-null-progress__description {
    width: 80%;
  }
}

@media (max-width: 576px) {
  .hc-null-progress__title,
  .hc-null-progress__description {
    padding: 0 24px;
    width: 100%;
  }
}

.hc-null-progress__title {
  color: #22263f;
  font-size: 46px;
  font-weight: normal;
}

@media (max-width: 576px) {
  .hc-null-progress__title {
    font-size: 24px;
  }
}

.hc-null-progress__description {
  margin-bottom: 48px;
}

@media (max-width: 576px) {
  .hc-null-progress__description {
    margin-bottom: 32px;
  }
}

.hc-null-priorities {
  margin-bottom: 48px;
}

@media (max-width: 576px) {
  .hc-null-priorities {
    margin-bottom: 24px;
  }
}

@media (max-width: 576px) {
  .hc-null-priorities__title {
    display: none;
  }
}

.hc-null-priorities__title--mobile {
  color: #22263f;
  display: none;
  font-size: 16px;
  margin-bottom: 36px;
}

@media (max-width: 576px) {
  .hc-null-priorities__title--mobile {
    display: block;
  }
}

.hc-null-priorities__comment {
  border-top: 1px solid #e6ecf3;
  color: #22263f;
  padding-top: 32px;
  margin-top: 10px;
}

@media (max-width: 576px) {
  .hc-null-priorities__comment {
    border: none;
  }
}

.hc-null-priorities__comment-title {
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 12px;
}

@media (max-width: 576px) {
  .hc-null-priorities__comment-title {
    font-size: 16px;
    line-height: 24px;
  }
}

.hc-null-priorities__comment-text {
  font-size: 14px;
}

@media (max-width: 576px) {
  .hc-null-status__button {
    margin-top: 20px;
    width: 100%;
  }
}

.hc-null-status__value,
.hc-null-status__value--date {
  color: #22263f;
  font-size: 16px;
  margin-bottom: 5px;
}

@media (max-width: 576px) {
  .hc-null-status__value,
  .hc-null-status__value--date {
    font-size: 14px;
    order: 2;
  }
}

.hc-null-status__value--date {
  min-width: 84px;
}

.hc-null-status__label {
  font-size: 14px;
}

@media (max-width: 576px) {
  .hc-null-status__label {
    font-size: 12px;
    order: 1;
  }
}

.messages-header {
  flex-wrap: nowrap;
  padding-bottom: 10px;
}

@media (max-width: 576px) {
  .messages-header {
    width: 100%;
    padding: 0 24px 24px 24px;
    display: flex;
    flex-wrap: wrap;
  }
}

.messages-header-left {
  width: 100%;
}

.messages-header__title {
  font-size: 30px;
  line-height: 36px;
  letter-spacing: .005em;
  color: #22263f;
}

@media (max-width: 576px) {
  .messages-header__title {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: .005em;
    color: #22263f;
  }
}

.messages-header__messages-search {
  width: 400px;
  padding-left: 24px;
}

@media (max-width: 768px) {
  .messages-header__messages-search {
    width: 270px;
  }
}

@media (max-width: 576px) {
  .messages-header__messages-search {
    width: 100%;
    padding-left: 0;
  }
}

.messages-header__compose-btn {
  width: 180px;
  font-size: 18px;
  line-height: 130%;
  letter-spacing: .005em;
}

@media (max-width: 576px) {
  .messages-header__compose-btn {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .message-body__container {
    margin-top: -52px;
  }
}

.message-body__btn-back {
  background: rgba(0,0,0,0);
  border: none;
  color: #22263f;
  text-align: start;
  margin-bottom: 20px;
}

.message-body__btn-back:hover {
  color: #22263f;
  background: rgba(0,0,0,0);
  border: none;
}

.message-body__title {
  word-break: normal !important;
}

.message-body-w {
  flex: 3;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  padding-left: 40px;
  background-color: #fff;
  min-width: 1022px;
}

@media only screen and (max-width: 1280px) {
  .message-body-w {
    min-width: 656px;
  }
}

@media (max-width: 768px) {
  .message-body-w {
    min-width: 320px;
    padding-left: 15px;
    margin-top: 16px;
  }
}

.message-body__list {
  margin-top: 10px;
  overflow-y: auto;
  max-height: 600px;
  flex: 1;
}

@media (max-width: 576px) {
  .message-body__list {
    max-height: none;
  }
}

@media (max-width: 768px) {
  .message-body__list {
    max-height: none;
  }
}

.message-body__list-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.message-body-item-w {
  display: flex;
  flex-direction: row;
  padding: 10px;
}

@media (min-width: 1200px) {
  .message-body__modal .modal-dialog {
    max-width: 870px;
    width: auto;
  }
}

.message-body__video {
  cursor: pointer;
}

.message-body__video-thumbnail {
  margin-top: 24px;
  width: 320px;
}

.message-body__video-thumbnail img {
  margin-top: 24px;
  width: 320px;
}

.message-body__video-thumbnail-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 320px;
}

@media (max-width: 768px) {
  .message-body__video-thumbnail-wrapper {
    max-width: 270px;
  }
}

.message-body__video-thumbnail-wrapper:after {
  content: " ";
  width: 50px;
  height: 50px;
  background: url("../images/icons/play_hover_Icon.svg") no-repeat 0 0;
  background-size: 50px 50px;
  position: absolute;
}

.message-body__video-media {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px 0px;
}

@media (min-width: 1200px) {
  .message-body__video-media {
    padding: 40px 25px;
  }
}

.message-body-heading-w {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
  justify-content: space-between;
}

.message-body-heading-container {
  margin-left: 24px;
  width: 100%;
}

@media (max-width: 768px) {
  .message-body-heading-container {
    margin-left: 16px;
  }
}

.message-body-heading__name {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: .005em;
  color: #676879;
}

.message-body-heading__date {
  font-size: 12px;
  line-height: 16px;
  margin-right: 40px;
  color: #676879;
}

@media (max-width: 768px) {
  .message-body-heading__date {
    margin-right: 14px;
  }
}

.message-body-heading__dot {
  font-size: 12px;
  line-height: 16px;
  color: #676879;
  margin: 0 5px;
}

.message-body__message {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: .005em;
  color: #676879;
  padding-bottom: 20px;
  width: 95%;
}

@media (max-width: 768px) {
  .message-body__message {
    width: 107%;
    margin-left: -48px;
  }
}

@media (max-width: 576px) {
  .message-body__message {
    width: 110%;
  }
}

.message-body__divider-w {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.message-body__divider-line {
  height: 1px;
  background-color: #aeb6be;
  flex: 1;
  margin: 10px;
  border: 1px solid #e6ecf3;
}

.message-body__divider-date {
  font-size: 14px;
  line-height: 22px;
  text-align: right;
  letter-spacing: .005em;
  color: #676879;
}

.message-body__video-loader {
  background-color: #2178c4;
  margin-top: 10px;
  width: 385px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .message-body__video-loader {
    width: 270px;
  }
}

.message-body__thumbnail-loader {
  background-color: #2178c4;
  margin-top: 10px;
  width: 320px;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.message-body__thank-you {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: .005em;
  color: #676879;
  margin-top: 40px;
  margin-bottom: 80px;
}

@media (max-width: 768px) {
  .message-body__thank-you {
    margin-left: 24px;
    margin-right: 24px;
    margin-top: 16px;
  }
}

.message-reply-w {
  margin-left: 8px;
  margin-right: 40px;
  width: 100%;
}

@media (max-width: 768px) {
  .message-reply-w {
    margin-right: 24px;
  }
}

.message-reply-field-w {
  flex: 1;
  align-items: center;
  display: flex;
  flex-direction: row;
  color: #22263f;
  background-color: #fff;
  overflow: hidden;
  margin: 0px;
}

.message-reply-field__icon:hover {
  cursor: pointer;
}

.message-reply-field__input {
  font-size: 16px;
  line-height: 27px;
  min-height: 50px;
  color: #22263f;
  padding: 0 16px;
  margin: 0px;
  background: #fff;
  box-sizing: border-box;
  border-radius: 4px;
}

.message-reply-field__input:focus {
  color: #2178c4;
}

.message-reply-field__input:focus.is-invalid,
.was-validated .message-reply-field__input:invalid:focus {
  box-shadow: none;
}

.message-reply-field__input:not(:focus) {
  color: inherit;
}

.message-reply-field__button {
  font-size: 16px;
  margin-top: 24px;
  margin-bottom: 40px;
  border-radius: 4px;
}

@media (max-width: 768px) {
  .message-reply-field__button {
    width: 100%;
  }
}

.message-reply-field__label {
  color: #22263f;
}

.message-reply__container {
  margin-top: 14px;
  padding-left: 10px;
  box-sizing: border-box;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .message-reply__container {
    padding-left: 0px;
  }
}

.messages-table {
  margin-bottom: 120px;
}

.messages-table__table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
}

@media (max-width: 768px) {
  .messages-table {
    margin-bottom: 80px;
  }
}

@media (max-width: 576px) {
  .messages-table {
    margin-bottom: 40px;
  }
}

.messages-table__cell-status {
  display: flex;
  justify-content: center;
  width: 5%;
}

@media (max-width: 768px) {
  .messages-table__cell-status {
    width: 6%;
  }
}

@media (max-width: 576px) {
  .messages-table__cell-status {
    width: 10%;
  }
}

.messages-table__cell-name {
  width: 81%;
}

@media (max-width: 1200px) {
  .messages-table__cell-name {
    width: 80%;
  }
}

@media (max-width: 992px) {
  .messages-table__cell-name {
    width: 79%;
  }
}

@media (max-width: 768px) {
  .messages-table__cell-name {
    width: 73%;
  }
}

@media (max-width: 576px) {
  .messages-table__cell-name {
    width: 60%;
  }
}

.messages-table__cell-name-text {
  width: 77%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  letter-spacing: .005em;
  color: #22263f;
}

@media (max-width: 768px) {
  .messages-table__cell-name-text {
    width: 90%;
  }
}

@media (max-width: 576px) {
  .messages-table__cell-name-text {
    width: 100%;
  }
}

.messages-table__cell-video {
  display: flex;
  justify-content: center;
  width: 5%;
}

@media (max-width: 576px) {
  .messages-table__cell-video {
    display: none;
  }
}

.messages-table__cell-date {
  max-width: 120px;
  width: 9%;
  font-size: 14px;
  text-align: right;
  letter-spacing: .005em;
  color: #676879;
}

@media (max-width: 1200px) {
  .messages-table__cell-date {
    width: 10%;
  }
}

@media (max-width: 992px) {
  .messages-table__cell-date {
    width: 12%;
  }
}

@media (max-width: 768px) {
  .messages-table__cell-date {
    width: 15%;
  }
}

.messages-table__cell-date-mobile-w {
  display: flex;
  flex-direction: column;
}

@media (max-width: 576px) {
  .messages-table__cell-date--no-mobile {
    display: none;
  }
}

.messages-table__cell-date--mobile {
  display: none;
}

@media (max-width: 576px) {
  .messages-table__cell-date--mobile {
    display: table-cell;
    width: 30%;
    max-width: none;
  }
}

.messages-table__dot {
  width: 8px;
  height: 8px;
  background: #068667;
  border-radius: 50%;
}

.messages-table td {
  padding: 32px 8px;
}

@media (max-width: 576px) {
  .messages-table td {
    padding: 16px 4px;
  }
}

.messages-table tr {
  cursor: pointer;
  position: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.messages-table tr:not(last-child) {
  border-bottom: solid 1px #e6ecf3;
}

.messages-table tr:hover {
  background-color: rgba(33,120,196,.1019607843);
}

.add-message__card {
  max-width: 588px;
  border: none;
}

.add-message__input {
  min-height: 50px;
}

.add-message__input:focus {
  color: #2178c4;
}

.add-message__input:focus.is-invalid,
.was-validated .add-message__input:invalid:focus {
  box-shadow: none;
}

.add-message__input:not(:focus) {
  color: inherit;
}

.add-message__textarea {
  min-height: 50px;
  height: 120px;
}

.add-message__textarea:focus {
  color: #2178c4;
}

.add-message__title-heading {
  font-weight: 400;
  font-size: 46px;
  letter-spacing: .005em;
  color: #22263f;
}

@media (max-width: 576px) {
  .add-message__title-heading {
    font-size: 28px;
  }
}

.add-message__title-subheading {
  font-size: 16px;
  letter-spacing: .005em;
  color: #22263f;
  margin-bottom: 20px;
}

.add-message__button {
  margin: 20px 0 40px;
}

.add-message__disclaimer {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: .005em;
  color: #676879;
}

.messages-null__w {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 80px 0px;
}

@media (max-width: 768px) {
  .messages-null__w {
    padding: 40px 0px;
  }
}

@media (max-width: 768px) {
  .messages-null__w {
    padding: 20px 0px;
  }
}

.messages-null__body-header {
  font-size: 24px;
  color: #22263f;
  text-align: center;
}

@media (max-width: 576px) {
  .messages-null__body-header {
    font-size: 18px;
  }
}

.messages-null__body-description {
  font-size: 16px;
  color: #676879;
  padding-top: 15px;
  text-align: center;
}

@media (max-width: 576px) {
  .messages-null__body-description {
    font-size: 14px;
  }
}

.disclosures {
  display: flex;
  max-width: 1272px;
  width: 100%;
  padding: 0px 20px;
  margin: auto;
}

.disclosures__sidebar {
  margin-top: 230px;
  min-width: 187px;
}

@media (max-width: 768px) {
  .disclosures__sidebar {
    margin-top: 260px;
  }
}

@media (max-width: 576px) {
  .disclosures__footer {
    padding: 0 20px;
  }
}

.disclosures__back {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: fixed;
  min-width: 187px;
  top: 140px;
  font-size: 14px;
  color: #231f20;
  mix-blend-mode: normal;
  opacity: .6;
}

.disclosures__nav {
  position: fixed;
}

.disclosures__nav-link {
  cursor: pointer;
  display: block;
  font-size: 20px;
  margin-bottom: 8px;
  font-style: normal;
  font-weight: normal;
  color: #22263f;
}

.disclosures__nav-link--align-end {
  display: flex;
  align-items: flex-end;
}

.disclosures__nav-link:hover {
  color: #40a9ff;
}

.disclosures__body {
  margin: 80px 0px 0px 80px;
  color: #22263f;
}

@media (max-width: 768px) {
  .disclosures__body {
    margin: 40px 0px 0px 40px;
  }
}

.disclosures__page-title {
  color: #22263f;
  font-size: 46px;
  padding: 6px 0px 0px 0px;
  margin: 0px;
  font-style: normal;
  font-weight: normal;
}

.disclosures__section-title {
  font-size: 30px;
  padding: 64px 0px 24px 0px;
  margin: 0px;
  text-transform: none;
  font-style: normal;
  font-weight: normal;
  color: #22263f;
}

.disclosures__section-subtitle {
  margin: 24px 0px;
  text-transform: none;
  font-style: normal;
  font-weight: normal;
  color: #22263f;
}

.disclosures__section-subtitle--large {
  font-size: 24px;
  line-height: 32px;
}

.disclosures__text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #22263f;
}

.disclosures__text--footer {
  font-size: 11px;
  color: #676879;
  line-height: 16px;
  font-style: normal;
  font-weight: normal;
  margin: 0px;
}

.disclosures__text p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #22263f;
}

.disclosures__text a {
  text-decoration: underline;
}

.disclosures__link {
  display: block;
  font-size: 11px;
  line-height: 13px;
  color: #676879;
  font-style: normal;
  font-weight: normal;
  text-decoration: underline;
  margin-bottom: 16px;
}

.feesum-table__link {
  font-size: 14px;
  color: #231f20;
  margin-bottom: 40px;
}

.feesum-table__link a {
  text-decoration: underline;
  color: #231f20;
}

.feesum-table__link a:hover {
  text-decoration: underline;
  color: #231f20;
}

.feesum-table__btn-w {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}

.feesum-table__back-btn {
  color: #231f20;
  font-size: 14px;
  display: flex;
  justify-self: center;
  align-items: center;
}

.feesum-table__back-btn:hover {
  color: #231f20;
}

.feesum-table__back-btn:hover img {
  left: -5px;
}

.feesum-table__back-btn img {
  position: relative;
  transform: rotate(180deg);
  max-width: 12px;
  width: 100%;
  margin-right: 5px;
  left: 0px;
  transition: left 300ms;
}

.feesum-table__print-btn {
  color: #255eba;
  font-size: 12px;
  display: flex;
  justify-self: center;
  align-items: center;
}

.feesum-table__print-btn img {
  max-width: 20px;
  width: 100%;
  margin-right: 5px;
}

.feesum-table__print-btn:hover {
  cursor: pointer;
}

.feesum-table__w {
  width: 100%;
  border: 1px solid #e9eaec;
  border-radius: 6px;
  overflow: hidden;
  margin-top: 10px;
  margin-bottom: 40px;
}

.feesum-table__header {
  display: flex;
  background: #fafafa;
  font-size: 12px;
  color: #231f20;
  line-height: 1;
  padding: 10px 15px;
  border-bottom: 1px solid #e9eaec;
  padding-right: 30px;
}

.feesum-table__content-w {
  max-height: 750px;
  overflow: auto;
}

.feesum-table__item-w {
  display: flex;
  font-size: 14px;
  color: #231f20;
  padding: 20px 15px;
  line-height: 1;
  background-color: #fff;
}

.feesum-table__item-w:not(:last-child) {
  border-bottom: 1px solid #e9eaec;
}

.feesum-table__col-name {
  flex: 4;
  font-weight: 500;
}

.feesum-table__col-manager {
  flex: 2;
  font-weight: 500;
}

.feesum-table__col-info {
  flex: 1;
  font-weight: 300;
}

.feesum-table__col-info:not(:last-child) {
  margin-right: 5px;
}

.feesum-table__col-info--bold {
  font-weight: 500;
}

.feesum-table__footer_text {
  color: #8a8888;
  font-size: 11px;
  padding-left: 20px;
  padding-right: 8px;
}

.feesum-table__footer_text ol {
  padding: 0;
}

@media all {
  .feesum-pdf-header {
    display: none;
  }

  .feesum-pdf-header__logo {
    display: none;
  }

  .feesum-pdf-header__text {
    display: none;
  }
}

@media print {
@page {
    size: auto;
}

  html,
  body {
    background-color: #fff;
    margin: 0px;
  }

  #header {
    display: none;
  }

  .footer__links-w {
    display: none;
  }

  p {
    font-size: 10pt;
  }

  .feesum-pdf-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .feesum-pdf-header__logo {
    display: block;
    width: 150pt;
    height: 50pt;
  }

  .feesum-pdf-header__text {
    display: block;
    color: #ccc;
    font-size: 16pt;
  }

  .wrapper__main {
    display: block;
  }

  .nav-menu {
    display: none;
  }

  .nav-menu.nav-menu--open {
    display: none;
  }

  .nav-open .main-container {
    width: 100%;
    margin-left: 0px;
  }

  .main-container,
  .main-container-inner {
    width: 100%;
    margin-left: 0px;
    padding: 0px;
    display: block;
    top: 0px;
    max-width: none;
  }

  .feesum-table__btn-w {
    display: none;
  }

  .feesum-table__w {
    height: 100%;
    overflow: auto;
    border: none;
  }

  .feesum-table__content-w {
    max-height: none;
  }

  .feesum-table__item-w {
    page-break-inside: avoid;
    padding: 14pt 10pt;
  }

  .feesum-table__item-w * {
    page-break-inside: avoid;
    font-size: 10pt;
    font-weight: normal;
  }

  .feesum-table__item-w:last-child {
    border-bottom: 1px solid #e9eaec;
  }

  .feesum-table__footer_text {
    page-break-inside: avoid;
  }

  .feesum-table__footer_text ol {
    page-break-inside: avoid;
  }

  .feesum-table__footer_text ol li {
    page-break-inside: avoid;
  }

  .disclosures__footer--general {
    display: none;
  }
}

.errors {
  margin-top: 48px;
}

.errors__container--mx {
  margin-left: 34px;
  margin-right: 34px;
}

.errors__heading-text {
  margin-top: 68px;
}

.errors__heading-text--lg {
  font-size: 46px;
  color: rgba(0,0,0,.65);
  font-weight: 200;
}

.errors__body-text {
  margin-top: 68px;
}

.errors__body-text--sm {
  font-size: 18px;
  color: rgba(0,0,0,.65);
}

.maintenance__wrapper {
  background: url("../images/backgroundlogo.png") fixed;
  background-repeat: no-repeat;
  background-color: #e6ecf3;
  background-position: right -25px top -50px;
  background-size: 385px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: absolute;
  left: 0;
  width: 100%;
}

@media (max-width: 768px) {
  .maintenance__wrapper {
    background-position: right -130px top -50px;
  }
}

.maintenance__container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 180px;
  margin-left: 150px;
  padding: 0 10px 10px;
}

@media (max-width: 768px) {
  .maintenance__container {
    margin-right: 0;
    margin-left: 10%;
  }
}

.maintenance__content {
  text-align: left;
  margin-right: 300px;
}

@media (max-width: 768px) {
  .maintenance__content {
    margin-top: 55px;
    width: 75%;
  }
}

.maintenance__title {
  color: #2178c4;
  margin-bottom: 45px;
  font-size: 30px;
}

@media (max-width: 768px) {
  .maintenance__title {
    font-size: 24px;
  }
}

.maintenance__body p {
  font-size: 16px;
  margin: 16px 0;
}

@media (max-width: 768px) {
  .maintenance__body p {
    font-size: 14px;
  }
}

.maintenance__body a {
  color: #2178c4;
  text-decoration: none;
}

.maintenance__footer {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: auto;
  font-size: 12px;
  color: #8a8888;
}

.maintenance__footer-links {
  display: flex;
}

.maintenance__footer-links a {
  margin-top: 5px;
  text-decoration: none;
  color: #678679;
}

.maintenance__footer-seperator {
  margin: 5px 2px;
}

@media (max-width: 768px) {
  .maintenance__footer {
    width: 80%;
  }
}

.toast__container {
  width: 100%;
  border-top-color: #e7edf3;
  position: absolute;
  bottom: 0;
}

.toast__content {
  padding: 0px 32px 32px 32px;
}

.toast__content-icon--success {
  color: #068667;
}

.toast__content-icon--error {
  color: #d8761c;
}

.toast__content-text {
  color: #22263f;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: .005em;
  white-space: pre-wrap;
}

.toast__content-action-btn {
  padding-top: 20px;
}

.toasts__container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2;
}

@media (min-width: 768px) {
  .toasts__container {
    bottom: 60px;
    left: 60px;
    width: 392px;
  }
}

.text-input {
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 8px;
}

.text-input.is-invalid:focus {
  box-shadow: none;
}

.text-input.is-valid:focus {
  box-shadow: none;
}

.text-input__label {
  line-height: 24px;
}

.text-input__errors {
  font-size: 14px;
  display: inline-block;
  padding-top: 2px;
}

.text-input__errors div {
  display: flex;
  flex-direction: column;
}

.text-input__errors span {
  display: inline-block;
  margin-left: 5px;
  line-height: 22px;
}

.donut-chart__label {
  fill: #fff;
  font-size: 16px;
}

@media (max-width: 768px) {
  .donut-chart__label {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .donut-chart__label {
    font-size: 11px;
  }
}

.donut-chart__label-value {
  font-size: 24px;
}

@media (max-width: 768px) {
  .donut-chart__label-value {
    font-size: 16px;
  }
}

@media (max-width: 576px) {
  .donut-chart__label-value {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .donut-chart__label--default {
    display: none;
  }
}

.HorizontalBarChart__container {
  position: relative;
}

.HorizontalBarChart__chart-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
}

.HorizontalBarChart__bargraph {
  height: 10px;
  position: relative;
}

.HorizontalBarChart__graph-name {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  font-family: "Helvetica Neue",Arial,sans-serif;
}

.HorizontalBarChart__tooltip-content {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  color: #676879;
  padding: 24px;
}

.hero {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
}

.hero-gradient {
  background-image: linear-gradient(84.17deg, #2178c4, #236fb5, #2466a7, #255d98, #24548a, #264d7f, #264774, #264069, #27395e, #263354, #242c49, #22263f);
  mix-blend-mode: normal;
}

.dashboard-row {
  gap: 10px;
  margin-bottom: 10px;
}

.dashboard-row:empty {
  display: none;
}

.dashboard-tile {
  flex: 1 1 auto;
  min-height: 100%;
  width: calc(50% - 5px);
}

@media (max-width: 768px) {
  .dashboard-tile {
    width: 100%;
  }
}

.dashboard-tile__card,
.dashboard-tile__card--no-content {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 32px;
}

@media (max-width: 576px) {
  .dashboard-tile__card,
  .dashboard-tile__card--no-content {
    padding: 32px 24px;
  }
}

@media (max-width: 576px) {
  .dashboard-tile__card,
  .dashboard-tile__card--no-content {
    padding: 32px 24px;
  }
}

.dashboard-tile__card--no-content {
  justify-content: flex-start;
}

.dashboard-tile__header {
  margin-bottom: 32px;
}

.dashboard-tile__header h4,
.dashboard-tile__header .h4 {
  color: #22263f;
  font-size: 18px;
  line-height: 22px;
  font-weight: normal;
  margin-bottom: 0;
}

@media (max-width: 576px) {
  .dashboard-tile__header h4,
  .dashboard-tile__header .h4 {
    font-size: 16px;
    line-height: 20px;
  }
}

.dashboard-tile__content {
  margin-bottom: 32px;
}

.dashboard-tile__links-link {
  margin-right: 32px;
}

.dashboard-tile__links-link-text {
  color: #2178c4;
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
}

.dashboard-tile__icon {
  color: #22263f;
  font-size: 1rem;
  margin-left: .5rem;
  text-shadow: 0 0 1px #676879;
}

.dashboard-row-tile__card {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 32px;
}

@media (max-width: 576px) {
  .dashboard-row-tile__card {
    padding: 32px 24px;
  }
}

.dashboard-row-tile__header {
  height: 52px;
  border-bottom: 1px solid #e6ecf3;
}

.dashboard-row-tile__header h4,
.dashboard-row-tile__header .h4 {
  color: #22263f;
  font-size: 18px;
  line-height: 22px;
  font-weight: normal;
  margin-bottom: 0;
}

@media (max-width: 576px) {
  .dashboard-row-tile__header h4,
  .dashboard-row-tile__header .h4 {
    font-size: 16px;
    line-height: 20px;
  }
}

.dashboard-row-tile__content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start;
}

.dashboard-row-tile__links {
  border-top: 1px solid #e6ecf3;
}

.dashboard-row-tile__links-link {
  margin-top: 32px;
}

.dashboard-tile-null {
  height: 100%;
}

.dashboard-tile-null__content {
  text-align: center;
}

.dashboard-tile-null__content-title {
  color: #22263f;
  font-size: 24px;
  font-weight: normal;
  line-height: 32px;
}

.dashboard-tile-null__content-desc {
  color: #676879;
  font-size: 16px;
  line-height: 24px;
}

.dashboard-tile-null__content-warning {
  align-items: center;
  color: #d8761c;
  display: flex;
  font-size: 16px;
  justify-content: center;
  line-height: 16px;
  margin: 48px 0 24px;
}

.dashboard-tile-null__content-warning>svg {
  fill: #d8761c;
}

.tou-modal .modal-content {
  max-width: 600px;
  margin: 0px auto;
}

.tou-modal__header {
  border-bottom: none;
  padding: 32px 32px 0px 32px;
}

.tou-modal__title {
  color: #22263f;
  font-size: 24px;
  font-weight: normal;
  margin-top: 10px;
  margin-bottom: 0;
  padding: 0;
}

@media (max-width: 576px) {
  .tou-modal__title {
    font-size: 18px;
  }
}

.tou-modal__checkbox .form-check-input {
  margin-top: 8px;
  height: 24px;
  width: 24px;
}

.tou-modal__body {
  padding: 0 32px 32px;
}

.tou-modal__body-text {
  padding: 25px 0;
  font-size: 16px;
  line-height: 26px;
  color: #22263f;
}

@media (max-width: 768px) {
  .tou-modal__body-text {
    margin-top: 30px;
  }
}

.tou-modal__buttons {
  display: flex;
  margin-top: 40px;
  position: relative;
}

.tou-modal__save-button {
  padding: 8px 32px;
}

.tou-modal__tooltip .tooltip-inner {
  max-width: 300px;
  padding: 24px;
}

.legacy-title-container {
  height: 24px;
  margin: 24px 0;
  display: flex;
  align-items: center;
}

@media (max-width: 576px) {
  .legacy-title-container {
    margin-left: 16px;
  }
}

.legacy-title-container__text {
  display: inline-block;
  margin: 0;
  color: #22263f;
  font-family: "Helvetica Neue",Arial,sans-serif;
  font-size: 30px;
  line-height: 34px;
}

.legacy-title-container__text--registerSymbol {
  display: inline-block;
  margin: 0;
  color: #22263f;
  font-family: "Helvetica Neue",Arial,sans-serif;
  font-size: 30px;
  line-height: 34px;
}

.legacy-title-container__icon {
  vertical-align: baseline;
  margin-right: 8px;
  max-width: 24px;
}

.legacy-title-container__icon-filter {
  margin-left: 8px;
  padding: 6px;
  cursor: pointer;
}

.main-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 48px;
  min-height: 100vh;
  width: 100%;
  margin: 0 auto;
  margin-left: 0;
  transition: all 300ms;
  background-color: #e6ecf3;
}

.main-container-fullscreen {
  background-color: #fff;
  border-top: solid 1px #aeb6be;
}

.nav-open .main-container {
  width: calc(100% - 240px);
  margin-left: 240px;
}

.main-container-inner {
  display: flex;
  flex-direction: column;
  max-width: 1070px;
  min-width: 600px;
  padding: 0 16px;
  width: 100%;
  margin: 0 auto 0px;
}

@media (max-width: 768px) {
  .main-container-inner {
    min-width: auto;
    padding: 0 25px;
  }
}

@media (max-width: 576px) {
  .main-container-inner {
    min-width: auto;
    padding: 0;
  }
}

.footer__w {
  background: #22263f;
  z-index: 1;
}

.footer__links-w {
  width: 100%;
  max-width: 1070px;
  margin: 0 auto;
  padding: 0 16px 40px 16px;
}

.footer__links-container {
  width: 65%;
  padding-top: 25px;
  display: flex;
  font-style: normal;
  font-weight: normal;
  color: #e6ecf3;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: .005em;
  margin: 0;
}

.footer__links-container:not(:last-child) {
  padding-top: 40px;
}

@media (max-width: 768px) {
  .footer__links-container {
    width: 85%;
  }
}

@media (max-width: 576px) {
  .footer__links-container {
    width: 100%;
  }
}

.footer__links-link {
  line-height: 1;
  font-size: 11px;
  line-height: 16px;
  color: #e6ecf3;
}

.footer__links-link:hover {
  color: #625f60;
}

.footer__links-link:not(:last-child) {
  margin-right: 30px;
}

.footer__links-logo {
  max-width: 100px;
  margin-right: 5px;
}

.footer__links-rights {
  text-align: center;
  line-height: 1;
  margin-left: 5px;
  font-size: 11px;
}

.legacy-mainTile {
  margin: 0;
}

.legacy-mainTile__header {
  height: 50px;
  border-radius: 6px 6px 0 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
}

.legacy-mainTile__header__title {
  margin: 0 16px;
  padding: 0;
  height: 24px;
  color: #22263f;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

@media (max-width: 768px) {
  .legacy-mainTile__header__title {
    font-size: 10px;
  }
}

.legacy-tile-header {
  position: relative;
  width: 100%;
  padding: 20px 40px 15px;
  display: flex;
  flex-wrap: wrap;
}

.legacy-tile-header-w {
  display: flex;
  align-items: center;
}

.legacy-tile-header__right-w {
  display: flex;
  align-items: center;
  color: #22263f;
  font-weight: 400;
}

.legacy-link-tile-header {
  position: relative;
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  border: none;
}

.legacy-tile-title {
  display: flex;
  align-items: center;
  float: left;
  margin-right: 16px;
}

.legacy-tile-title-text {
  color: #22263f;
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  line-height: 24px;
}

.legacy-title-icon {
  display: flex;
  align-items: center;
  width: 20px;
  margin-right: 10px;
}

.legacy-title-icon img {
  display: inline-block;
  width: 100%;
}

.legacy-tile-total {
  flex: 1;
  text-align: right;
  font-size: 18px;
  line-height: 22px;
  color: #22263f;
}

.legacy-link-tile,
.legacy-main-tile {
  clear: both;
  background: #fff;
  position: relative;
}

@media only screen and (max-width: 768px) {
  .legacy-tile-body .legacy-tile-content {
    padding: 10px 20px;
  }
}

span.legacy-tooltip-icon {
  display: inline-block;
  flex: 0 0 auto;
  font-size: 12px;
  line-height: 16px;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  margin: 4px 0 0 5px;
  color: #fff;
  background: #0068ae;
  text-align: center;
  cursor: default;
}

.mm-line span.legacy-tooltip-icon {
  margin: 0;
  width: 20px;
  height: 20px;
  font-size: 16px;
  line-height: 20px;
  position: absolute;
  left: -10px;
  top: -11px;
}

.legacy-tile-w {
  clear: both;
  position: relative;
  margin: 0;
  margin-top: 17px;
  margin-bottom: 16px;
  width: 100%;
  background-color: #fff;
  overflow: visible;
}

.legacy-tile__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
  padding: 12px 16px;
  font-size: 16px;
  align-self: stretch;
  position: relative;
  background-color: #fff;
}

.legacy-tile__header--collapsable {
  cursor: pointer;
}

.legacy-tile__header--large-text {
  font-size: 18px;
  padding: 0 8px;
}

.legacy-tile__header--anchor {
  position: relative;
}

.legacy-tile__header--anchor img {
  position: relative;
  left: 0;
  transition: left 300ms;
}

.legacy-tile__header--anchor:hover img {
  left: 5px;
}

.legacy-tile__header-label {
  display: flex;
  align-items: center;
}

.legacy-tile__header-label>svg {
  margin-right: 10px;
}

.legacy-tile__header-label>svg path {
  fill: #676879;
}

.legacy-tile__header-link {
  display: flex;
  align-items: center;
  color: #2178c4;
  cursor: pointer;
}

.legacy-tile__header-link:hover {
  text-decoration: underline;
}

.legacy-tile__header-link>svg path {
  fill: #2178c4;
}

.legacy-tile__header-left {
  display: flex;
  align-items: center;
  flex: 1;
  order: 1;
  color: #22263f;
  font-size: 16px;
  line-height: 24px;
  height: 24px;
}

.legacy-tile__header-right {
  display: flex;
  justify-content: flex-end;
  order: 2;
}

.legacy-tile__header-arrow-collapse {
  transform: rotate(90deg);
  transition: transform 300ms;
}

.legacy-tile__header-arrow-collapse--closed {
  transform: rotate(-90deg);
}

.legacy-tile__header-icon {
  margin-right: 8px;
}

.legacy-tile__header-icon--png {
  max-width: 40px;
  margin-right: 8px;
}

.legacy-tile__header--icon {
  margin-right: 8px;
}

.legacy-tile__content {
  transition: max-height 300ms;
}

.legacy-tile__header--closed~.legacy-tile__content {
  max-height: 0;
  overflow: hidden;
}

.legacy-tile__icon {
  margin-right: 8px;
}

.legacy-tile-link {
  display: flex;
  justify-content: flex-end;
}

.legacy-tile-link>div {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  color: #2178c4;
}

.legacy-tile-link>div:hover {
  text-decoration: underline;
}

.legacy-tile-link svg {
  margin-right: 10px;
}

.legacy-tile-link svg:hover {
  text-decoration: underline;
}

.legacy-tile-link svg path {
  fill: #2178c4;
}

.legacy-tile-action {
  display: inline-flex;
  flex-direction: column;
  position: relative;
}

.legacy-tile-action__heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  cursor: pointer;
  transition: color 300ms;
}

.legacy-tile-action__heading:hover {
  text-decoration: underline;
}

.legacy-tile-action__heading svg {
  color: #2178c4;
  transform: rotate(180deg);
}

.legacy-tile-action__heading svg path {
  fill: #2178c4;
}

.legacy-tile-action__menu {
  position: absolute;
  z-index: 500;
  display: none;
  width: 132px;
  left: 15px;
  top: 35px;
  font-size: 14px;
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,.15);
}

.legacy-tile-action__menu--show {
  display: block;
}

.legacy-tile-action__menu:after,
.legacy-tile-action__menu:before {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
}

.legacy-tile-action__menu:after {
  top: -19px;
  left: 11px;
  border-color: rgba(0,0,0,0) rgba(0,0,0,0) #fff rgba(0,0,0,0);
  border-width: 10px;
}

.legacy-tile-action__menu:before {
  top: -22px;
  left: 10px;
  border-color: rgba(0,0,0,0) rgba(0,0,0,0) #d2d2d2 rgba(0,0,0,0);
  border-width: 11px;
}

.legacy-tile-action__menu .legacy-tile-action__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 12px;
  cursor: pointer;
}

.legacy-tile-action__menu .legacy-tile-action__item:not(:last-child) {
  border-bottom: 1px solid #d2d2d2;
}

.legacy-tile-action__menu .legacy-tile-action__item:hover {
  text-decoration: underline;
}

.legacy-tile-action__menu .legacy-tile-action__item span {
  transition: color 300ms;
  padding: 5px 0;
}

.legacy-tile-action__menu .legacy-tile-action__item--active {
  cursor: unset;
}

.legacy-tile-action__menu .legacy-tile-action__item--active:hover {
  text-decoration: none;
}

.legacy-tile-action__menu .legacy-tile-action__item--active span {
  color: #2178c4;
}

.legacy-tile-action__menu .legacy-tile-action__item--active svg path {
  fill: #2178c4;
}

.legacy-modal__overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1030;
  background-color: rgba(230,236,243,.9);
}

.legacy-modal__box-w {
  margin: 100px auto;
  width: 600px;
  color: #22263f;
}

.legacy-modal__box-w--xl {
  width: 820px;
}

@media (max-width: 576px) {
  .legacy-modal__box-w {
    width: 300px !important;
    display: flex;
    justify-content: center;
  }
}

.legacy-modal__btn--close {
  display: flex;
  justify-content: flex-end;
}

.legacy-modal__btn--close svg path {
  fill: #676879;
}

.legacy-modal__icon {
  cursor: pointer;
}

.legacy-modal__buttons {
  margin-top: 40px;
  justify-content: flex-end;
  display: flex;
}

.legacy-modal__buttons--w {
  margin-top: 0;
  padding: 16px 25px 25px 25px;
}

.legacy-modal__buttons--center {
  justify-content: center;
  margin-top: 0;
}

.legacy-glob-btn__close {
  position: relative;
  height: 20px;
  width: 20px;
  transition: all ease-in-out .2s;
}

.legacy-glob-btn__close:hover {
  transform: scale(1.1);
}

.legacy-glob-btn__close:hover .legacy-bar-vert,
.legacy-glob-btn__close:hover .legacy-bar-horiz {
  background: #999;
}

.legacy-glob-btn__close .legacy-bar-vert {
  width: 20px;
  height: 5px;
  display: block;
  background: #aeaeae;
  transform: rotate(45deg);
  position: absolute;
  top: 10px;
  right: 0px;
  transition: all ease-in-out .2s;
}

.legacy-glob-btn__close .legacy-bar-horiz {
  position: absolute;
  width: 20px;
  height: 5px;
  background: #aeaeae;
  top: 10px;
  right: 0;
  transform: rotate(-45deg);
  transition: all ease-in-out .2s;
}

.legacy-glob-btn__plus {
  display: block;
  position: relative;
  border: 2px solid #0068ae;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin: -10px -5px -5px;
  transform: rotate(0deg);
  transition: all ease-in-out .2s;
}

.legacy-glob-btn__plus:hover {
  transform: rotate(90deg);
}

.legacy-glob-btn__plus .legacy-bar-vert {
  position: absolute;
  top: 10px;
  left: 17px;
  width: 2px;
  height: 16px;
  transition: all ease-in-out .2s;
  background: #0068ae;
}

.legacy-glob-btn__plus .legacy-bar-horiz {
  position: absolute;
  top: 17px;
  left: 10px;
  width: 16px;
  height: 2px;
  transition: all ease-in-out .2s;
  background: #0068ae;
}

.legacy-glob-btn__main {
  padding: 15px 0;
  border-radius: 50px;
  width: 160px;
  display: block;
  text-align: center;
  float: left;
  transition: all ease-in-out .2s;
}

.legacy-glob-btn__main:hover {
  border-radius: 20px;
}

.legacy-glob-btn__main.gray {
  background: #aeaeae;
  color: #434041;
}

.legacy-glob-btn__main.blue {
  background: #0068ae;
  color: #fff;
}

.legacy-glob-btn__link {
  width: 24px;
  height: 24px;
  display: block;
  float: left;
  position: relative;
}

.legacy-glob-btn__link .legacy-left-bar {
  position: absolute;
  width: 12px;
  height: 2px;
  background: #0068ae;
  top: 8px;
  transform: rotate(45deg);
  left: 10px;
  transition: all ease-in-out .2s;
}

.legacy-glob-btn__link .legacy-right-bar {
  position: absolute;
  width: 12px;
  height: 2px;
  background: #0068ae;
  top: 15px;
  transform: rotate(-45deg);
  left: 10px;
  transition: all ease-in-out .2s;
}

.legacy-link-tile .legacy-tile-header:hover .legacy-glob-btn__link .legacy-left-bar,
.tile-page-link:hover .legacy-glob-btn__link .legacy-left-bar {
  width: 13px;
  transform: rotate(35deg);
}

.legacy-link-tile .legacy-tile-header:hover .legacy-glob-btn__link .legacy-right-bar,
.tile-page-link:hover .legacy-glob-btn__link .legacy-right-bar {
  width: 13px;
  transform: rotate(-35deg);
}

.net-worth .legacy-glob-btn__plus .legacy-bar-vert,
.net-worth .legacy-glob-btn__plus .legacy-bar-horiz {
  background: #a2d134;
}

.financial-goals .legacy-glob-btn__plus .legacy-bar-vert,
.financial-goals .legacy-glob-btn__plus .legacy-bar-horiz {
  background: #2da27c;
}

.legacy-modal-header .legacy-glob-btn__close {
  float: right;
}

.no-header .legacy-glob-btn__close {
  position: absolute;
  right: 15px;
  top: 10px;
  z-index: 999;
}

.legacy-modal-buttons>.legacy-glob-btn__main {
  max-width: 160px;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .legacy-modal-buttons>.legacy-glob-btn__main {
    font-size: 14px;
  }
}

.legacy-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: inherit;
  font-size: 14px;
  font-family: inherit;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  padding: 9px 17px;
  margin: 0;
  transition: background-color 300ms;
  border: 0;
}

.legacy-btn__prime {
  color: #fff;
  background-color: #2178c4;
  border-radius: 4px;
}

.legacy-btn__prime:hover {
  background-color: #3b98e2;
  color: #fff;
}

.legacy-btn__prime--disabled {
  margin-left: 15px;
  opacity: .5;
}

.legacy-btn__default {
  background-color: #aeb6be;
  border-radius: 4px;
}

.legacy-btn__default:hover {
  background-color: #e6ecf3;
}

.legacy-btn__clear {
  color: #676879;
  border-radius: 4px;
  background-color: rgba(0,0,0,0);
}

.legacy-btn__clear:hover {
  color: #676879;
  background-color: rgba(0,0,0,0) !important;
  text-decoration: none;
}

.legacy-btn__negative {
  border-radius: 4px;
  background-color: #b7343f;
}

.legacy-btn__action.btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #255eba;
  padding: 6px 8px;
  font-size: 14px;
  background-color: rgba(0,0,0,0);
  transition: background-color 300ms;
}

.legacy-btn__action.btn:hover {
  border-radius: 4px;
  background-color: #f2f2f2;
}

.legacy-btn__action.btn svg {
  margin-right: 4px;
}

.legacy-btn__action.btn svg path {
  fill: #255eba;
}

.legacy-btn__img {
  padding-right: 4px;
  height: 26px;
}

.legacy-btn~.legacy-btn:not(:first-of-type) {
  margin-left: 15px;
}

.legacy-timeout-modal {
  padding: 24px 24px 16px 24px;
}

.legacy-timeout-modal__message-1 {
  margin: 0;
  color: #22263f;
  font-size: 14px;
  margin-bottom: 16px;
}

.legacy-timeout-modal__message-2 {
  margin: 0;
  color: #22263f;
  font-size: 14px;
}

.legacy-timeout-modal__btn-w {
  margin-top: 32px;
  text-align: end;
}

.header {
  z-index: 899;
  background-color: #fff;
  min-height: 48px;
}

.header__advisor-impersonation {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 7px 0px;
  background-color: #f49500;
  color: #fff;
  font-size: 12px;
  animation: fadeInDown 2s;
}

.header__logout {
  text-decoration: underline;
  cursor: pointer;
}

.header__logo-container {
  max-width: 72px;
}

@media (min-width: 768px) {
  .header__logo-container {
    max-width: 149px;
  }
}

.header__logo {
  max-height: 100px;
  max-width: 100%;
  height: auto;
  width: auto;
  cursor: pointer;
}

.header__dropdown-menu {
  padding-top: 0px;
  width: 325px;
}

.header__dropdown-item {
  font-size: 14px;
  line-height: 20px;
  width: 300px;
  font-weight: bold;
  padding: .5rem 1rem;
}

.header__dropdown-item:hover {
  color: #40a9ff;
  background-color: unset;
}

.header__dropdown-item--logout {
  color: #2178c4;
}

.header__nav-container {
  height: 80px;
  gap: 0px;
  max-width: 1440px;
  padding: 0px 7%;
}

@media (min-width: 992px) {
  .header__nav-container {
    height: 104px;
    gap: 16px;
  }
}

@media (min-width: 1200px) {
  .header__nav-container {
    gap: 32px;
  }
}

@media (min-width: 1400px) {
  .header__nav-container {
    padding: 0px 104px;
  }
}

.header__nav-icon {
  font-size: 24px;
  color: #676879;
}

.header__nav-link {
  line-height: 86px;
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;
}

.header__nav-link.nav-link.active {
  border-bottom-color: #000;
  font-weight: bold;
}

.header__nav-link.nav-link:hover {
  color: #40a9ff;
  border-bottom-color: #000;
}

.header__nav-link:focus {
  outline: none;
}

.header__notification-icon {
  font-size: 24px;
  color: #676879;
}

.header__notification-icon-img {
  max-width: 24px;
}

.header__notification-badge {
  position: absolute;
  top: 4px;
  right: 4px;
  font-weight: 500;
  width: 16px;
  height: 16px;
  padding: 2px;
}

.header__notification-badge--high-count {
  font-size: 8px;
  line-height: 2;
  padding: 0px;
}

.header__notification-message {
  font-size: 14px;
  color: #22263f;
  line-height: 22px;
  margin-left: 10px;
}

.header__notification-name {
  font-weight: 500;
}

.header__notification-date {
  color: #676879;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  margin-left: 10px;
}

.header__notification-dropdown {
  position: absolute;
  z-index: 900;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  right: -60px;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 #e6ecf3;
  top: 48px;
  width: 400px;
  max-height: 500px;
  cursor: initial;
}

@media (max-width: 576px) {
  .header__notification-dropdown {
    width: 300px;
  }
}

.header__notification-dropdown-null {
  padding: 16px;
  text-align: center;
  font-size: 16px;
  color: #2178c4;
  font-weight: 400;
}

.header__notification-dropdown-list {
  box-shadow: 0px 1px 0px #e6ecf3;
  max-height: 466px;
  overflow-y: scroll;
  width: 100%;
}

.header__notification-dropdown-item {
  padding: 13px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.header__notification-dropdown-item:not(:last-child) {
  box-shadow: 0px 1px 0px #e6ecf3;
}

.header__notification-dropdown-item--unread {
  background-color: #f2f5f9;
}

.header__notification-dropdown-item--image {
  min-width: 64px;
}

.header__notification-dropdown-name {
  font-weight: 500;
}

.header__notification-dropdown-avatar {
  color: #fff;
  background-color: #0068ae;
  border: 1px solid #fff;
  border-radius: 18px;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}

.header__notification-dropdown-avatar img {
  margin-right: 0;
}

.header__notification-dropdown-mark-read {
  align-items: center;
  justify-content: center;
  display: flex;
  height: 34px;
}

.header__notification-dropdown-mark-read .Button {
  height: 50%;
  min-height: unset;
  padding: 0;
}

.header__notification-dropdown-mark-read .Button:active {
  transform: none;
}

.header__notification-dropdown-mark-read .Button:hover span::after {
  display: none;
}

.header__drawer-body {
  padding: 0px;
}

.header__drawer-head {
  justify-content: end;
  padding: 80px 28px 0px 28px;
}

.header__drawer-list {
  padding: 0px;
}

.header__drawer-item {
  position: relative;
  margin-bottom: 10px;
  height: 32px;
  color: #676879;
  padding: 0px 28px;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
}

.header__drawer-item--account {
  height: 40px;
}

.header__drawer-item--hover:hover {
  color: #40a9ff;
}

.header__drawer-item--hover:hover:before {
  background-color: #22263f;
  width: 12px;
  height: 1px;
  content: "";
  position: absolute;
  top: 35%;
  left: 0px;
}

.header__drawer-item--active:before {
  background-color: #22263f;
  width: 12px;
  height: 1px;
  content: "";
  position: absolute;
  top: 35%;
  left: 0px;
}

.header__drawer-account-actions {
  padding: 48px 28px 16px;
}

.header .show {
  display: block;
}

.header .hide {
  display: none;
}

@keyframes fadeInDown {
  from {
    transform: translate3d(0, -100%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.profile-picture__w {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  height: 48px;
  width: 48px;
}

.profile-picture__w img {
  display: block;
  margin: auto;
  width: 100%;
}

.profile-picture__w--carousel {
  background-color: #e7edf3;
}

.profile-picture__w--primary {
  background-color: #8790a3;
}

.profile-picture__w--secondary {
  background-color: #f4f4f4;
}

.profile-picture__initials {
  font-size: inherit;
}

.profile-picture__initials--carousel {
  color: #22263f;
}

.profile-picture__initials--primary {
  color: rgba(255,255,255,.8);
}

.profile-picture__initials--secondary {
  color: rgba(35,31,32,.5);
}

.row-w {
  display: flex;
  padding: 13px 16px;
  text-decoration: none;
  font-size: 12px;
  align-items: center;
}

.row-w--hover:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

.row-w:not(:last-child) {
  border-bottom: 1px solid #f2f5f9;
}

.row-w:hover {
  background-color: #f5f5f5;
}

.histogram__container {
  padding: 24px 16px;
}

.histogram__wrapper {
  display: flex;
  justify-content: center;
  position: relative;
  background-color: #e7edf3;
  height: 154px;
  margin: auto;
}

.histogram__bar {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #8a8888;
}

.histogram__bar--alert {
  background-color: #f4a83e;
}

.histogram__limit {
  position: absolute;
  bottom: 0;
  width: 111%;
  border-top: 1px dotted #22263f;
}

.histogram__threshold-label {
  position: absolute;
  font-size: 16px;
  bottom: 8px;
}

.histogram__threshold-label--right {
  right: -32px;
}

.histogram__threshold-label--left {
  left: -32px;
}

.histogram__link {
  color: inherit;
}

.histogram__link:hover {
  color: #40a9ff;
}

.app-banner-offset,
.app-banner {
  transition: transform .3s ease-in-out;
}

.app-banner {
  padding: 16px 10px 16px 0;
  border-bottom: 1px solid #e7edf3;
  max-height: 100%;
  opacity: 1;
  background-color: #255eba;
  color: #fff;
}

.app-banner__button {
  min-height: 40px;
  width: 90%;
  padding: 10px;
}

.app-banner__transparent-button {
  background-color: rgba(0,0,0,0);
  border: none;
  padding: 0;
}

.app-banner__message-container {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.app-banner__message {
  margin: 0;
  white-space: nowrap;
  line-height: 1.5;
  font-size: 100%;
}

@media only screen and (max-width: 360px) {
  .app-banner__message {
    font-size: 14px;
  }
}

.app-banner__gc-icon {
  border: 1px solid #edeff1;
  border-radius: 6px;
  background-color: #fff;
  min-width: 45px;
  height: auto;
}

.app-banner__x-icon {
  font-size: 35px;
  margin-right: 5px;
  cursor: pointer;
}

.bottom-sheet__content,
.bottom-sheet__overlay {
  position: fixed;
  left: 0;
  width: 100%;
  pointer-events: none;
  z-index: 1200;
}

.bottom-sheet__overlay {
  top: 0;
  height: 100%;
  transition: opacity .3s ease-in-out;
  background-color: #000;
  opacity: 0;
}

.bottom-sheet__content {
  bottom: 0;
  height: auto;
  transform: translate3d(0, 100%, 0);
  transition: transform .5s ease-in-out;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background-color: #fff;
}

.bottom-sheet__header {
  padding: 16px;
  border-bottom: 1px solid #e5e5e5;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  white-space: nowrap;
}

.bottom-sheet__actions {
  padding: 16px;
}

.bottom-sheet__action {
  display: flex;
  align-items: center;
}

.bottom-sheet__action+.bottom-sheet__action {
  margin-top: 16px;
}

.bottom-sheet__action-tittle {
  flex: 1;
  overflow: hidden;
  font-size: 14px;
  line-height: 18px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.bottom-sheet__open-button-wrapper {
  width: 25%;
}

.bottom-sheet__open-button {
  min-height: 40px;
}

.bottom-sheet__continue-button-wrapper {
  width: 30%;
}

.bottom-sheet__continue-button {
  min-height: 40px;
  color: #676879;
  background-color: #aeb6be;
}

.bottom-sheet__image-wrapper {
  margin-right: 12px;
  padding: 5px;
  border: 1px solid #edeff1;
  border-radius: 6px;
  background-color: #fff;
}

.bottom-sheet__image {
  width: 40px;
  height: 40px;
}

.bottom-sheet--active .bottom-sheet__overlay {
  opacity: .5;
  pointer-events: all;
}

.bottom-sheet--active .bottom-sheet__content {
  pointer-events: all;
  transform: translate3d(0, 0, 0);
}

.slick-carousel {
  position: relative;
}

.slick-carousel .slick-slider {
  width: 100%;
}

.slick-carousel .slick-prev,
.slick-carousel .slick-next {
  position: absolute;
  top: 100%;
  z-index: 1;
}

.slick-carousel .slick-prev::before,
.slick-carousel .slick-next::before {
  content: "";
}

.slick-carousel .slick-prev.slick-disabled,
.slick-carousel .slick-next.slick-disabled {
  opacity: .25;
}

.slick-carousel .slick-prev {
  left: 5px;
}

.slick-carousel .slick-next {
  right: 25px;
}

.slick-carousel .slick-dots {
  bottom: -20px;
}

.slick-carousel .slick-dots li {
  margin: 0px;
}

.slick-carousel .slick-dots li button::before {
  font-size: 10px;
  line-height: 20px;
  content: "■";
  background-color: rgba(0,0,0,0);
}

.slick-carousel .slick-dots li.slick-active button::before {
  background-color: rgba(0,0,0,0);
}

.slick-carousel-dots--light .slick-dots li button::before {
  color: #000;
}

.slick-carousel-dots--light .slick-dots li.slick-active button::before {
  color: #fff;
}

.slick-carousel-dots--dark .slick-dots li button::before {
  color: #3b98e2;
}

.slick-carousel-dots--dark .slick-dots li.slick-active button::before {
  color: #2178c4;
}

.steps__container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-items: start;
  align-items: center;
  width: 100%;
}

.steps__item {
  flex-grow: 1;
  position: relative;
}

.steps__item:last-of-type {
  flex-grow: 0;
}

.steps__item:after {
  display: block;
  position: absolute;
  left: 26px;
  width: calc(100% - 26px);
  height: 2px;
  top: 13px;
  content: "";
  background-color: #22263f;
  opacity: .25;
}

.steps__item:after:last-of-type {
  display: none;
}

.steps__item--completed:after {
  opacity: 1;
  background-color: #068667;
}

.steps__item--error:after {
  opacity: 1;
  background-color: #b7343f;
}

.steps__marker {
  display: block;
  position: relative;
  border-radius: 50%;
  height: 26px;
  width: 26px;
  border: 2px solid #22263f;
  opacity: .25;
}

.steps__marker--completed {
  border-color: #000;
  opacity: 1;
  background-color: #000;
}

.steps__marker--completed:before {
  font-family: "bootstrap-icons";
  content: "";
  color: #fff;
  font-size: 22px;
  line-height: 24px;
}

.steps__marker--current {
  border-color: #000;
  opacity: 1;
}

.steps__marker--error {
  border-color: #000;
  opacity: 1;
  background-color: #000;
}

.steps__marker--error:before {
  font-family: "bootstrap-icons";
  content: "";
  color: #fff;
  font-size: 22px;
  line-height: 24px;
}

.steps__marker--disabled {
  border-color: #000;
  opacity: 1;
  background-color: #000;
}

.steps__marker--disabled:before {
  font-family: "bootstrap-icons";
  content: "";
  color: #fff;
  font-size: 22px;
  line-height: 24px;
}

.messages-error__text {
  font-size: 12px;
  margin-left: 8px;
  color: #22263f;
  overflow-wrap: break-word;
}

.messages-error__icon {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  color: #d9771c;
}

.form-dropdown__dropdown-item {
  color: rgba(0,0,0,.6509803922);
  padding: 16px;
  font-size: 16px;
}

.form-dropdown__dropdown-item.active,
.form-dropdown__dropdown-item:active {
  color: rgba(0,0,0,.6509803922);
  background-color: unset;
}

.form-dropdown__dropdown-item:hover {
  background-color: rgba(33,120,196,.1019607843);
  color: rgba(0,0,0,.6509803922);
}

.form-dropdown .dropdown-menu.show {
  display: block;
  width: 100%;
  border: 1px solid #2178c4;
  padding: 0;
  max-height: 280px;
  overflow: auto;
}

.form-dropdown .dropdown-toggle {
  border: 1px solid #adb5bd;
  font-size: 16px;
  border-radius: 4px;
  line-height: 27px;
  color: #22263f;
}

.form-dropdown .dropdown-toggle:hover {
  border: 1px solid #2178c4;
}

.form-dropdown .dropdown-toggle.show {
  border: 1px solid #2178c4;
  color: #2178c4;
}

.form-dropdown .dropdown-toggle .bi {
  margin-right: 10px;
  color: #2178c4;
}

.form-dropdown .dropdown.show .dropdown-toggle .bi-chevron-down {
  transform: rotate(180deg);
}

.form-dropdown__dropdown-is-invalid .form-control {
  border: 1px solid rgba(0,0,0,0);
  border-radius: 5px;
}

.form-dropdown__dropdown-is-invalid .dropdown-menu,
.form-dropdown__dropdown-is-invalid .dropdown-menu.show,
.form-dropdown__dropdown-is-invalid .dropdown-toggle:hover,
.form-dropdown__dropdown-is-invalid .dropdown-toggle,
.form-dropdown__dropdown-is-invalid .dropdown-toggle.show {
  border: 1px solid #d9771c;
  border-radius: 4px;
}

.form-dropdown__dropdown-is-invalid .dropdown-toggle .bi {
  color: #d9771c;
}

.form-dropdown .plan-input-forms__dropdown-is-invalid .dropdown-toggle {
  border: 1px solid rgba(0,0,0,0);
}

.form-dropdown .plan-input-forms__dropdown-is-invalid .dropdown-toggle:hover {
  border: 1px solid rgba(0,0,0,0);
}

.form-dropdown .plan-input-forms__dropdown-is-invalid .dropdown .dropdown-toggle .bi {
  color: #d9771c;
}

.file-upload__dropzone {
  position: relative;
  padding: 46px 0;
  border: 2px dashed #e7edf3;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.file-upload__input {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
}

.file-upload__divider {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 0;
}

.file-upload__divider-line {
  height: 1px;
  background-color: #e7edf3;
  width: 120px;
  flex-grow: 1;
}

.file-upload__divider-text {
  margin: 0 24px;
  font-size: 14px;
  color: #676879;
}

